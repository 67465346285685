import { styled } from '@material-ui/core/styles';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Cmf_Base, WALLET_API } from 'src/Data/Api';
import QRCodeIcon from 'src/assets/icons/cmf_linea/QRCode.svg';
import ArrowBack from 'src/assets/images/cmf_linea/arrowBack.svg';
import CloseButton from 'src/assets/images/cmf_linea/closeButton.svg';
import LinearProgressWithLabel from 'src/components/ProgressBar/qrProgressBar';
import useResponsive from 'src/hooks/useResponsive';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { BASE_COLORS, FONT_SIZE, FONT_WEIGHT } from 'src/theme/constants';
import { PRODUCTS_TYPES, TimerQr, URL_APP } from 'src/utils/Constants';
import { Name, ocultarNumeroEnmascarado } from 'src/utils/Functions';
import ShoppingCart from '../../../assets/images/cmf_linea/credit-card-solid.svg';
import ShareIcon from '../../../assets/images/cmf_linea/share.svg';
import ExtraCreditDrawer from '../../../components/Drawers/extra-credit';
import CardAcciones from './cardAcciones';
export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  height: 'fit-content',
  width: '100%',
}));
export const ContainerModal = styled('div')(() => ({
  fontFamily: 'Red Hat Text',
  backgroundColor: '#fff',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  [BreakPointTheme.breakpoints.between('xs', 'sm')]: {
    width: '100%',
    height: '100%',
  },
  [BreakPointTheme.breakpoints.between('sm', 'sm1')]: {
    width: '70%',
    height: 'auto',
    borderRadius: '1rem',
  },
  [BreakPointTheme.breakpoints.up('sm1')]: {
    width: '70%',
    height: 'auto',
    borderRadius: '1rem',
  },
  [BreakPointTheme.breakpoints.up('md')]: {
    width: '50%',
    height: 'auto',
    borderRadius: '1rem',
  },
  [BreakPointTheme.breakpoints.up('lg')]: {
    width: '45%',
    height: 'auto',
    borderRadius: '1rem',
  },
}));
export const ContainerQR = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '34px',
  background: '#F2F4F7',
  margin: '0rem auto 1rem',
  [BreakPointTheme.breakpoints.between('xs', 'sm')]: {
    width: '85%',
    height: 'auto',
  },
  [BreakPointTheme.breakpoints.up('sm')]: {
    width: '75%',
    height: 'auto',
  },
  [BreakPointTheme.breakpoints.up('md')]: {
    width: '65%',
    height: 'auto',
  },
  [BreakPointTheme.breakpoints.up('lg1')]: {
    width: '55%',
    height: 'auto',
  },
}));
export const ContainerTimer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '34px',
  background: '#F2F4F7',
  margin: '0rem auto 1rem',
  textAlign: 'center',
  padding: '1rem 0px',
  alignItems: 'center',
  [BreakPointTheme.breakpoints.between('xs', 'sm')]: {
    width: '85%',
    height: 'auto',
  },
  [BreakPointTheme.breakpoints.up('sm')]: {
    width: '75%',
    height: 'auto',
  },
  [BreakPointTheme.breakpoints.up('md')]: {
    width: '65%',
    height: 'auto',
  },
  [BreakPointTheme.breakpoints.up('lg1')]: {
    width: '55%',
    height: 'auto',
  },
}));
export const ContainerButton = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '1rem',
  minWidth: '48px',
  height: '48px',
}));

export const ReloadQrButton = styled(LoadingButton)(() => ({
  borderRadius: '15px',
  width: '50%',
  [BreakPointTheme.breakpoints.up('md')]: {
    width: '35%',
  },
}));

export const BackButton = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  minWidth: '48px',
  height: '48px',
  borderRadius: '16px',
  color: BASE_COLORS.green,
  backgroundColor: 'var(--global-gray-10, #F9FAFB)',
  '&:hover': {
    fontSize: FONT_SIZE.md,
    color: BASE_COLORS.green,
    background: 'var(--global-gray-10, #F9FAFB)',
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
  },
  '& span': {
    color: BASE_COLORS.green,
    fontFamily: 'Red Hat Text',
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.lg,
  },
}));
export const VolverTypo = styled(Typography)(() => ({
  fontSize: '18px',
  fontStyle: 'normal',
  margin: '0px 15px',
  color: '#1018288A',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '-0.36px',
  fontFamily: 'Red Hat Text',
  cursor: 'pointer',
}));
const CarrouselContainer = ({ opens }) => {
  const isDesktop = useResponsive('up', 'md');
  const { accountSF, access_token } = useSelector(
    state => state.AuthenticationReducer?.CurrentUser,
  );
  const { customer } = useSelector(
    state => state.AuthenticationReducer?.CurrentUser,
  );
  const [displayModal, setDisplayModal] = useState(false);
  const [QRCard, SetQRCard] = useState('');
  const [QRModal, setQRModal] = useState(false);
  const [loadingQR, setLoadingQR] = useState(false);
  const [linkReferral, setLink] = useState(false);
  const [progress, setProgress] = React.useState(TimerQr);
  const [timerActive, setTimerActive] = React.useState(false);
  const [disabledReferral, setDisabledReferral] = useState(false);
  const navigate = useNavigate();
  const { CurrentUser } = useSelector(state => state.AuthenticationReducer);
  const { selectedProduct } = useSelector(state => state.ActiveProductReducer);
  const [loadExtraFinancial, setLoadExtra] = useState(false);
  const PromoStep = () => {
    getReferral();
  };
  const QRStep = () => {
    getQRCard();
  };
  const Resumen = () => {
    navigate('/home/details');
  };

  React.useEffect(() => {
    let timer;

    if (timerActive) {
      timer = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress <= 0) {
            // Cambié de === a <= para manejar el caso si el valor se vuelve negativo
            clearInterval(timer);
            setTimerActive(false); // Desactivar el temporizador cuando llegue a 0
            return 0;
          } else {
            return prevProgress - 1;
          }
        });
      }, 1000);
    }

    // Limpiar el intervalo al desmontar el componente o cuando se desactive el temporizador
    return () => {
      clearInterval(timer);
    };
  }, [timerActive]); // Dependencia del estado timerActive

  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(opens === true ? opens : false);
  const handleExtraCreditDrawerClose = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const onLoading = load => {
    setLoadExtra(load);
  };
  const gradientBackground = {
    background:
      'linear-gradient(45deg, rgba(2,110,24,1) 0%, rgba(120,206,137,1) 100%)',
  };
  /**
   * Fetches the referral link for the current user and sets the link state.
   * If available, shares the link via the Web Share API.
   * @async
   * @function getReferral
   */
  const getReferral = async () => {
    setDisabledReferral(true);
    try {
      const response = await axios.get(
        `${WALLET_API}api/referral-link/${selectedProduct?.customerId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
      );
      setDisplayModal(true);
      setDisabledReferral(false);
      setLink(response?.data?.referral_link);
      if (navigator.share && !isDesktop) {
        await navigator.share({
          text: `¡Hola! ${CurrentUser?.customer?.firstname} te envía este link para que solicites la tarjeta digital CMF y disfrutes de todos sus beneficios. `,
          url: response?.data?.referral_link,
        });
        setDisabledReferral(false);
      } else {
        setDisabledReferral(false);
      }
    } catch (error) {
      setDisabledReferral(false);
      NotificationManager.error('Error al obtener el enlace de referido');
    } finally {
      setDisabledReferral(false);
    }
  };
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(linkReferral);
    setDisplayModal(false);
    NotificationManager.success('Enlace copiado correctamente');
  };
  const handleBack = () => {
    setQRModal(false);
    setLoadingQR(false);
  };
  const getQRCard = async () => {
    try {
      setLoadingQR(true);
      const response = await axios.get(
        `${Cmf_Base}api/notifications/qrCodeWallet/${selectedProduct?.customerId}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
      );
      setQRModal(true);
      if (response.status === 200) {
        const QRImage = response?.data?.qrCode;
        setTimerActive(true);
        setProgress(TimerQr);
        SetQRCard(QRImage);
      }
    } catch (error) {
      setLoadingQR(false);
      setTimerActive(false);
      NotificationManager.error('Error al generar el QR de la tarjeta');
    } finally {
      setLoadingQR(false);
    }
  };
  useEffect(() => {
    if (opens) {
      handleExtraCreditDrawerClose();
    }
  }, [opens]);
  return (
    <>
      <div>
        <Modal
          open={QRModal}
          onClose={handleBack}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <ContainerModal>
            {!isDesktop && (
              <Box
                sx={{
                  display: 'flex',
                  padding: '5px 24px 0px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  borderBottom:
                    '1px solid var(--specific-light-stroke-m, #EAECF0)',
                  fontSize: '18px',
                  fontFamily: 'Red Hat Text',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '15px',
                  letterSpacing: '-0.36px',
                }}>
                <ContainerButton>
                  <BackButton onClick={handleBack}>
                    <img src={ArrowBack} alt="X" />
                  </BackButton>
                  <VolverTypo> Volver</VolverTypo>
                </ContainerButton>
              </Box>
            )}
            {isDesktop && (
              <Box
                sx={{
                  display: 'flex',
                  padding: '1rem 1rem 0 0',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  alignSelf: 'stretch',
                  fontFamily: 'Red Hat Text',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '22px',
                  letterSpacing: '-0.36px',
                  cursor: 'pointer',
                }}>
                <img
                  role="button"
                  src={CloseButton}
                  onClick={handleBack}
                  alt="X"
                />
              </Box>
            )}
            <Typography
              sx={{
                color: 'rgba(16, 24, 40, 0.80)',
                textAlign: 'center',
                fontFamily: 'Red Hat Text',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px /* 150% */',
                letterSpacing: '-0.16px',
                marginTop: '1rem',
              }}>
              CÓDIGO QR
            </Typography>
            <Typography
              sx={{
                color: '#adb5bd',
                textAlign: 'center',
                fontFamily: 'Red Hat Text',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px /* 150% */',
                letterSpacing: '-0.16px',
              }}>
              {Name(customer.firstname)}
            </Typography>
            <ContainerQR>
              <div
                style={{
                  height: 'auto',
                  maxWidth: 250,
                  width: '100%',
                  margin: '25px 10px',
                }}>
                <QRCode
                  size={200}
                  style={{
                    height: '100%',
                    maxWidth: isDesktop ? '83%' : '100%',
                    width: '100%',
                    margin: 'auto',
                    ...gradientBackground,
                  }}
                  value={QRCard}
                  viewBox={`0 0 256 256`}
                  fgColor="rgba(2, 110, 24, 0.5)"
                />
              </div>
              <Typography
                sx={{
                  color: 'rgb(2, 110, 24)',
                  textAlign: 'center',
                  fontFamily: 'Red Hat Text',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px /* 150% */',
                  letterSpacing: '-0.16px',
                  background: '#fff',
                  borderRadius: '30px',
                  padding: '10px 25px',
                }}>
                {ocultarNumeroEnmascarado(
                  selectedProduct?.isCorporate
                    ? selectedProduct?.customerAccountCards[0]
                      ?.lastFourDigitCard
                    : selectedProduct?.decryptedProductAccount,
                )}
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(16, 24, 40, 0.80)',
                  textAlign: 'center',
                  fontFamily: 'Red Hat Text',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px /* 150% */',
                  letterSpacing: '-0.16px',
                  margin: '10px 20px',
                }}>
                Tarjeta CMF
              </Typography>
            </ContainerQR>
            <ContainerTimer sx={{ width: '70%' }}>
              <Typography variant="h5" color="#000" sx={{ mt: 1 }}>
                Estás pagando
              </Typography>
              <Typography
                variant="body2"
                color="rgba(16, 24, 40, 0.80)"
                sx={{ margin: !timerActive && '1rem !important' }}>
                {!timerActive
                  ? `Este código QR ha expirado`
                  : `Este código QR expira en ${progress} seg`}
              </Typography>
              {timerActive && (
                <LinearProgressWithLabel value={(progress / 60) * 100} />
              )}
              <ReloadQrButton
                variant="contained"
                onClick={getQRCard}
                loading={loadingQR}
                disabled={timerActive}>
                Actualizar
              </ReloadQrButton>
            </ContainerTimer>
          </ContainerModal>
        </Modal>
      </div>
      <div>
        {isDesktop && (
          <Modal
            open={displayModal}
            onClose={() => setDisplayModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '40%',
                bgcolor: 'background.paper',
                borderRadius: '34px',
                boxShadow: 24,
                p: '2.5rem 1rem',
                gap: '15px',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Typography
                sx={{
                  color: 'rgba(16, 24, 40, 0.80)',
                  textAlign: 'center',
                  fontFamily: 'Red Hat Text',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px /* 150% */',
                  letterSpacing: '-0.16px',
                }}>
                Comparte el enlace con tus amigos y conocidos para ganar $5
                dólares
              </Typography>

              <Box
                sx={{
                  alignSelf: 'stretch',
                  borderRadius: '200px',
                  backgroundColor: 'var(--specific-light-background-20)',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding:
                    'var(--padding-9xs) var(--padding-9xs) var(--padding-9xs)\n    16px',
                  color: 'var(--specific-light-semantic-brand-secondary-verde)',
                }}>
                <div>{linkReferral}</div>
                <Button
                  sx={{
                    borderRadius: '100px',
                    height: '2.25rem',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0px 16px',
                    boxSizing: 'border-box',
                    gap: '8px',
                    textAlign: 'left',
                    fontSize: 'var(--detail-m-size)',
                    width: '121px',
                  }}
                  variant="contained"
                  onClick={handleClick}>
                  <Typography
                    sx={{
                      color: '#FFF',
                      fontFamily: 'Red Hat Text',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '22px /* 157.143% */',
                      letterSpacing: '-0.14px',
                    }}>
                    Copiar Enlace
                  </Typography>
                </Button>
              </Box>
              <a
                href={URL_APP.referral}
                target="_blank"
                rel="noreferrer"
                style={{
                  textAlign: 'center',
                  color: '#026E18',
                  fontFamily: 'Red Hat Text',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.16px',
                }}>
                Términos y condiciones
              </a>
            </Box>
          </Modal>
        )}
      </div>
      <Container>
        {!selectedProduct?.isCorporate &&
          selectedProduct?.productTypeId?.toUpperCase() !==
          PRODUCTS_TYPES.PREPP && (
            <React.Fragment>
              <ExtraCreditDrawer
                onLoading={onLoading}
                open={open}
                onClose={handleExtraCreditDrawerClose}
                showModal={showModal}
                closeModal={closeModal}
                opens={opens}
                loadExtraFinancial={loadExtraFinancial}
              />
              <CardAcciones
                image={QRCodeIcon}
                loading={loadingQR}
                text="Ver código QR de tarjeta"
                onClick={QRStep}
                disabled={loadingQR}
              />
              <CardAcciones
                image={ShareIcon}
                text="Invita a tus conocidos"
                onClick={PromoStep}
                disabled={disabledReferral}
                loading={disabledReferral}
              />
            </React.Fragment>
          )}
        <CardAcciones
          image={ShoppingCart}
          text="Estado de cuenta"
          onClick={Resumen}
        />
      </Container>
    </>
  );
};

export default CarrouselContainer;

import SecurityIcon from '@mui/icons-material/Security';
import {Box, Input, Typography, styled} from '@mui/material';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {IMaskInput} from 'react-imask';
import {useDispatch, useSelector} from 'react-redux';
import {
  ResetPasswordSendEmail,
  ResetPasswordValidateCode,
  ResetPasswordChangePassword,
  SendEmailToChangePassword,
} from 'src/store/actions/LoginAction';
import {BreakPointTheme} from 'src/theme/breakpoints';
import * as yup from 'yup';
import '../../../App.css';
import * as reset from './resetPassword.style';
import {useNavigate} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';

const LeadingIcon = styled('div')({
  borderRadius: 'var(--br-sm)',
  width: '3rem',
  height: '3rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const LabelText1 = styled('div')({
  position: 'relative',
  fontFamily: 'Red Hat Text',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px /* 133.333% */',
});

const LabelText = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const InputText = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontSize: 'var(--text-md-medium-size)',
  color: 'var(--specific-light-content-80)',
});

const Content2 = styled('div')({
  flex: 1,
  height: '2.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

const StateLayer = styled('div')({
  alignSelf: 'stretch',
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const InputField1 = styled('div')({
  alignSelf: 'stretch',
  borderRadius: 'var(--br-base)',
  backgroundColor: '#FFF',
  border: '1px solid #D0D5DD',
  boxSizing: 'border-box',
  height: '3.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '0rem 16px 0rem var(--padding-9xs)',
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="000000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({target: {name: props.name, value}})}
      overwrite
    />
  );
});

const CodeValidator = ({email, origin, password, isOut, onNextPassword}) => {
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(60);
  const [counterAttempts, setCounterAttempts] = useState(5);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const {ResetLoading} = useSelector(state => state.AuthenticationReducer);
  const validationSchemaBank = yup.object({
    code: yup.string('Introduce el codigo').required('Codigo es requerido'),
  });
  const {Authenticated, emailUser} = useSelector(
    state => state.AuthenticationReducer,
  );
  const navigate = useNavigate();
  const onSuccess = success => {
    if (success) {
      // setLoading(false)
      if (Authenticated === 3) {
        dispatch({
          type: 'PROCCESS_LOGIN',
          payload: true,
        });
        navigate('/home');
      } else {
        navigate('/');
      }
    } else {
      // setLoading(false)
    }
  };
  useEffect(() => {
    if (counterAttempts === 0) {
      setTimeout(() => {
        setRedirectToLogin(true);
        NotificationManager.error(
          'Has superado la cantidad de intentos permitidos. Por favor, espera 1 hora antes de intentarlo nuevamente.',
          '',
          10000,
        );
        dispatch({
          type: 'PROCCESS_LOGIN',
          payload: false,
        });
        navigate('/');
      }, 3000);
    }
  }, [counterAttempts]);
  useEffect(() => {
    // Función para actualizar el temporizador cada segundo
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(prevCountdown => prevCountdown - 1);
      }
    }, 1000);

    // Limpiar el temporizador cuando el componente se desmonta o cuando countdown llega a 0
    return () => clearInterval(timer);
  }, [countdown]);
  const handleResendCode = () => {
    if (isOut) {
      dispatch(
        SendEmailToChangePassword(
          Authenticated === 3 ? emailUser : email,
          onNextPassword,
        ),
      );
    } else {
      dispatch(
        ResetPasswordSendEmail(
          Authenticated === 3 ? emailUser : email,
          onSuccess,
        ),
      );
    }
    setCountdown(60);
  };
  const formikCode = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationSchemaBank,
    onSubmit: values => {
      setCounterAttempts(prevCounterAttempts => prevCounterAttempts - 1);
      if (isOut) {
        dispatch(
          ResetPasswordChangePassword(
            {
              to: Authenticated === 3 ? emailUser : email,
              password: password,
              newPassword: password,
            },
            values.code,
            onSuccess,
          ),
        );
      } else {
        dispatch(
          ResetPasswordValidateCode(
            {
              to: Authenticated === 3 ? emailUser : email,
              code: values.code,
            },
            onNextPassword,
          ),
        );
      }
    },
  });

  return (
    <>
      <reset.text>
        <Typography
          variant="titleLogin"
          style={{
            fontSize: origin === 'reset' && '16px',
          }}>
          Ingresa el código enviado por SMS a tu número de celular
        </Typography>
      </reset.text>
      <form onSubmit={formikCode.handleSubmit} style={{width: '100%'}}>
        <reset.ContainerInput
          style={{
            marginTop: '5px',
          }}>
          <InputField1
            style={{
              border:
                formikCode.touched.code &&
                formikCode.errors.code &&
                ' 1px solid #FF4842',
            }}>
            <StateLayer>
              <LeadingIcon>
                <SecurityIcon
                  sx={{
                    color: '#026E18',
                  }}
                />
              </LeadingIcon>
              <Content2>
                <LabelText>
                  <LabelText1
                    style={{
                      color:
                        formikCode.touched.code &&
                        formikCode.errors.code &&
                        '#FF4842',
                    }}>
                    Código de Verificación
                  </LabelText1>
                </LabelText>
                <InputText>
                  <Input
                    disableUnderline
                    name="code"
                    placeholder="123456"
                    value={formikCode.values.code}
                    onChange={formikCode.handleChange}
                    onBlur={formikCode.handleBlur}
                    inputComponent={TextMaskCustom}>
                    12345678
                  </Input>
                </InputText>
              </Content2>
            </StateLayer>
          </InputField1>
          {formikCode.touched.code && formikCode.errors.code && (
            <Typography variant="errors">{formikCode.errors.code}</Typography>
          )}
        </reset.ContainerInput>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          {counterAttempts !== 0 && (
            <>
              <reset.ReenviarContaint>
                <Typography variant="H4" color="#667085">
                  ¿No te ha llegado aun?
                </Typography>
                {countdown === 0 && (
                  <reset.aReset onClick={handleResendCode}>
                    Reenviar
                  </reset.aReset>
                )}
              </reset.ReenviarContaint>
              {countdown !== 0 && (
                <Typography variant="H4" color="#667085">
                  Reenviar código en {countdown} segundos
                </Typography>
              )}
              <br />
              <Typography variant="H4" color="#667085">
                {counterAttempts} intentos restantes
              </Typography>
              <br />
            </>
          )}
          {counterAttempts === 0 && (
            <>
              <br />
              <Typography variant="H4" color="#667085">
                Haz alcanzado el limite de intentos
              </Typography>
              <br />
            </>
          )}
          <Box
            sx={{
              width: origin !== 'reset' ? '100%' : null,
              [BreakPointTheme.breakpoints.down('md')]: {
                width: origin === 'reset' ? '100%' : null,
              },
              [BreakPointTheme.breakpoints.up('md')]: {
                width: origin === 'reset' ? '40%' : null,
              },
            }}>
            <reset.ButtonReset
              variant="contained"
              disabled={counterAttempts === 0}
              loading={ResetLoading}
              type="submit">
              Continuar
            </reset.ButtonReset>
          </Box>
        </div>
      </form>
    </>
  );
};
export default CodeValidator;

import { LoadingButton } from '@mui/lab';
import { FormControl, FormHelperText } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance } from 'src/config/axiosMiddleware';
import { API_KEY } from 'src/Data/Api';
import useForm from 'src/hooks/useForms';
import {
  logsOnboarding,
  sendSmsToVerify,
} from 'src/store/actions/requestCard.actions';
import {
  APP,
  EXPIRED_OTP_MESSAGE,
  MAX_ATTEMPTS,
  MAX_ATTEMPTS_MESSAGE,
  TYPE_PHONE,
} from 'src/utils/Constants';
import { openNotificationWithIcon } from 'src/utils/Functions';
import * as Yup from 'yup';
import {
  default as ImagePhone,
  default as Phone,
} from '../../../assets/images/onboardingCredito/messagePhone.svg';
import ModalCommon from '../../../components/Modal/ModalCommon';
import { CodeVerification, TextFieldComponent } from '../Component/FieldsForms';
const initialData = {
  code: {
    name: 'code',
    label: 'Ingresa el código de verificación aquí',
    errorMessages: 'Código ingresado inválido',
  },
};
/**
 * Componente de validacion de codigo de telefono
 * Comportamiento:
 * El contador se debe de mostrar con el onShow, ademas debera de permitir
 * validar el codigo en varias ocaciones, en base a los MAX_ATTEMPTS permitidos
 * De igual forma se puede reenviar el codigo
 * El OTP tiene validez de 5 minutos.
 */

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Campo requerido')
    .min(6, 'Código inválido')
    .max(6, 'Código inválido')
    .matches(/^[0-9]+$/, 'Código inválido'),
});

const ModalValidationPhone = forwardRef(
  (
    {
      phone = '',
      onConfirm,
      onClose = () => {},
      startTimerPhone,
      countdownPhoneFormat,
      initialTimerPhone,
      finishTimer,
      stopTimerPhone,
    },
    ref,
  ) => {
    const {loadingSms, originSourceOnboarding} = useSelector(state => {
      return state.RequestCardReducer;
    });
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    // loading to send sms and call
    const [loading, setLoading] = useState(false);
    const [loadingCall, setLoadingCall] = useState(false);
    // state to display to modal call
    const [displayModal, setDisplay] = useState(false);
    //state to show modal call
    const [callModal, setCallModal] = useState(false);
    //state to executeTimer and countclick sms and call
    const [executeTimer, setExecuteTimer] = useState(false);
    const [countClickConfirmPhone, setCountClickComirmPhone] = useState(0);
    const [countClickReSendPhone, setCountClickReSendPhone] = useState(0);
    const [
      countClickConfirmCallPhone,
      setCountClickConfirmCallPhone,
    ] = useState(0);
    const [countClickReSendCallPhone, setCountClickReSendCallPhone] = useState(
      0,
    );
    //state to count attemps
    const [isMaxCountModalPhone, setMaxCountModalPhone] = useState(false);
    const [isMaxCountModalPhoneCall, setMaxCountModalPhoneCall] = useState(
      false,
    );
    //state to erroCode
    const [errorCode, setErrorCode] = useState(false);
    //state to ResendCode
    const [isResendPhoneCode, setResendPhoneCode] = useState(false);
    //state to expired OTP code
    const [isExpiredSms, setIsExpiredSms] = useState(false);
    const [isExpiredCall, setIsExpiredCall] = useState(false);
    const [isChangeFunctionSMS, setChangeFunctionSMS] = useState(false);
    const [isChangeFunctionCall, setChangeFunctionCall] = useState(false);

    const {
      formData,
      getValue,
      handleChange,
      handleBlur,
      showError,
      getError,
      validateField,
      clearForm,
    } = useForm(initialData, validationSchema);
    useEffect(() => {
      if (finishTimer) {
        setExecuteTimer(false);
        setResendPhoneCode(false);
        setChangeFunctionSMS(true);
        setChangeFunctionCall(true);
      }
    }, [finishTimer]);
    // function to reset initial state
    const resetState = () => {
      setExecuteTimer(false);
      setErrorCode(false);
      setCountClickReSendCallPhone(0);
      setCountClickReSendPhone(0);
      setMaxCountModalPhone(false);
      setMaxCountModalPhoneCall(false);
      setResendPhoneCode(false);
      setIsExpiredSms(false);
      setIsExpiredCall(false);
      setChangeFunctionSMS(false);
      setChangeFunctionCall(false);
    };

    const isMaxAttempsPhone = () => {
      setMaxCountModalPhone(true);
    };
    const isMaxAttempsPhoneCall = () => {
      setMaxCountModalPhoneCall(true);
    };
    const show = () => {
      resetState();
      clearForm();
      setIsModalOpen(true);
      initialTimerPhone();
      setExecuteTimer(true);
      startTimerPhone();
      setDisplay(false);
      setChangeFunctionSMS(false);
      setChangeFunctionCall(false);
    };
    const hide = () => {
      clearForm();
      resetState();
      initialTimerPhone();
      setIsModalOpen(false);
      onClose();
    };
    const hideModal = () => {
      clearForm();
      onClose();
      setIsModalOpen(false);
    };
    //function to validate code sms
    const handleClick = () => {
      if (!getError('code')) {
        setCountClickComirmPhone(countClickConfirmPhone + 1);
        if (countClickConfirmPhone < MAX_ATTEMPTS) {
          setExecuteTimer(true);
          validateField('code', verifyCode);
        } else {
          errorValidation();
        }
      }
    };
    //function to resendcode sms
    const resendCodeSMS = () => {
      setDisplay(false);
      setCountClickReSendPhone(countClickReSendPhone + 1);
      if (isExpiredSms || isExpiredCall || !executeTimer) {
        initialTimerPhone();
        startTimerPhone();
      }
      if (countClickReSendPhone < MAX_ATTEMPTS) {
        setErrorCode(false);
        setIsExpiredSms(false);
        setIsExpiredCall(false);
        clearForm();
        dispatch(sendSmsToVerify(phone, originSourceOnboarding, onComplete));
        setChangeFunctionSMS(false);
        openNotificationWithIcon(
          'success',
          'Ingresa el código que te enviamos por mensaje de texto',
        );
        setCountClickComirmPhone(0);
      } else {
        errorValidation();
      }
    };
    //function to confirm to code sms
    const verifyCode = async values => {
      setLoading(true);
      setErrorCode(false);
      try {
        const res = await axiosInstance.post(
          `api/EmailServices/confirmVerify`,
          {
            to: TYPE_PHONE.PA + phone,
            code: formData.code.value,
          },
        );

        if (res.data.complete) {
          hide();
          onConfirm();
          initialTimerPhone();
          setExecuteTimer(false);
          stopTimerPhone();
        }
      } catch (err) {
        setErrorCode(true);
        if (err?.response?.data?.problemPublic) {
          const errorMessage = err?.response?.data?.problemPublic;
          //codigo expirado
          if (errorMessage.includes('Max check attempts reached')) {
            clearForm();
            setErrorCode(true);
            setMaxCountModalPhone(true);
            openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
            clearForm();
            setErrorCode(true);
          } else if (
            errorMessage.includes(
              'VA0421c5090c9e8ee0c397405bf7fb7755/VerificationCheck was not found',
            )
          ) {
            openNotificationWithIcon('error', 'Codigo expirado');
            clearForm();
            setErrorCode(true);
            setCountClickComirmPhone(0);
            setIsExpiredSms(true);
          }
        } else if (err?.response?.data?.jsonAnswer) {
          //codigo invalido
          const jsonResponse = JSON.parse(err?.response?.data?.jsonAnswer);
          if (jsonResponse.valid === false) {
            openNotificationWithIcon(
              'error',
              'Código incorrecto, ingrésalo nuevamente',
            );
            clearForm();
            setErrorCode(true);
          }
        } else if (countClickConfirmPhone > MAX_ATTEMPTS) {
          //intentos maximos
          clearForm();
          setErrorCode(true);
          setMaxCountModalPhone(true);
          openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
          clearForm();
          setErrorCode(true);
        }
      } finally {
        setLoading(false);
      }
    };
    //function to code is incorrect and show error message
    const errorValidation = () => {
      hideModal();
      setDisplay(false);
      isMaxAttempsPhone();
      initialTimerPhone();
      startTimerPhone();
      setExecuteTimer(true);
      setCountClickReSendPhone(0);
      openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
    };
    //funtion to send code call
    const handleClickCall = () => {
      setCallModal(true);
      setDisplay(false);
      setCountClickConfirmCallPhone(countClickConfirmCallPhone + 1);
      if (isExpiredSms || isExpiredCall || !executeTimer) {
        initialTimerPhone();
        startTimerPhone();
      }
      if (countClickConfirmCallPhone < MAX_ATTEMPTS) {
        setIsExpiredSms(false);
        setIsExpiredCall(false);
        clearForm();
        sendCall();
        setExecuteTimer(true);
        setChangeFunctionCall(false);
        openNotificationWithIcon(
          'success',
          'Ingresa el código que te enviamos por llamada de voz',
        );
        // setCountClickComirmPhone(0)
        setCountClickConfirmCallPhone(0);
      } else {
        errorValidationCall();
      }
    };
    //function to validate code
    const validationCall = () => {
      setCountClickReSendPhone(countClickReSendCallPhone + 1);
      if (countClickReSendCallPhone < MAX_ATTEMPTS) {
        setErrorCode(false);
        validateField('code', onFinishCall);
      } else {
        errorValidation();
      }
    };
    function CallModal() {
      setDisplay(true);
      setCallModal(false);
    }
    //ws to send call
    async function sendCall() {
      setLoadingCall(true);
      try {
        /* only for test email */
        if (APP.fakePhone) {
          return;
        }
        await axiosInstance.post(
          `api/EmailServices/sendPhoneCallverify`,
          {
            to: TYPE_PHONE.PA + phone,
          },
          {
            headers: {
              Apikey: API_KEY,
              'content-type': 'application/json',
            },
          },
        );
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'No se ha podido enviar el código por llamada',
        );
        await logsOnboarding(
          '',
          '',
          'OnboardingConfirmCall',
          e?.response,
          phone,
          false,
        );
      } finally {
        setLoadingCall(false);
      }
    }
    // ws to validate code call
    async function onFinishCall(values) {
      try {
        setErrorCode(false);
        setLoading(true);
        const res = await axiosInstance.post(
          `api/EmailServices/confirmPhoneCallVerify`,
          {
            to: TYPE_PHONE.PA + phone,
            code: formData.code.value,
          },
        );

        if (res.data.complete) {
          hide();
          onConfirm();
          setCallModal(false);
        }
      } catch (err) {
        setErrorCode(true);
        if (err?.response?.data?.problemPublic) {
          const errorMessage = err?.response?.data?.problemPublic;
          //codigo expirado
          if (errorMessage.includes('Max check attempts reached')) {
            clearForm();
            setErrorCode(true);
            setMaxCountModalPhoneCall(true);
            openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
            clearForm();
            setErrorCode(true);
          } else if (
            errorMessage.includes(
              'VA0421c5090c9e8ee0c397405bf7fb7755/VerificationCheck was not found',
            )
          ) {
            openNotificationWithIcon('error', 'El código ingresado ya expiró');
            clearForm();
            setErrorCode(true);
            setCountClickConfirmCallPhone(0);
            setIsExpiredCall(true);
          }
        } else if (err?.response?.data?.jsonAnswer) {
          //codigo invalido
          const jsonResponse = JSON.parse(err?.response?.data?.jsonAnswer);
          if (jsonResponse.valid === false) {
            openNotificationWithIcon(
              'error',
              'Código incorrecto, ingrésalo nuevamente',
            );
            clearForm();
            setErrorCode(true);
          }
        } else if (countClickConfirmPhone > MAX_ATTEMPTS) {
          //intentos maximos
          clearForm();
          setErrorCode(true);
          setMaxCountModalPhoneCall(true);
          openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
          clearForm();
          setErrorCode(true);
        }
      } finally {
        setLoading(false);
      }
    }
    const onComplete = success => {
      if (success) {
        setResendPhoneCode(true);
      }
    };
    //function to error code in call
    const errorValidationCall = () => {
      hideModal();
      setDisplay(false);
      isMaxAttempsPhoneCall();
      initialTimerPhone();
      startTimerPhone();
      setExecuteTimer(true);
      setCountClickReSendCallPhone(0);
      openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
    };
    return (
      <>
        <ModalCommon
          isOpen={isModalOpen}
          onHide={hide}
          onShow={show}
          ref={ref}
          modalClassName={{
            overlay: 'bg-[rgba(0,0,0,0.8)]',
            modal:
              'border border-2 border-gray-300 relative w-full md:w-[80%] lg:w-[719px] h-full md:h-[auto] lg:h-[auto] m-0',
            closeButton: 'hidden',
          }}
          bodyClassName="!mt-[30px] md:mt-16 flex flex-col items-center justify-center">
          {displayModal ? (
            <div className="w-full md:w-[95%] flex items-center justify-center flex-col md:h-[auto]">
              <img src={Phone} alt="send" />
              <h1 className="text-[#011E41] lg:px-20 font-semibold text-[22px] md:text-[30px] mt-[10px] md:mt-[10px] text-center md:leading-[35px]">
                No hemos recibido la confirmación de tu celular
              </h1>
              <div className="flex justify-center mt-[15px] md:mt-[15px] mb-[15px]"></div>
              <div className="mt-[15px] md:mt-[10px] mb-[35px] w-full">
                <LoadingButton
                  loading={loadingSms}
                  sx={{
                    backgroundColor: '#53A600 !important',
                    borderRadius: '9999px',
                    display: 'flex',
                    marginTop: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60px',
                    width: '100%',
                  }}
                  onClick={resendCodeSMS}>
                  <Typography
                    variant="buttonStyle"
                    sx={{color: '#fff', fontSize: '22px', fontWeight: '400'}}>
                    Solicita un nuevo código
                  </Typography>
                </LoadingButton>
                <LoadingButton
                  loading={loadingCall}
                  sx={{
                    backgroundColor: '#fff',
                    borderWidth: '2px',
                    border: ' 2px solid #026E18 ',
                    marginTop: '10px',
                    borderRadius: '9999px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60px',
                    width: '100%',
                  }}
                  onClick={handleClickCall}>
                  <Typography
                    variant="buttonStyle"
                    sx={{
                      color: '#026E18',
                      fontSize: '22px',
                      fontWeight: '400',
                    }}>
                    Solicita validación por llamada de voz
                  </Typography>
                </LoadingButton>
              </div>
            </div>
          ) : !callModal && !displayModal ? (
            <div className="w-full md:w-[80%] flex items-center justify-center flex-col ">
              <img src={ImagePhone} alt="send" />
              <h1 className="text-[#011E41] font-semibold text-[22px] md:text-[30px] mt-[5px] text-center leading-[20px] md:leading-[35px]">
                Validación de tu número de celular
              </h1>
              <p className="text-base md:text-xl mt-[5px] md:mt-[10px] text-center">
                Ingresa el código que te enviamos por mensaje de texto
              </p>
              <div className="mt-[5px] md:mt-[5px] w-full">
                <FormControl fullWidth error={showError('code')}>
                  <TextFieldComponent
                    placeholder={initialData.code.label}
                    value={getValue('code')}
                    onChange={e => handleChange('code', e.target.value)}
                    onBlur={() => handleBlur('code')}
                    InputProps={{
                      inputComponent: CodeVerification,
                    }}
                    error={showError('code') || errorCode}
                  />
                  {showError('code') && (
                    <FormHelperText>{getError('code')}</FormHelperText>
                  )}
                </FormControl>
                <div className="flex justify-center mt-[10px] md:mt-[10px]">
                  {!isChangeFunctionSMS ? (
                    <LoadingButton
                      disabled={
                        getValue('code') === ''
                          ? true
                          : loading ||
                            isMaxCountModalPhoneCall ||
                            isMaxCountModalPhone ||
                            getError('code') ||
                            isExpiredSms ||
                            isExpiredCall
                      }
                      loading={loading}
                      variant="onboarding"
                      style={{
                        background: 'var(--linear-bg)',
                        fontFamily: 'Red Hat Text',
                        color: '#fff',
                        fontSize: '22px',
                        fontWeight: '400',
                      }}
                      onClick={() => handleClick()}
                      className=" text-white rounded-full flex items-center justify-center h-[60px] md:h-[76px] w-full md:w-[70%] lg:w-[50%]">
                      Aceptar
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      loading={loadingSms}
                      sx={{
                        backgroundColor: '#53A600 !important',
                        borderRadius: '9999px',
                        display: 'flex',
                        marginTop: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '60px',
                        width: '100%',
                      }}
                      onClick={resendCodeSMS}>
                      <Typography
                        variant="buttonStyle"
                        sx={{
                          color: '#fff',
                          fontSize: '22px',
                          fontWeight: '400',
                        }}>
                        Solicita un nuevo código
                      </Typography>
                    </LoadingButton>
                  )}
                </div>
                <div className="flex justify-center mt-[5px] md:mt-[5px]">
                  {isMaxCountModalPhoneCall || isMaxCountModalPhone ? (
                    <p className="text-center font-semibold text-[16px] text-[#d52007] mt-[5px] md:mt-[5px]">
                      {MAX_ATTEMPTS_MESSAGE}
                    </p>
                  ) : isExpiredSms || isExpiredCall ? (
                    <p className="text-center font-semibold text-[16px] text-[#d52007] mt-[5px] md:mt-[5px]">
                      {EXPIRED_OTP_MESSAGE}
                    </p>
                  ) : (
                    (executeTimer || isResendPhoneCode) && (
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p className="text-center text-[16px] text-[#026E18] mt-[5px] md:mt-[5px]">
                          El código de verificación expirará en:{' '}
                          <strong>{countdownPhoneFormat} min</strong>
                        </p>
                      </div>
                    )
                  )}
                </div>
                <p
                  role="button"
                  className={`text-center text-[17px] mt-[5px] md:mt-[5px] ${
                    isExpiredSms || isExpiredCall
                      ? 'text-[#026E18] underline font-bold'
                      : 'text-[#026E18] underline'
                  }`}
                  onClick={CallModal}>
                  ¿Quieres probar otro método de verificación?
                </p>
                <p
                  role="button"
                  className="text-center text-[17px] text-[#026E18] mt-[5px] md:mt-[5px] underline"
                  onClick={hide}>
                  ¿Deseas cambiar tu número de celular?
                </p>
              </div>
            </div>
          ) : (
            callModal &&
            !displayModal && (
              <div className="w-full md:w-[80%] flex items-center justify-center flex-col ">
                <img src={ImagePhone} alt="send" />
                <h1 className="text-[#011E41] font-semibold text-[22px] md:text-[30px] mt-[5px] text-center leading-[20px] md:leading-[35px]">
                  Validación de tu número de celular
                </h1>
                <p className="text-base md:text-xl mt-[5px] md:mt-[10px] text-center">
                  Ingresa el código que te enviamos por llamada de voz a tu
                  número de celular
                </p>
                <div className="mt-[10px] md:mt-[10px] w-full">
                  <FormControl fullWidth error={showError('code')}>
                    <TextFieldComponent
                      placeholder={initialData.code.label}
                      value={getValue('code')}
                      onChange={e => handleChange('code', e.target.value)}
                      onBlur={() => handleBlur('code')}
                      InputProps={{
                        inputComponent: CodeVerification,
                      }}
                      error={showError('code') || errorCode}
                    />
                    {showError('code') && (
                      <FormHelperText>{getError('code')}</FormHelperText>
                    )}
                  </FormControl>
                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    {!isChangeFunctionCall ? (
                      <LoadingButton
                        disabled={
                          getValue('code') === ''
                            ? true
                            : loading ||
                              isMaxCountModalPhoneCall ||
                              isMaxCountModalPhone ||
                              getError('code') ||
                              isExpiredSms ||
                              isExpiredCall
                        }
                        loading={loading}
                        variant="onboarding"
                        style={{
                          background: 'var(--linear-bg)',
                          fontFamily: 'Red Hat Text',
                          color: '#fff',
                          fontSize: '22px',
                          fontWeight: '400',
                        }}
                        onClick={() => validationCall()}
                        className=" text-white rounded-full flex items-center justify-center h-[60px] md:h-[76px] w-full md:w-[70%] lg:w-[50%]">
                        {' '}
                        Aceptar
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        loading={loadingCall}
                        variant="onboarding"
                        style={{
                          background: 'var(--linear-bg)',
                          fontFamily: 'Red Hat Text',
                          color: '#fff',
                          fontSize: '22px',
                          fontWeight: '400',
                        }}
                        className=" text-white rounded-full flex items-center justify-center h-[60px] md:h-[76px] w-full md:w-[70%] lg:w-[50%]"
                        onClick={() => handleClickCall()}>
                        <Typography
                          variant="buttonStyle"
                          sx={{
                            color: '#fff',
                            fontSize: '22px',
                            fontWeight: '400',
                          }}>
                          Solicita validación por llamada de voz
                        </Typography>
                      </LoadingButton>
                    )}
                  </div>
                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    {isMaxCountModalPhoneCall || isMaxCountModalPhone ? (
                      <p className="text-center font-semibold text-[16px] text-[#d52007] mt-[5px] md:mt-[5px]">
                        {MAX_ATTEMPTS_MESSAGE}
                      </p>
                    ) : isExpiredSms || isExpiredCall ? (
                      <p className="text-center font-semibold text-[16px] text-[#d52007] mt-[5px] md:mt-[5px]">
                        {EXPIRED_OTP_MESSAGE}
                      </p>
                    ) : (
                      (executeTimer || isResendPhoneCode) && (
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                          <p className="text-center text-[16px] text-[#026E18] mt-[5px] md:mt-[5px]">
                            El código de verificación expirará en:{' '}
                            <strong>{countdownPhoneFormat} min</strong>
                          </p>
                        </div>
                      )
                    )}
                  </div>
                  <p
                    role="button"
                    className={`text-center text-[17px] mt-[5px] md:mt-[5px] ${
                      isExpiredSms || isExpiredCall
                        ? 'text-[#026E18] underline font-bold'
                        : 'text-[#026E18] underline'
                    }`}
                    onClick={CallModal}>
                    ¿Quieres probar otro método de verificación?
                  </p>
                  <p
                    role="button"
                    className="text-center text-[17px] text-[#026E18] mt-[5px] md:mt-[5px] underline"
                    onClick={hide}>
                    ¿Deseas cambiar tu número de celular?
                  </p>
                </div>
              </div>
            )
          )}
        </ModalCommon>
      </>
    );
  },
);

export default ModalValidationPhone;

import {default as React, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import logoCMF from 'src/assets/images/cmf_linea/logoCMF.svg';
import ButtonBackModal from 'src/components/Button/ButtonBackModal';
import {messageCMF} from 'src/theme/constants';
import '../../../App.css';
import CodeValidator from './CodeValidator';
import NewPassword from './NewPassword';
import SendEmailReset from './SendEmailReset';
import * as reset from './resetPassword.style';

const Reset = () => {
  const [emailReset, setEmail] = useState(null);
  const [newPasswordComponent, setNewPasswordComponent] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const {Authenticated} = useSelector(state => state.AuthenticationReducer);
  const dispatch = useDispatch();
  const onSuccess = success => {
    if (success && Authenticated === 3) {
      setActiveStep(0);
    } else if (success) {
      setActiveStep(1);
    }
  };
  const onNextPassword = success => {
    if (success && Authenticated === 3) {
      setActiveStep(1);
    } else if (success) {
      setActiveStep(2);
    }
  };
  const getPassword = password => {
    setNewPasswordComponent(password);
  };
  const email = email => {
    setEmail(email);
  };
  useEffect(() => {
    const handlePageReload = () => {
      dispatch({type: 'RESET_LOGIN'});
    };

    window.onload = handlePageReload;

    return () => {
      window.onload = null; // Limpiar el event listener al desmontar el componente
    };
  }, [dispatch]);
  const getStepContent = stepIndex => {
    if (Authenticated === 3) {
      switch (stepIndex) {
        case 0:
          return (
            <NewPassword
              email={emailReset}
              isOut={true}
              onNextPassword={onNextPassword}
              getPassword={getPassword}
            />
          );

        case 1:
          return (
            <CodeValidator
              email={emailReset}
              password={newPasswordComponent}
              isOut={true}
            />
          );
        default:
          break;
      }
    } else {
      switch (stepIndex) {
        case 0:
          return (
            <SendEmailReset onSuccess={onSuccess} email={email} isOut={true} />
          );
        case 1:
          return (
            <NewPassword
              email={emailReset}
              onNextPassword={onNextPassword}
              getPassword={getPassword}
              isOut={true}
            />
          );
        case 2:
          return (
            <CodeValidator
              email={emailReset}
              password={newPasswordComponent}
              isOut={true}
            />
          );
        default:
          break;
      }
    }
  };

  const navigate = useNavigate();
  const handleBack = () => {
    //Button back to login
    if (activeStep === 0) {
      navigate('/');
    }
    setActiveStep(prevActiveStep =>
      prevActiveStep === 0 ? 0 : prevActiveStep - 1,
    );
  };

  return (
    <>
      <reset.Containt>
        <reset.ResetContainer>
          <reset.LoginDescription>
            <img
              role="button"
              alt="Logo CMF"
              style={{
                cursor: 'pointer',
              }}
              src={logoCMF}
              onClick={() => {
                navigate('/');
              }}
            />
          </reset.LoginDescription>
          <reset.formPassword>
            <reset.FormL>
              <ButtonBackModal
                types="Page"
                texto="Volver"
                onClick={handleBack}
              />
              {getStepContent(activeStep)}
            </reset.FormL>
          </reset.formPassword>
        </reset.ResetContainer>
        <reset.divCopy>
          <reset.CopyContainer>
            <reset.pCopy>{messageCMF.copyright}</reset.pCopy>
          </reset.CopyContainer>
        </reset.divCopy>
      </reset.Containt>
    </>
  );
};
export default Reset;

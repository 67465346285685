export const AccountApi = process.env.REACT_APP_ACCOUNT_API;
export const ActiveProducts = process.env.REACT_APP_ACTIVE_PRODUCTS;
export const ExtraFinanApi = process.env.REACT_APP_EXTRA_FINAN_API;
export const movementApi = process.env.REACT_APP_MOVEMENT_API;
export const PointsApi = process.env.REACT_APP_POINTS_API;
export const ProductCatalogue = process.env.REACT_APP_PRODUCTS_CATALOGUE;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const WALLET_API = process.env.REACT_APP_API_WALLET;
export const Cmf_Base = process.env.REACT_APP_CMF_BASE;
export const Cmf_Base02 = process.env.REACT_APP_CMF_BASE02;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const EID_Base = process.env.REACT_APP_ELECTRONIC_ID_BASE;
export const CMF_MANAGEMENT = process.env.REACT_APP_CMF_MANAGEMENT;
export const TOKEN = process.env.REACT_APP_TOKEN;
export const MDL30 = process.env.REACT_APP_MDL30;
export const BasicAuth = process.env.REACT_APP_USER_MANAGMENT_TOKEN;
export const URL = process.env.REACT_APP_URL;
export const Api_Url = process.env.REACT_APP_API_URL;
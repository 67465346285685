import { LoadingButton } from '@mui/lab';
import { FormControl, FormHelperText } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance } from 'src/config/axiosMiddleware';
import useForm from 'src/hooks/useForms';
import { sendEmailToVerify } from 'src/store/actions/requestCard.actions';
import {
  EXPIRED_OTP_MESSAGE,
  MAX_ATTEMPTS,
  MAX_ATTEMPTS_MESSAGE,
  TIMER_SECONDS,
} from 'src/utils/Constants';
import { openNotificationWithIcon } from 'src/utils/Functions';
import * as Yup from 'yup';
import ImageMail from '../../../assets/images/onboardingCredito/messageMail.svg';
import ModalCommon from '../../../components/Modal/ModalCommon';
import { CodeVerification, TextFieldComponent } from '../Component/FieldsForms';
/**
 * Componente de validacion de codigo de correo
 * Comportamiento:
 * El contador se debe de mostrar con el onShow, ademas debera de permitir
 * validar el codigo en varias ocaciones, en base a los MAX_ATTEMPTS permitidos
 * De igual forma se puede reenviar el codigo
 * El OTP tiene validez de 5 minutos.
 */
const initialData = {
  code: {
    name: 'code',
    label: 'Ingresa el código de verificación aquí',
    errorMessages: 'Código ingresado inválido',
  },
};

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Campo requerido')
    .min(6, 'Código inválido')
    .max(6, 'Código inválido')
    .matches(/^[0-9]+$/, 'Código inválido'),
});

const ModalValidationMail = forwardRef(
  (
    {
      email = '',
      onConfirm,
      onClose = () => {},
      startTimerMail,
      countdownMailFormat,
      initialTimerMail,
      finishTimer,
      stopTimerMail,
    },
    ref,
  ) => {
    const {loadingEmail, originSourceOnboarding} = useSelector(state => {
      return state.RequestCardReducer;
    });
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    // state to executeTimer
    const [executeTimer, setExecuteTimer] = useState(false);
    //state to errorcode
    const [errorCode, setErrorCode] = useState(false);
    //state to count click confirm code adn confirm resendcode
    const [countClickConfirm, setCountClickConfirm] = useState(0);
    const [countClickResend, setCountClickResend] = useState(0);
    //state to maxAttemps
    const [isMaxCountModalMail, setMaxCountModalMail] = useState(false);
    //state to resendCode
    const [isResendMailCode, setResendMailCode] = useState(false);
    // state to expiredCode
    const [isExpired, setIsExpired] = useState(false);
    //timer to resendCode
    const [isChangeFunction, setChangeFunction] = useState(false);
    const {
      formData,
      getValue,
      handleChange,
      handleBlur,
      showError,
      getError,
      validateField,
      clearForm,
    } = useForm(initialData, validationSchema);
    useEffect(() => {
      // indetifica cuando se finalizo el timer para cambiar los textos
      if (finishTimer) {
        initialTimerMail();
        setExecuteTimer(false);
        setResendMailCode(false);
        setChangeFunction(true);
      }
    }, [finishTimer]);

    // function to reset initial state
    const resetState = () => {
      setExecuteTimer(false);
      setErrorCode(false);
      setCountClickConfirm(0);
      setCountClickResend(0);
      setMaxCountModalMail(false);
      setResendMailCode(false);
      setIsExpired(false);
      setChangeFunction(false);
    };
    const show = () => {
      resetState();
      clearForm();
      setIsModalOpen(true);
      initialTimerMail();
      setExecuteTimer(true);
      startTimerMail(TIMER_SECONDS);
      setChangeFunction(false);
    };
    const hide = () => {
      clearForm();
      resetState();
      initialTimerMail();
      setIsModalOpen(false);
      onClose();
    };
    //function to code is incorrect and show error message
    const errorValidation = () => {
      setMaxCountModalMail(true);
      initialTimerMail();
      startTimerMail(TIMER_SECONDS);
      setExecuteTimer(true);
      setCountClickConfirm(0);
      openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
      stopTimerMail();
    };
    // funcion para validar codigo
    const handleClick = () => {
      if (!getError('code')) {
        setCountClickConfirm(countClickConfirm + 1);
        if (countClickConfirm < MAX_ATTEMPTS) {
          validateField('code', verifyCode);
        } else {
          errorValidation();
        }
      }
      // if (!executeTimer) {
      //   setExecuteTimer(true)
      //   startTimerMail(TIMER_SECONDS)
      // }
    };
    const onComplete = success => {
      setResendMailCode(success);
    };
    // function to call ws resend code
    const resendCode = () => {
      setCountClickResend(countClickResend + 1);
      if (isExpired || !executeTimer) {
        initialTimerMail();
        startTimerMail(TIMER_SECONDS);
      }
      if (countClickResend < MAX_ATTEMPTS) {
        setIsExpired(false);
        setErrorCode(false);
        clearForm();
        dispatch(sendEmailToVerify(email, originSourceOnboarding, onComplete));
        setChangeFunction(false);
        openNotificationWithIcon(
          'success',
          'Ingresa el código que enviamos a tu correo electrónico',
        );
        setCountClickConfirm(0);
      } else {
        errorValidation();
      }
    };
    //ws to confirm mail code
    const verifyCode = async values => {
      setErrorCode(false);
      setLoading(true);
      try {
        const res = await axiosInstance.post(
          `api/EmailServices/confirmEmailVerify`,
          {
            to: email,
            code: formData.code.value,
          },
        );
        if (res.data.complete) {
          initialTimerMail();
          onConfirm();
          setExecuteTimer(false);
          stopTimerMail();
          hide();
        }
      } catch (err) {
        if (err?.response?.data?.problemPublic) {
          const errorMessage = err?.response?.data?.problemPublic;
          //codigo expirado
          if (errorMessage.includes('Max check attempts reached')) {
            clearForm();
            setErrorCode(true);
            setMaxCountModalMail(true);
            openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
            clearForm();
            setErrorCode(true);
          } else if (
            errorMessage.includes(
              'VA0421c5090c9e8ee0c397405bf7fb7755/VerificationCheck was not found',
            )
          ) {
            openNotificationWithIcon('error', 'Código expirado');
            clearForm();
            setErrorCode(true);
            setCountClickConfirm(0);
            setIsExpired(true);
          }
        } else if (err?.response?.data?.jsonAnswer) {
          //codigo invalido
          const jsonResponse = JSON.parse(err?.response?.data?.jsonAnswer);
          if (jsonResponse.valid === false) {
            openNotificationWithIcon(
              'error',
              'Código incorrecto, ingrésalo nuevamente',
            );
            clearForm();
            setErrorCode(true);
          }
        } else if (countClickConfirm > MAX_ATTEMPTS) {
          //intentos maximos
          clearForm();
          setErrorCode(true);
          setMaxCountModalMail(true);
          openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
          clearForm();
          setErrorCode(true);
        }
      } finally {
        setLoading(false);
      }
    };
    // funcion para reenviar codigo y evitar multi clicks en la opcion
    const renderResendText = () => {
      if (!loadingEmail) {
        return (
          <LoadingButton
            disabled={loading}
            variant="onboarding"
            loading={loading}
            onClick={() => resendCode()}
            sx={{
              fontFamily: 'Red Hat Text',
              backgroundColor: '#53A600 !important',
              borderRadius: '9999px',
              display: 'flex',
              marginTop: '10px',
              alignItems: 'center',
              justifyContent: 'center',
              height: '60px',
              width: '100%',
            }}>
            <Typography
              variant="buttonStyle"
              sx={{color: '#fff', fontSize: '22px', fontWeight: '400'}}>
              Solicita un nuevo código
            </Typography>
          </LoadingButton>
        );
      }
    };
    return (
      <ModalCommon
        isOpen={isModalOpen}
        onHide={hide}
        onShow={show}
        ref={ref}
        modalClassName={{
          overlay: 'bg-[rgba(0,0,0,0.8)]',
          modal:
            'border border-2 border-gray-300 relative w-full md:w-[80%] lg:w-[719px] h-full md:h-[auto] lg:h-[auto] m-0',
          closeButton: 'hidden',
        }}
        bodyClassName="!mt-[30px] md:mt-16 flex flex-col items-center justify-center">
        <div className="w-full md:w-[80%] flex items-center justify-center flex-col ">
          <img src={ImageMail} alt="send" />
          <h1 className="text-[#011E41] font-semibold text-[22px] md:text-[30px] mt-[5px] text-center leading-[20px] md:leading-[35px]">
            Validación de correo electrónico
          </h1>
          <p className="text-base md:text-xl mt-[5px] md:mt-[10px] text-center">
            Ingresa el código que te enviamos por correo electrónico
          </p>

          <div className="mt-[5px] md:mt-[5px] w-full">
            <FormControl fullWidth error={showError('code')}>
              <TextFieldComponent
                placeholder={initialData.code.label}
                value={getValue('code')}
                InputProps={{
                  inputComponent: CodeVerification,
                }}
                onChange={e => handleChange('code', e.target.value)}
                onBlur={() => handleBlur('code')}
                error={showError('code') || errorCode}
              />
              {(showError('code') || errorCode) && (
                <FormHelperText>{getError('code')}</FormHelperText>
              )}
            </FormControl>
            <div className="flex justify-center mt-[5px] md:mt-[10px]">
              {!isChangeFunction ? (
                <LoadingButton
                  disabled={
                    getValue('code') === ''
                      ? true
                      : loading ||
                        getError('code') ||
                        isMaxCountModalMail ||
                        isExpired
                  }
                  variant="onboarding"
                  loading={loading}
                  style={{
                    background: 'var(--linear-bg)',
                    fontFamily: 'Red Hat Text',
                    color: '#fff',
                    fontSize: '22px',
                    fontWeight: '400',
                  }}
                  onClick={() => handleClick()}
                  className=" text-white rounded-full flex items-center justify-center h-[60px] md:h-[76px] w-full md:w-[70%] lg:w-[50%]">
                  Aceptar
                </LoadingButton>
              ) : (
                !isMaxCountModalMail && renderResendText()
              )}
            </div>
            <div className="flex flex-col justify-center mt-[5px] md:mt-[11px]">
              {isMaxCountModalMail ? (
                <p className="text-center font-semibold text-[16px] text-[#d52007] mt-[5px] md:mt-[5px]">
                  {MAX_ATTEMPTS_MESSAGE}
                </p>
              ) : isExpired ? (
                <p className="text-center font-semibold text-[16px] text-[#d52007] mt-[5px] md:mt-[5px]">
                  {EXPIRED_OTP_MESSAGE}
                </p>
              ) : (
                (executeTimer || isResendMailCode) && (
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p className="text-center  text-[16px] text-[#026E18] mt-[5px] md:mt-[5px]">
                      El código de verificación expirará en:{' '}
                      <strong>{countdownMailFormat} min</strong>
                    </p>
                  </div>
                )
              )}
            </div>
            <p
              role="button"
              className="text-center text-[17px] text-[#026E18] mt-[5px] md:mt-[5px] underline"
              onClick={hide}>
              ¿Deseas cambiar tu correo electrónico?
            </p>
          </div>
        </div>
      </ModalCommon>
    );
  },
);

export default ModalValidationMail;

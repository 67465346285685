import { notification } from 'antd';
// import {publicIp, publicIpv4, publicIpv6} from 'public-ip';
import axios from 'axios';
import publicIp from 'public-ip';
import { NotificationManager } from 'react-notifications';
import { Cmf_Base } from 'src/Data/Api';
import { axiosInstance } from 'src/config/axiosMiddleware';
import { createOnsite, getIdentityId, getTokenMdl03 } from '../../Data/Client';
import {
  ACTIVATE_ONBOARDING,
  ACTIVATE_PRODUCT,
  ACTIVE_PROD_STATUS_HIDE,
  ACTIVE_PROD_STATUS_SHOW,
  ADD_IDENTITY_BATCH,
  ADD_IDENTITY_EID_SUCCESS,
  CLEAR_PDF,
  DATA_MATRIZ_SIGN,
  DIALOG_MESSAGE,
  DOCUMENT_UPLOADED_ERROR,
  DOCUMENT_UPLOADED_SUCCESS,
  EDIT_REQUEST_CARD_FORM,
  FORM_REQUEST_SUCCESS,
  FORM_REQUEST_UPDATE,
  GET_ACTIVE_BANNER,
  GET_ACTIVITY_TYPES,
  GET_CLEAN_BANNER,
  GET_COUNTRIES,
  GET_COUNTRIES_BIRTH,
  GET_COUNTRIES_BIRTH_TRANSLATE,
  GET_DISTRICTS,
  GET_GENDERS,
  GET_LOAD_TO_PDF,
  GET_LOAD_TO_PDF_HIDE,
  GET_MARITAL_STATUS,
  GET_MAX_LOAN_LIMIT,
  GET_NATIONALITIES,
  GET_NATIONALITIES_TRANSLATE,
  GET_OCCUPATIONS,
  GET_PDF_TO_SIGN_SUCCESS,
  GET_PROFESSIONS,
  GET_PROVINCES,
  GET_STATUS_ONBOARDING,
  GET_TERMS_SUCCESS,
  GET_TOWNSHIPS,
  GET_TYPE_OF_IDENTITY,
  GET_TYPE_OF_IMAGE,
  GET_TYPE_OF_PHONE,
  GET_VALIDATE_MINIMAL_AGE,
  ORIGEN_SIGN_ID,
  RESET_REQUEST_CARD_FORM,
  SET_APC_ID,
  SET_LOAD_SIGN,
  SET_REQUEST_CARD_LOADING,
  SET_REQUEST_VIDEO_ID,
  UPDATE_SIGNATURE_REQUEST,
} from '../../constrants/reducers';
import { formFields } from '../../containers/CardRequest/FormRequest.config';
import {
  APP,
  BRANCH_OFFICES,
  DIALOG_TYPES,
  GENDER_TYPE,
  IDENTITY_TYPES,
  MAX_ATTEMPTS_MESSAGE,
  MESSAGE_VALIDATION,
  ONBOARDING_STATUS,
  ORIGIN,
  PRODUCTS_TYPES,
  SELLER_ID_TYPE,
  STATUS_SIGNATURES,
  SYSTEM_TYPES,
  TYPE_PHONE,
  loadingTypes,
} from '../../utils/Constants';
import {
  CapitalizeString,
  ConsoleHelper,
  openNotificationWithIcon,
} from '../../utils/Functions';

export const headerEID = {
  headers: {
    Authorization: APP.electronicId.tokenAuth,
    'content-type': 'application/json',
  },
};

export const setLoading = (typeString, isActive = true) => {
  if (isActive) {
    return dispatch => {
      dispatch({
        type: SET_REQUEST_CARD_LOADING,
        payload: {[typeString]: true},
      });
    };
  } else {
    return dispatch => {
      dispatch({
        type: SET_REQUEST_CARD_LOADING,
        payload: {[typeString]: false},
      });
    };
  }
};

export const validateEmail = email => {
  return dispatch => {
    /* TODO  -- Functions for email validation */
    dispatch({
      type: SET_REQUEST_CARD_LOADING,
      payload: {[loadingTypes.EMAIL_FIELD]: true},
    });
    dispatch({
      type: EDIT_REQUEST_CARD_FORM,
      payload: {email},
    });
  };
};

export const setBase64 = base64 => {
  return dispatch => {
    dispatch({
      type: 'SET_BASE_64',
      payload: base64,
    });
  };
};

export const validatePhone = cellphone => {
  return dispatch => {
    dispatch({
      type: SET_REQUEST_CARD_LOADING,
      payload: {[loadingTypes.CELLPHONE_FIELD]: true},
    });
    dispatch({
      type: EDIT_REQUEST_CARD_FORM,
      payload: {cellphone},
    });
  };
};

export const getSettings = () => {
  return (dispatch, getState) => {
    /* minimal age */
    axiosInstance.get(`api/onboarding/MinimalAge`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        dispatch({
          type: GET_VALIDATE_MINIMAL_AGE,
          payload: data,
        });
      }
    });

    /* type of identity */
    axiosInstance.get(`api/catalogs/TypeOfIdentity`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        dispatch({
          type: GET_TYPE_OF_IDENTITY,
          payload: data,
        });
      }
    });

    /* type of phone */
    axiosInstance.get(`api/catalogs/TypePhone`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        dispatch({
          type: GET_TYPE_OF_PHONE,
          payload: data,
        });
      }
    });

    /* type of Images */
    axiosInstance.get(`api/catalogs/TypeOfImage`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        dispatch({
          type: GET_TYPE_OF_IMAGE,
          payload: data,
        });
      }
    });

    /* Status Form Request */
    axiosInstance.get(`api/catalogs/OnboardStatus`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        dispatch({
          type: GET_STATUS_ONBOARDING,
          payload: data,
        });
      }
    });

    /* get global configuration */
    axiosInstance
      .get(`api/onboarding/GetGlobalConfigurationTekky`)
      .then(response => {
        const {status, data} = response;
        if (status === 200) {
          /*
           * Key GLOB_TEKKY_MONTO_FINAN is used in json response for max loan amount
           */
          const searchConfigKey = data?.jsonAnswer.find(
            x => x.keyName === 'GLOB_TEKKY_MONTO_FINAN',
          );
          if (searchConfigKey) {
            const maxLoanAmount = parseFloat(searchConfigKey?.keyValue0);
            if (!isNaN(maxLoanAmount)) {
              dispatch({
                type: GET_MAX_LOAN_LIMIT,
                payload: maxLoanAmount,
              });
            }
          }
        }
      });
  };
};

export const getActivityTypes = () => {
  return async dispatch => {
    try {
      const response = await axiosInstance.get(`api/catalogs/TypeWorkActivity`);
      const {data} = response;
      const activityTypes = data.map(item => ({
        label: item.value,
        value: item.code,
      }));
      dispatch({
        type: GET_ACTIVITY_TYPES,
        payload: activityTypes.sort((a, b) => a.label.localeCompare(b.label)),
      });
    } catch (error) {
      openNotificationWithIcon(
        'error',
        'Ha ocurrido un error obteniendo datos',
      );
    }
  };
};
export const getConfigOnboard = () => {
  return dispatch => {
    axiosInstance
      .get(`api/onboarding/GetGlobalConfigurationOnboarding`)
      .then(response => {
        const {status, data} = response;
        if (status === 200) {
          const map = new Map(
            data?.jsonAnswer.map(item => [
              item.keyName,
              JSON.parse(item.keyValue0),
            ]),
          );
          const valor = map.get('GLOB_MARK_PEP');
          const valor2 = map.get('GLOB_MAX_REVIEW');
          const valor3 = map.get('GLOB_ETRIBUNAL');
          const valor4 = map.get('GLOB_SEON');
          const btnWts = map.get('GLOB_WHATSAPP');
          dispatch({
            type: 'GET_CONFIG_ONBOARD',
            payload: valor,
          });
          dispatch({
            type: 'MAX_DATE',
            payload: valor2,
          });
          dispatch({
            type: 'GLOB_ETRIBUNAL',
            payload: valor3,
          });
          dispatch({
            type: 'GLOB_SEON',
            payload: valor4,
          });
          dispatch({
            type: 'GLOB_WHATSAPP',
            payload: btnWts,
          });
        }
      });
  };
};

export const getGenders = () => {
  return dispatch => {
    axiosInstance.get(`api/catalogs/Gender`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        const payload = data.map(_m => {
          return {label: _m.value, value: _m.code};
        });
        ConsoleHelper('PAYLOAD', payload);
        dispatch({
          type: GET_GENDERS,
          payload: payload.sort((a, b) => a.label.localeCompare(b.label)),
        });
      }
    });
  };
};

export const getMaritalStatus = () => {
  return dispatch => {
    axiosInstance.get(`api/catalogs/MaritalStatus`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        const payload = data.map(_m => {
          return {label: _m.value, value: _m.code};
        });
        ConsoleHelper('PAYLOAD', payload);
        dispatch({
          type: GET_MARITAL_STATUS,
          payload: payload.sort((a, b) => a.label.localeCompare(b.label)),
        });
      }
    });
  };
};

export const getProfessions = () => {
  return dispatch => {
    axiosInstance.get(`api/catalogs/Profession`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        const payload = data.map(_m => {
          return {label: _m.value, value: _m.code};
        });
        ConsoleHelper('PAYLOAD', payload);
        dispatch({
          type: GET_PROFESSIONS,
          payload: payload.sort((a, b) => a.label.localeCompare(b.label)),
        });
      }
    });
  };
};

export const getOccupations = () => {
  return (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {valueMotorCreditoV2} = RequestCardReducer;
    axiosInstance
      .get(`api/catalogs/Occupation?IsShowMotor2=${valueMotorCreditoV2}`)
      .then(response => {
        const {status, data} = response;
        if (status === 200) {
          const payload = data.map(_m => {
            return {label: _m.value, value: _m.code};
          });
          ConsoleHelper('PAYLOAD', payload);
          dispatch({
            type: GET_OCCUPATIONS,
            payload: payload.sort((a, b) => a.label.localeCompare(b.label)),
          });
        }
      });
  };
};

export const getNationalities = () => {
  return dispatch => {
    axiosInstance.get(`api/catalogs/Nationalities`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        const payload = data.map(_m => {
          return {label: _m.value, value: _m.code};
        });
        ConsoleHelper('PAYLOAD', payload);
        dispatch({
          type: GET_NATIONALITIES,
          payload: payload.sort((a, b) => a.label.localeCompare(b.label)),
        });
      }
    });
  };
};
/**
 * Api que contiene los nacionalidades traducidas a español
 * @returns
 */
export const getNacionalizacion = () => {
  return dispatch => {
    axiosInstance
      .get(
        `api/internationalization/countries/c1ce190c-0c60-486c-9319-766ba97fb9f3`,
      )
      .then(response => {
        const {status, data} = response;
        if (status === 200) {
          const payload = data.map(_m => {
            return {label: _m.nationality, value: _m.countryId};
          });
          dispatch({
            type: GET_NATIONALITIES_TRANSLATE,
            payload: payload,
          });
        }
      });
  };
};
/**
 *
 * @returns
 */
export const countryBirthTranslate = () => {
  return dispatch => {
    axiosInstance
      .get(
        `api/internationalization/countries/c1ce190c-0c60-486c-9319-766ba97fb9f3`,
      )
      .then(response => {
        const {status, data} = response;
        if (status === 200) {
          const payload = data.map(_m => {
            return {label: _m.country, value: _m.countryId};
          });

          dispatch({
            type: GET_COUNTRIES_BIRTH_TRANSLATE,
            payload: payload,
          });
          const payloads = data.map(_m => {
            return {label: _m.country, value: _m.countryId};
          });
          dispatch({
            type: 'COMPLETE_MAP_COUNTRIES',
            payload: payloads,
          });
        }
      });
  };
};
export const getCountries = () => {
  return dispatch => {
    axiosInstance.get(`api/catalogs/Countries`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        const payload = data.map(_m => {
          return {label: _m.value, value: _m.code};
        });
        ConsoleHelper('PAYLOAD', payload);
        const contriesPma = payload.filter(
          get => get.value === '2D3D33C2-3401-40A1-858B-AE0140B0D376',
        );
        dispatch({
          type: GET_COUNTRIES,
          payload: contriesPma,
        });
      }
    });
  };
};
export const countryBirth = () => {
  return dispatch => {
    axiosInstance.get(`api/catalogs/Countries`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        const payload = data.map(_m => {
          return {label: _m.value, value: _m.code};
        });
        ConsoleHelper('PAYLOAD', payload);
        dispatch({
          type: GET_COUNTRIES_BIRTH,
          payload: payload,
        });
      }
    });
  };
};
export const getProvinces = country => {
  /* TODO -- can be filter by country */
  return (dispatch, getState) => {
    const PANAMA = '2D3D33C2-3401-40A1-858B-AE0140B0D376';

    if (country !== PANAMA) {
      return dispatch({
        type: GET_PROVINCES,
        payload: [],
      });
    }
    const {RequestCardReducer} = getState();
    const {valueMotorCreditoV2} = RequestCardReducer;
    axiosInstance
      .get(`api/catalogs/Province?IsShowMotor2=${valueMotorCreditoV2}`)
      .then(response => {
        const {status, data} = response;
        if (status === 200) {
          const payload = data.map(_m => {
            return {label: CapitalizeString(_m.value), value: _m.code};
          });
          ConsoleHelper('PAYLOAD', payload);
          return dispatch({
            type: GET_PROVINCES,
            payload: payload.sort((a, b) => a.label.localeCompare(b.label)),
          });
        }
      });
  };
};
export const getDistricts = province => {
  return dispatch => {
    axiosInstance.get(`api/catalogs/District/${province}`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        const payload = data.map(_m => {
          return {label: CapitalizeString(_m.value), value: _m.code};
        });
        ConsoleHelper('PAYLOAD', payload);
        dispatch({
          type: GET_DISTRICTS,
          payload: payload.sort((a, b) => a.label.localeCompare(b.label)),
        });
      }
    });
  };
};

export const getTownships = district => {
  return dispatch => {
    axiosInstance.get(`api/catalogs/Township/${district}`).then(response => {
      const {status, data} = response;
      if (status === 200) {
        const payload = data.map(_m => {
          return {label: CapitalizeString(_m.value), value: _m.code};
        });
        ConsoleHelper('PAYLOAD', payload);
        dispatch({
          type: GET_TOWNSHIPS,
          payload,
        });
        dispatch({
          type: 'FINISH_TOWN',
        });
        dispatch({
          type: 'CLEAN_FINISH_TOWN',
        });
      }
    });
  };
};

export const editFormRequest = formFields => {
  ConsoleHelper('FORM----->', formFields);

  if (formFields) {
    if (formFields.countryOfBirth === null) {
      delete formFields?.countryOfBirth;
    }
    if (formFields?.nationality === null) {
      delete formFields?.nationality;
    }

    return dispatch => {
      dispatch({
        type: EDIT_REQUEST_CARD_FORM,
        payload: formFields,
      });
    };
  }
};

export const resetFormRequest = () => {
  return dispatch => {
    dispatch({
      type: RESET_REQUEST_CARD_FORM,
    });
  };
};

export const setRequestVideoId = requestVideoId => {
  return async dispatch => {
    dispatch({
      requestVideoId,
      type: SET_REQUEST_VIDEO_ID,
    });
  };
};
/**
 * @description obtener pdf de servicio del pdf para pantalla individual de signature CREDI && PREAP
 * @param {*} onboarding obnoarding id del onboaring en proceso
 * @param {*} origin PREAP,PRECA,CREDI
 * @param {*} templateName codigo del template del pdf de contrato
 * @returns
 */
export const loadPDF = (onboarding, origin, templateName) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {branchOfficeId} = RequestCardReducer;
    const onboardingId = parseInt(onboarding);
    const payload = {
      onboardingId,
      templateName,
      origin,
      branchOfficeId:
        branchOfficeId.length > 1
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
    };
    dispatch({type: GET_LOAD_TO_PDF});
    try {
      const response = await axiosInstance.post(
        'api/print/getOnlyContract',
        payload,
      );
      if (response.status === 200 && response.data.error === false) {
        dispatch({
          payload: response.data.response,
          type: GET_PDF_TO_SIGN_SUCCESS,
        });
      } else if (
        response.data.toUpperCase() ===
        MESSAGE_VALIDATION.active_product_pdf.toUpperCase()
      ) {
        dispatch({
          payload: origin,
          type: 'EXIST_PRODUCT',
        });
      }
    } catch (e) {
      await logsOnboarding(
        onboarding,
        origin,
        'PDF_ID',
        e?.response?.data,
        payload,
        false,
      );
      const error = e.response.data;
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: error,
      });
    } finally {
      dispatch({type: GET_LOAD_TO_PDF_HIDE});
    }
  };
};
/**
 * @description obtener pdf de servicio del pdf para pantalla individual de signature CREDI && PREAP
 * @param {*} onboarding obnoarding id del onboaring en proceso
 * @param {*} origin PREAP,PRECA,CREDI
 * @param {*} templateName codigo del template del pdf de contrato
 * @returns
 */
export const loadTermsPDF = (onboarding, origin, templateName) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {branchOfficeId} = RequestCardReducer;
    const onboardingId = parseInt(onboarding);
    const payload = {
      onboardingId,
      templateName,
      origin,
      branchOfficeId:
        branchOfficeId.length > 1
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
    };
    dispatch({type: GET_LOAD_TO_PDF});
    try {
      const response = await axiosInstance.post(
        'api/print/getOnlyContract',
        payload,
      );
      if (response.status === 200) {
        await logsOnboarding(
          onboarding,
          origin,
          'PDF_ID',
          response?.data?.meta?.name,
          payload,
          true,
        );
        dispatch({
          payload: response.data.response,
          type: GET_TERMS_SUCCESS,
        });
      }
    } catch (e) {
      await logsOnboarding(
        onboarding,
        origin,
        'PDF_ID',
        e?.response?.data,
        payload,
        false,
      );
      const error = e.response.data;
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: error,
      });
    } finally {
      dispatch({type: GET_LOAD_TO_PDF_HIDE});
    }
  };
};
/* request = payloadOnboarding, onCompleted -> callback on success */
export const createOrUpdate = async (request, onCompleted) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      onboardingSaved,
      requestVideoId,
      onboardingId,
      origin,
      identityId,
      preBatchId,
      form,
    } = RequestCardReducer;
    /* Actualizando Onboarding */
    const newIdOnb = onboardingId;
    const payload = {
      action: 'UPD_PRE',
      OnboardingPreDTO: {
        onboardingBatchPreApprovedId: preBatchId,
        ...onboardingSaved,
        ...request,
        id: newIdOnb,
        createdBy: SELLER_ID_TYPE,
        electronicVideoId: requestVideoId,
        electronicSignId: identityId,
        lastModifiedBy: 'CMF_AZURE',
        Origin: origin,
        idExpirationDate: form.IDExpirationDate,
      },
    };
    try {
      const getPeticion = await axiosInstance.post(
        'api/onboarding/UpdateOnboardingPrePre',
        payload,
      );
      const {data} = getPeticion;
      await logsOnboarding(
        onboardingId,
        origin,
        'UPDATE_PROFESSIONAL_INFO',
        data,
        payload,
        true,
      );
      onCompleted(true);
      dispatch({
        onboardingSaved: payload.OnboardingPreDTO,
        type: FORM_REQUEST_UPDATE,
      });
    } catch (err) {
      await logsOnboarding(
        onboardingId,
        origin,
        'UPDATE_PROFESSIONAL_INFO',
        err?.response?.data,
        payload,
        false,
      );
      onCompleted(false);
      return dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message:
          err?.response?.data?.problemPublic || 'No se ha podido guardar.',
      });
    }
  };
};

export const SEONEvaluation = (onboardingId, origin, session, nacimiento) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {ipAddress, phoneForm, emailForm, form} = RequestCardReducer;
    function getcompleteName(primaryName, secondName, lastName) {
      return `${primaryName} ${secondName} ${lastName}`;
    }
    const fullName = getcompleteName(
      form?.firstName,
      form?.secondName,
      form?.lastName,
    );
    function getDomain(email) {
      const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regexCorreo.test(email)) {
        return null;
      }
      const partes = email.split('@');
      const dominio = partes[1];
      return dominio;
    }
    const domain = getDomain(emailForm);
    const payload = {
      onboardingId: onboardingId,
      Origin: origin,
      ip: ipAddress,
      action_type: '',
      affiliate_id: '',
      affiliate_name: '',
      email: emailForm,
      email_domain: domain,
      password_hash: '',
      user_fullname: fullName,
      user_firstname: form?.firstName,
      user_middlename: form?.secondName,
      user_lastname: form?.lastName,
      user_dob: form?.birthdate,
      user_pob: nacimiento, //payload
      user_photoid_number: '',
      user_id: '',
      user_name: '',
      transaction_id: '',
      user_category: '',
      user_account_status: '',
      user_created: '',
      user_country: '', //payload
      user_city: '',
      user_region: '',
      user_zip: '',
      user_street: '',
      user_street2: '',
      session: session,
      phone_number: '+507' + phoneForm,
      transaction_type: '',
      bonus_campaign_id: '',
      merchant_id: '',
      details_url: '',
    };
    try {
      await axiosInstance.post(`api/seon/GetTransactionFraudApi`, payload);
    } catch (e) {
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: e?.response?.data
          ? e?.response?.data
          : 'Ha ocurrido un error enviando solicitud',
      });
      await logsOnboarding(
        onboardingId,
        origin,
        'SEON_EVALUATION',
        e?.response?.data,
        payload,
        false,
      );
    }
  };
};
export const UpdateEndPreap = (request, onCompleted) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      onboardingSaved,
      requestVideoId,
      onboardingId,
      origin,
    } = RequestCardReducer;
    const phone = onboardingSaved.cellphone;
    const email = onboardingSaved.email;
    const isPep = request.isPep;
    const isCrs = request.isCrs;
    /* Actualizando Onboarding */
    const newIdOnb = onboardingId;
    const payload = {
      action: 'UPD_PRE',
      OnboardingPreDTO: {
        ...onboardingSaved,
        ...request,
        id: newIdOnb,
        createdBy: SELLER_ID_TYPE,
        electronicVideoId: requestVideoId,
        lastModifiedBy: 'CMF_AZURE',
        Origin: origin,
      },
    };
    try {
      await axiosInstance.post(
        'api/onboarding/UpdateOnboardingPrePre',
        payload,
      );

      const responses = await axiosInstance.post(
        `api/onboarding/OnbEvaluationCustomerRiskLevel?onboarding=${onboardingId}&origin=${origin}`,
        payload,
      );
      const levelRisk = JSON.parse(responses.data.jsonAnswer);

      dispatch({
        payload: levelRisk.ConsolidatedRisk,
        type: 'GET_RISK_LEVEL',
      });

      dispatch({
        onboardingSaved: payload.OnboardingPreDTO,
        type: FORM_REQUEST_UPDATE,
      });

      if (!isPep && !isCrs && levelRisk.ConsolidatedRisk !== 'ALTO') {
        const payloadSignatureForm = {
          email: email,
          nif: onboardingSaved.identityNumber,
          phone: phone,
          primaryName: onboardingSaved.firstName,
          secondaryName: onboardingSaved.firstSurname,
        };

        try {
          const res = await axios.get(
            `${APP.electronicId.endpoints.getIdentityId}?nif=${onboardingSaved.identityNumber}`,
            headerEID,
          );
          const {data} = res;

          dispatch({
            identityId: data?.id,
            type: ADD_IDENTITY_EID_SUCCESS,
          });

          onCompleted(true);
        } catch (err) {
          const responseIdentity = await axios.post(
            APP.electronicId.endpoints.onsite,
            payloadSignatureForm,
            headerEID,
          );

          const {data} = responseIdentity;

          await logsOnboarding(
            onboardingId,
            origin,
            'CREAR_IDENTIDAD_FIRMA',
            data,
            payloadSignatureForm,
            true,
          );

          if (responseIdentity?.data?.id) {
            onCompleted(true);
            dispatch({
              identityId: responseIdentity?.data?.id,
              type: ADD_IDENTITY_EID_SUCCESS,
            });
          }
        }
      } else {
        await changeStatusPreca({
          onboardingPreId: onboardingId,
          commentary: 'Onboarding pendiente de aprobación',
          statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
          aprobe: false,
          createdBy: SELLER_ID_TYPE,
        });

        const payloadPep = {
          action: 'UPD_PRE',
          OnboardingPreDTO: {
            OnboardingPreId: onboardingId,
            commentary: 'Pendiente por Aprobar',
            createdBy: SELLER_ID_TYPE,
            lastModifiedBy: 'CMF_AZURE',
            ...onboardingSaved,
            ...request,
            isPep: isPep === true || isCrs == true ? true : false,
            statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
          },
        };

        await axiosInstance.post(
          'api/onboarding/UpdateOnboardingPrePre',
          payloadPep,
        );

        onCompleted(true);
      }
    } catch (err) {
      onCompleted(false);
      await logsOnboarding(
        onboardingId,
        origin,
        'UPDATE_POLITICAL_INFO',
        err?.response?.data,
        payload,
        false,
      );
      openNotificationWithIcon(
        err?.response?.data?.problemPublic || 'No se ha podido guardar.',
      );
    }
  };
};

const LexisNexisPrepaid = async payload => {
  return await axiosInstance.post(
    `api/LexisNexis/CheckClientInAllLexisNexisList`,
    payload,
  );
};
/**
 * Servicio que permite cambiar el estado del onboarding prepapago
 * @param {*} paylodPep Datos del onboarding prepago - onboardingId, statusId, commentary, createdBy, clientEmail, clientName, isPep, origin, SendMail: enviar este campo en true para enviar el correo de pendiente de aprobación y enviar en false si es una actualizacion de datos
 * @returns
 */
const changeStatusPrepaid = paylodPep => {
  return axiosInstance.post(
    `api/OnboardingPrepaid/ChangeStatusPrepaid`,
    paylodPep,
  );
};

/**
 *
 * @param {*} request objeto que contiene la data de las preguntas y respuestas PEP del onboarding
 * @param {*} onCompleted funcionalidad que permite realizar la redireccion de las pantallas segun el resultado del servicio
 * @returns
 */
export const UpdateEndPrepaid = (request, onCompleted) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer, KOReducer} = getState();
    const {
      onboardingSaved,
      requestVideoId,
      onboardingId,
      origin,
      form,
    } = RequestCardReducer;
    const phone = form.cellphone;
    const email = form.email;
    const isPep = request.isPep;
    const isCrs = request.isCrs;
    const isFatca = request.isFatca;
    const createdBy = SELLER_ID_TYPE;
    const nombre = form.firstName;
    const {acpId} = KOReducer;
    /* Actualizando Onboarding */
    const newIdOnb = onboardingId;
    const payloadPrePaid = {
      ...onboardingSaved,
      ...request,
      id: newIdOnb,
      createdBy: SELLER_ID_TYPE,
      electronicVideoId: requestVideoId,
      lastModifiedBy: 'CMF_AZURE',
      Origin: origin,
    };
    const payloadLexisNexis = {
      typeIdentity: IDENTITY_TYPES.DOCUMENT_ID,
      identityNumber: onboardingSaved.identityNumber,
      firstName: onboardingSaved.firstName,
      secondName: onboardingSaved.secondName,
      firstSurName: onboardingSaved.firstSurname,
      secondSurname: onboardingSaved.secondSurname,
      origin: ORIGIN.PREPAGO,
      isPep: isPep,
      createdDate: new Date().toISOString(),
      dateOfBirth: onboardingSaved.dateOfBirth,
      genderId: onboardingSaved.genderId,
      address: [
        {
          id: 0,
          countryId: onboardingSaved.address[0].countryId,
          provinceId: onboardingSaved.address[0].provinceId,
          districtId: onboardingSaved.address[0].districtId,
          townshipId: onboardingSaved.address[0].townshipId,
          address: onboardingSaved.address[0].address,
          buildingHouse: onboardingSaved.address[0].buildingHouse,
          apartmentHouseNumber: onboardingSaved.address[0].ApartmentHouseNumber,
          longitude: 0,
          latitude: 0,
          createdDate: new Date().toISOString(),
          createdBy: SELLER_ID_TYPE,
          lastModifiedDate: new Date().toISOString(),
          lastModifiedBy: 'CMF',
        },
      ],
      requiredReview: true,
      nationalityId: onboardingSaved.nationalityId,
      countryBirthId: onboardingSaved.countryBirthId,
      onboardingId: onboardingId,
    };
    try {
      await axiosInstance.put(
        `api/OnboardingPrepaid/${newIdOnb}`,
        payloadPrePaid,
      );
      LexisNexisPrepaid(payloadLexisNexis, newIdOnb)
        .then(response => {
          const {status, data} = response;
          const isLexisNexisList = JSON.parse(response?.data?.isLexisNexisList);
          const jsonAnswer = JSON.parse(response?.data?.jsonAnswer);
          const isPepLexis = jsonAnswer.some(item => item.IsPep === true);
          const isPepPayload = isPep ? isPep : isPepLexis;
          if (status && status === 200 && data) {
            dispatch({
              type: 'LEXIS_NEXIS_RESULT_PREPAID',
              payload: isLexisNexisList,
            });
            if (isLexisNexisList || isPep || isCrs) {
              let ChangeStatusPending = {
                onboardingId: onboardingId,
                statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
                commentary: 'Pendiente',
                createdBy: createdBy,
                clientEmail: email,
                clientName: nombre,
                isPep: isPepPayload,
                origin: origin,
                SendMail: true,
                isLexisNexis: isLexisNexisList,
              };
              let payloadUpdatePrepaid = {
                ...payloadPrePaid,
                isPep: isPepPayload,
              };
              axiosInstance
                .put(`api/OnboardingPrepaid/${newIdOnb}`, payloadUpdatePrepaid)
                .then(async answ => {
                  const {data} = answ;
                  dispatch({
                    onboardingSaved: payloadPrePaid,
                    type: FORM_REQUEST_UPDATE,
                  });
                  await logsOnboarding(
                    onboardingId,
                    origin,
                    'UPDATE_PEP',
                    data,
                    payloadUpdatePrepaid,
                    true,
                  );
                  changeStatusPrepaid(ChangeStatusPending)
                    .then(async res => {
                      const responses = await axiosInstance.post(
                        `api/OnboardingPrepaid/OnbPrepaidEvaluationCustomerRiskLevel?onboarding=${onboardingId}`,
                        payloadPrePaid,
                      );
                      const levelRisk = JSON.parse(responses.data.jsonAnswer);
                      dispatch({
                        payload: levelRisk.ConsolidatedRisk,
                        type: 'GET_RISK_LEVEL',
                      });
                      onCompleted(true);
                      dispatch({
                        onboardingSaved: payloadPrePaid,
                        type: FORM_REQUEST_UPDATE,
                      });
                    })
                    .catch(async err => {
                      await logsOnboarding(
                        onboardingId,
                        origin,
                        'ERROR_CHANGE_STATUS_PREPAID',
                        err?.response?.data,
                        err?.response?.data?.problemPublic,
                        false,
                      );
                      onCompleted(false);
                      NotificationManager.error('Error cambiando estatus');
                    });
                })
                .catch(async error => {
                  await logsOnboarding(
                    onboardingId,
                    origin,
                    'UPDATE_ERROR_PEP',
                    error?.response?.data,
                    error?.response?.data?.problemPublic,
                    false,
                  );
                  onCompleted(false);
                  NotificationManager.error('Error actualizando datos');
                });
            } else if (!isLexisNexisList && !isPep && !isCrs) {
              try {
                const payloadSignatureForm = {
                  email: email,
                  nif: onboardingSaved.identityNumber,
                  phone: phone,
                  primaryName: onboardingSaved.firstName,
                  secondaryName: onboardingSaved.firstSurname,
                };
                axios
                  .get(
                    `${APP.electronicId.endpoints.getIdentityId}?nif=${onboardingSaved.identityNumber}`,
                    headerEID,
                  )
                  .then(async res => {
                    const {data} = res;
                    dispatch({
                      identityId: data?.id,
                      type: ADD_IDENTITY_EID_SUCCESS,
                    });
                    onCompleted(true);
                  })
                  .catch(async err => {
                    if (APP.isFakeSignature) {
                      dispatch({
                        identityId: APP.fakeSignature,
                        type: ADD_IDENTITY_EID_SUCCESS,
                      });
                    } else {
                      try {
                        const responseIdentity = await axios.post(
                          APP.electronicId.endpoints.onsite,
                          payloadSignatureForm,
                          headerEID,
                        );
                        await logsOnboarding(
                          onboardingId,
                          origin,
                          'CREAR_IDENTIDAD_FIRMA',
                          responseIdentity,
                          err,
                          true,
                        );
                        if (responseIdentity?.data?.id) {
                          dispatch({
                            identityId: responseIdentity?.data?.id,
                            type: ADD_IDENTITY_EID_SUCCESS,
                          });
                        }
                        onCompleted(true);
                      } catch (e) {
                        await logsOnboarding(
                          onboardingId,
                          origin,
                          'ERROR_CREAR_IDENTIDAD_FIRMA',
                          e?.response?.data,
                          e?.response?.data?.problemPublic,
                          false,
                        );
                        openNotificationWithIcon(
                          'error',
                          e?.response?.data?.message
                            ? e?.response?.data?.message
                            : 'Error en creacion de identidad',
                        );
                        onCompleted(false);
                      }
                    }
                  });
              } catch (e) {
                onCompleted(false);
                const error = e.response;
                let message = error.data?.message;
                if (message) {
                  message = error.data?.message;
                } else if (error?.data?.problemasEncontrados[0]?.error) {
                  message = error?.data?.problemasEncontrados[0]?.error;
                } else if (error?.data?.problemPublic) {
                  let parse = JSON.parse(error.data.problemPublic);
                  message = parse[0].Error;
                } else {
                  message = 'No hemos podido completar su solicitud.';
                }
                NotificationManager.error(message);
              }
              let DataToAprove = {
                ...onboardingSaved,
                ...request,
                statusId: ONBOARDING_STATUS.IN_PROCESS,
                apcId: acpId,
                isFatca: isFatca,
              };
              axiosInstance
                .put(`api/OnboardingPrepaid/${newIdOnb}`, DataToAprove)
                .then(async res => {
                  const res_ = await axiosInstance.post(
                    `api/OnboardingPrepaid/OnbPrepaidEvaluationCustomerRiskLevel?onboarding=${onboardingId}`,
                    payloadPrePaid,
                  );
                  const levelRisk = JSON.parse(res_.data.jsonAnswer);
                  if (levelRisk.ConsolidatedRisk === 'ALTO') {
                    let ChangeStatusPendingRisk = {
                      onboardingId: onboardingId,
                      statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
                      commentary: 'Pendiente',
                      createdBy: createdBy,
                      clientEmail: email,
                      clientName: nombre,
                      isPep: false,
                      origin: origin,
                    };
                    changeStatusPrepaid(ChangeStatusPendingRisk);
                  }
                  dispatch({
                    payload: levelRisk.ConsolidatedRisk,
                    type: 'GET_RISK_LEVEL',
                  });
                  onCompleted(true);
                  dispatch({
                    onboardingSaved: DataToAprove,
                    type: FORM_REQUEST_UPDATE,
                  });
                  const {data} = res;
                  await logsOnboarding(
                    onboardingId,
                    origin,
                    'UPDATE_AFTER_SIGNID',
                    data,
                    DataToAprove,
                    true,
                  );
                  dispatch({
                    type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
                    ob: data,
                  });
                  dispatch({
                    type: 'IN_PROCESS_ONBOARDING',
                    setStatus: ONBOARDING_STATUS.IN_PROCESS,
                  });
                })
                .catch(async error => {
                  await logsOnboarding(
                    onboardingId,
                    origin,
                    'ERROR_CUSTOMER_RISK_PREPAID',
                    error.response?.data,
                    DataToAprove,
                    false,
                  );
                  openNotificationWithIcon(
                    'error',
                    error?.response?.data?.problemPublic
                      ? error?.response?.data?.problemPublic
                      : 'Error en actualización de datos',
                  );
                });
            }
          }
        })
        .catch(async err => {
          onCompleted(false);
          await logsOnboarding(
            onboardingId,
            origin,
            'ERROR_LEXIS_NEXIS_PREPAID',
            err?.response?.data,
            err?.response?.data?.problemPublic,
            false,
          );
          openNotificationWithIcon(
            'error',
            err?.response?.data?.problemPublic
              ? err?.response?.data?.problemPublic
              : 'Error en actualización de datos',
          );
        });
    } catch (err) {
      onCompleted(false);
      await logsOnboarding(
        onboardingId,
        origin,
        'ERROR_UPDATE_END_PREPAID',
        err?.response?.data,
        err?.response?.data?.problemPublic,
        false,
      );
      openNotificationWithIcon(
        err?.response?.data?.problemPublic || 'No se ha podido guardar.',
      );
    }
  };
};

/* request = payloadOnboarding, onCompleted -> callback on success */
export const activateOnBoarding = onCompleted => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingSaved, onboardingId} = RequestCardReducer;

    const phone = onboardingSaved.cellphone;
    const email = onboardingSaved.email;

    const isPep = onboardingSaved.isPep;
    const isCrs = onboardingSaved.isCrs;
    if (!isPep && !isCrs) {
      try {
        /* Crear Identidad en Electronic ID */
        const payloadSignatureForm = {
          email: email,
          nif: onboardingSaved.identityNumber,
          phone: phone,
          primaryName: onboardingSaved.firstName,
          secondaryName: onboardingSaved.firstSurname,
        };
        await axios
          .get(
            `${APP.electronicId.endpoints.getIdentityId}?nif=${onboardingSaved.identityNumber}`,
            headerEID,
          )
          .then(res => {
            const {data} = res;
            dispatch({
              identityId: data?.id,
              type: ADD_IDENTITY_EID_SUCCESS,
            });
            onCompleted(true);
          })
          .catch(async err => {
            if (APP.isFakeSignature) {
              /* only for test purporsed */
              dispatch({
                identityId: APP.fakeSignature,
                type: ADD_IDENTITY_EID_SUCCESS,
              });

              /* form request info */
              onCompleted(true);
            } else {
              const responseIdentity = await axios.post(
                APP.electronicId.endpoints.onsite,
                payloadSignatureForm,
                headerEID,
              );

              if (responseIdentity?.data?.id) {
                dispatch({
                  identityId: responseIdentity?.data?.id,
                  type: ADD_IDENTITY_EID_SUCCESS,
                });
              }

              /* form request info */
              onCompleted(true);
            }
          });
      } catch (e) {
        const errorResponse = e.response;
        let message =
          errorResponse?.data?.message ||
          errorResponse?.data?.problemasEncontrados?.[0]?.error ||
          JSON.parse(errorResponse?.data?.problemPublic || '[]')[0]?.Error ||
          'No hemos podido completar su solicitud.';

        onCompleted(false);
        return dispatch({
          type: DIALOG_MESSAGE,
          dialogType: DIALOG_TYPES.ERROR,
          message: message,
        });
      }
    } else {
      const payloadPep = {
        action: 'UPD_PRE',
        OnboardingPreDTO: {
          OnboardingPreId: onboardingId,
          commentary: 'Pendiente por Aprobar',
          createdBy: SELLER_ID_TYPE,
          lastModifiedBy: 'CMF_AZURE',
          ...onboardingSaved,
          isPep: true,
          statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
        },
      };
      await axiosInstance.post(
        'api/onboarding/UpdateOnboardingPrePre',
        payloadPep,
      );
      onCompleted(true);
    }
  };
};
/**
 * Verifica que un usuario exista en la lista de preaprobado o precalificado
 * @param {String} identityNumber cedula o numero de identificacion que se verificara
 * @returns {Array}
 */
const verifyBatch = async identityNumber => {
  return await axiosInstance.get(
    APP.cmf.endpoints.getOnBoardingBatch +
      `/${IDENTITY_TYPES.DOCUMENT_ID}/${identityNumber}`,
  );
};

export const verifyBatchPre = (identityNumber, onSuccess, typeIdentity) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      branchOfficeId,
      routeCreateUser,
      cedulaState,
      form,
      ipAddress,
      onboardingPrepago,
      originSourceOnboarding,
    } = RequestCardReducer;
    const newIdOnb = parseInt(routeCreateUser.newCode);
    let format = formFields.identity.rules[1].pattern;
    let idBatch;
    let origin;
    await axiosInstance
      .get(
        APP.cmf.endpoints.getOnBoardingBatch +
          `/${IDENTITY_TYPES.DOCUMENT_ID}/${cedulaState}`,
      )
      .then(res => {
        idBatch = res?.data[0]?.onboardingBatchPreApprovedId
          ? res?.data[0]?.onboardingBatchPreApprovedId
          : null;
        origin = res?.data[0]?.origin;
        //  data[0].onboardingBatchPreApprovedId
        ConsoleHelper(res);
      })
      .catch(error => {
        ConsoleHelper(error);
      });
    let payload = {
      action: 'CRECRE_COMCED',
      OnboardingCreDTO: {
        id: newIdOnb,
        statusId: ONBOARDING_STATUS.PREAPROBADO,
        branchOfficeId:
          branchOfficeId.length > 3
            ? branchOfficeId
            : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        createdBy: SELLER_ID_TYPE,
        lastModifiedBy: 'CMF_AZURE',
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        identityNumber: cedulaState,
        IpAddress: ipAddress,
      },
      VideoConfirmDTO: {
        ...form,
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        OnboardingBatchPreApprovedId: idBatch,
        Origin: idBatch ? origin : 'CREDI',
      },
    };
    let payloadPrepaid = {
      ...form,
      id: newIdOnb,
      statusId: ONBOARDING_STATUS.PREAPROBADO,
      branchOfficeId:
        branchOfficeId.length > 3
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      createdBy: SELLER_ID_TYPE,
      lastModifiedBy: 'CMF_AZURE',
      typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
      identityNumber: cedulaState,
      IpAddress: ipAddress,
      OnboardingBatchPreApprovedId: idBatch,
      Origin: idBatch ? origin : 'PPAGO',
    };
    let StatusChangePrepaid = {
      onboardingId: newIdOnb,
      statusId: ONBOARDING_STATUS.PREAPROBADO,
      commentary: 'Onboarding preaprobado',
      createdBy: SELLER_ID_TYPE,
      clientEmail: '',
      clientName: '',
      origin: originSourceOnboarding,
      SendMail: false,
    };
    if (format.test(identityNumber)) {
      let response;
      try {
        if (originSourceOnboarding === ORIGIN.PREPAGO) {
          response = await axiosInstance.put(
            `api/OnboardingPrepaid/${newIdOnb}`,
            payloadPrepaid,
          );
          changeStatusPrepaid(StatusChangePrepaid);
        } else {
          response = await axiosInstance.post(
            `api/onboarding/UpdateOnboardingCrePre`,
            payload,
          );
        }
        if (response) {
          const {data} = response;
          const getOnboardingId = parseInt(data?.newCode);
          dispatch({
            payload: getOnboardingId,
            type: 'GET_NEW_CODE',
          });
          await verifyBatch(identityNumber)
            .then(async response => {
              const batchId = response?.data[0]?.onboardingBatchPreApprovedId;
              const Limite = response?.data[0]?.limitCredit;
              const Origin = response?.data[0]?.origin;
              await axiosInstance
                .get(
                  `api/Customers/GetCustomerDetailbyTypeIdentityandIdentification?typeIdentityId=${IDENTITY_TYPES.DOCUMENT_ID}&identification=${identityNumber}`,
                )
                .then(async respond => {
                  const {data} = respond;
                  const activeProdc = data?.jsonAnswer?.customerProducts;
                  let costumerIdOnboarding = activeProdc[0]?.customerId;
                  dispatch({
                    payload: costumerIdOnboarding,
                    type: 'CUSTOMER_ID_ONBOARDING',
                  });
                  let validProduct = activeProdc.filter(
                    activeProdc =>
                      activeProdc.status === true &&
                      activeProdc.isRenovated === false &&
                      activeProdc.renovatedBy === null &&
                      activeProdc.cycle != null &&
                      (activeProdc.productTypeId.toUpperCase() ===
                        PRODUCTS_TYPES.CREDIT_PRODUCTO.toUpperCase() ||
                        activeProdc.productId.toUpperCase() ===
                          PRODUCTS_TYPES.TEKY.toUpperCase()),
                  );
                  if (
                    validProduct.length > 0 &&
                    originSourceOnboarding !== ORIGIN.PREPAGO
                  ) {
                    dispatch({
                      TypeProductId: validProduct[0]?.productId,
                      type: 'TYPE_PRODUCT',
                    });
                    onSuccess(false);
                  } else if (
                    validProduct.length > 0 &&
                    originSourceOnboarding === ORIGIN.PREPAGO
                  ) {
                    dispatch({
                      TypeProductId: validProduct[0]?.productId,
                      type: 'TYPE_PRODUCT',
                    });
                    onSuccess(8);
                  } else {
                    if (onboardingPrepago || (batchId && onboardingPrepago)) {
                      onSuccess(2);
                      return dispatch({
                        type: ADD_IDENTITY_BATCH,
                        origin: 'PPAGO',
                      });
                    } else if (batchId) {
                      onSuccess(true);
                      return dispatch({
                        origin: Origin,
                        preBatchId: batchId,
                        lmCredit: Limite,
                        type: ADD_IDENTITY_BATCH,
                      });
                    } else if (!batchId) {
                      onSuccess(2);
                      return dispatch({
                        type: ADD_IDENTITY_BATCH,
                        origin: 'CREDI',
                      });
                    }
                  }
                })
                .catch(async error => {
                  await logsOnboarding(
                    getOnboardingId,
                    origin,
                    'UPDATE_AFTER_SIGNID',
                    error?.response?.data,
                    error?.response?.data?.problemPublic,
                    true,
                  );
                  if (onboardingPrepago || (batchId && onboardingPrepago)) {
                    onSuccess(2);
                    return dispatch({
                      type: ADD_IDENTITY_BATCH,
                      origin: 'PPAGO',
                    });
                  } else if (batchId) {
                    onSuccess(true);
                    return dispatch({
                      origin: Origin,
                      preBatchId: batchId,
                      lmCredit: Limite,
                      type: ADD_IDENTITY_BATCH,
                    });
                  } else if (!batchId) {
                    onSuccess(2);
                    return dispatch({
                      type: ADD_IDENTITY_BATCH,
                      origin: 'CREDI',
                    });
                  }
                });
            })
            .catch(err => {
              const dataError = err?.response?.data;
              const onboardingApproved =
                'El cliente tiene un onboarding de crédito aprobado';
              const onboardingPending =
                'El cliente tiene un onboarding de crédito pendiente de aprobación';
              const onboardingActive =
                '007 - El Producto ya esta activo, contacte con su administrador';
              if (originSourceOnboarding !== ORIGIN.PREPAGO) {
                if (dataError.includes(onboardingApproved)) {
                  dispatch({type: 'SHOW_INFO_TEXT_TDC', payload: true});
                  onSuccess(10);
                } else if (dataError.includes(onboardingPending)) {
                  onSuccess(11);
                  dispatch({type: 'SHOW_INFO_TEXT_TDC', payload: true});
                } else {
                  onSuccess(false);
                }
                return dispatch({
                  type: DIALOG_MESSAGE,
                  title: 'Error enviando solicitud',
                  dialogType: DIALOG_TYPES.ERROR,
                  message: err.response.data
                    ? err.response.data
                    : 'Error enviando solicitud',
                });
              } else if (originSourceOnboarding === ORIGIN.PREPAGO) {
                if (
                  dataError.includes(onboardingApproved) ||
                  dataError.includes(onboardingPending) ||
                  dataError.includes(onboardingActive)
                ) {
                  onSuccess(8);
                }
                return dispatch({
                  type: DIALOG_MESSAGE,
                  title: 'Error enviando solicitud',
                  dialogType: DIALOG_TYPES.ERROR,
                  message: err.response.data
                    ? err.response.data
                    : 'Error enviando solicitud',
                });
              } else {
                onSuccess(true);
              }
              logsOnboarding(
                newIdOnb,
                origin,
                'ERROR_VEIFY_BATCH',
                err.response,
                err.response.data,
                false,
              );
              return dispatch({
                type: ADD_IDENTITY_BATCH,
                origin: originSourceOnboarding,
              });
            });
        } else {
          const problemPublicParsed = JSON.parse(response?.data?.problemPublic);
          let seccion = response?.data?.problemasEncontrados?.error
            ? response?.data?.problemasEncontrados?.error
            : problemPublicParsed[0]?.Error;
          logsOnboarding(
            newIdOnb,
            origin,
            'ERROR_UPDATE_ONBOARDING',
            seccion,
            response?.data,
            false,
          );
          openNotificationWithIcon('error', seccion);
        }
      } catch (error) {
        onSuccess(5);
        logsOnboarding(
          newIdOnb,
          origin,
          'ERROR_SAVE_ONBOARDING',
          error?.response?.data,
          error?.response?.data?.problemPublic,
          false,
        );
      }
    } else {
      onSuccess(5);
      logsOnboarding(
        newIdOnb,
        origin,
        'ERROR_FORMAT_CEDULA',
        formFields?.identity?.rules[1],
        formFields?.identity?.rules[1]?.message,
        false,
      );
      return dispatch({
        type: DIALOG_MESSAGE,
        title: 'Error enviando solicitud',
        dialogType: DIALOG_TYPES.ERROR,
        message: formFields?.identity?.rules[1]?.message,
      });
    }
  };
};

/* create identity from verification id */
export const createIdentity = (
  verificationId,
  payload,
  {email, phone},
  onSuccess,
) => {
  return async (dispatch, getState) => {
    try {
      const {RequestCardReducer} = getState();
      const {preBatchId} = RequestCardReducer;
      //-- Obteniendo Batch ID ->
      //-- Creando OnBoarding
      const payloadOnBoarding = {
        action: 'CRE_PRE',
        OnboardingPreApprovedDTO: {
          OnboardingBatchPreApprovedId: preBatchId,
          statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
          ...payload,
        },
      };

      const onboardingResponse = await axiosInstance.post(
        APP.cmf.endpoints.CreateOnboardingPre,
        payloadOnBoarding,
      );
      const isPep = payload.isPep;

      if (onboardingResponse?.data?.complete !== true) {
        const errorMsg = onboardingResponse?.data?.problemasEncontrados[0]
          ?.error
          ? onboardingResponse?.data?.problemasEncontrados[0]?.error
          : 'No pudimos completar el onboarding';
        onSuccess();
        return dispatch({
          type: DIALOG_MESSAGE,
          title: 'Error enviando solicitud',
          dialogType: DIALOG_TYPES.ERROR,
          message: errorMsg,
        });
      }

      if (onboardingResponse?.data?.newCode) {
        //-- Onboarding creado satisfactoriamente

        const onboardingId = parseInt(onboardingResponse.data.newCode);

        /* APROBADO Y PEP ES TRUE */
        if (onboardingResponse?.data.complete === true && isPep) {
          //-- Politicamente expuesto - Pendiente por Aprobar
          dispatch({
            type: DIALOG_MESSAGE,
            title: 'En verificación',
            dialogType: DIALOG_TYPES.SUCCESS,
            message:
              'Estimado de cliente, gracias, su solicitud de crédito sera revisada para aprobación',
          });

          const payloadUpdate = {
            action: 'UPD_PRE',
            OnboardingPreApprovedDTO: {
              OnboardingPreId: onboardingId,
              status: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
              commentary: 'Pendiente por Aprobar',
              createdBy: payloadOnBoarding.OnboardingPreApprovedDTO.sellerId,
              lastModifiedBy: 'CMF_AZURE',
              ...payloadOnBoarding.OnboardingPreApprovedDTO,
            },
          };

          await axiosInstance.post(
            'api/onboarding/UpdateOnboardingPrePre',
            payloadUpdate,
          );
          onSuccess();
          return dispatch({
            onboardingId: onboardingId,
            productId: null,
            customerId: null,
            statusOnboarding: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
            onboardingSaved: payloadOnBoarding.OnboardingPreApprovedDTO,
            type: FORM_REQUEST_SUCCESS,
          });
        } else if (onboardingResponse?.data.complete === true && !isPep) {
          //-- Activación de OnBoarding
          const onboardingPreAppro = await axiosInstance.post(
            APP.cmf.endpoints.ActiveOnboardingPre,
            {
              action: 'ACT_PRE',
              CallActiveOnboardingPreDTO: {
                OnboardingPreId: onboardingId,
                systemId: SYSTEM_TYPES.CMF,
                approvedBy: SELLER_ID_TYPE,
                statusId: ONBOARDING_STATUS.ACTIVE,
              },
            },
          );

          if (onboardingPreAppro?.data?.complete !== true) {
            const errorMsgApro = onboardingPreAppro?.data
              ?.problemasEncontrados[0]?.error
              ? onboardingPreAppro?.data?.problemasEncontrados[0].error
              : 'No pudimos completar el onboarding';
            onSuccess();
            return dispatch({
              type: DIALOG_MESSAGE,
              title: 'Error enviando solicitud',
              dialogType: DIALOG_TYPES.ERROR,
              message: errorMsgApro,
            });
          }
          const onboardingPreAObject = JSON.parse(
            onboardingPreAppro?.data?.jsonAnswer,
          );

          /* create IDENTITY */
          const payloadSignatureForm = {
            email: email,
            nif: payload.identityNumber,
            phone: phone,
            primaryName: payload.firstName,
            secondaryName:
              (payload.secondName || '') + ' ' + (payload.lastName || ''),
          };

          const responseIdentity = await axios.post(
            APP.electronicId.endpoints.onsite,
            payloadSignatureForm,
            headerEID,
          );

          if (responseIdentity?.data?.id) {
            dispatch({
              identityId: responseIdentity?.data?.id,
              type: ADD_IDENTITY_EID_SUCCESS,
            });
          }

          /* form request info */
          onSuccess();
          return dispatch({
            onboardingId: onboardingId,
            productId: onboardingPreAObject.CustomerProductId,
            customerId: onboardingPreAObject.CustomerId,
            statusOnboarding: ONBOARDING_STATUS.APPROVED,
            onboardingSaved: payloadOnBoarding.OnboardingPreApprovedDTO,
            type: FORM_REQUEST_SUCCESS,
          });
        } else if (onboardingResponse?.data.complete === false) {
          //-- Fallo creación de Onboarding
          const payloadUpdate = {
            action: 'UPD_PRE',
            OnboardingPreApprovedDTO: {
              OnboardingPreId: onboardingId,
              status: ONBOARDING_STATUS.REJECTED,
              commentary: 'Rejected',
              createdBy: payloadOnBoarding.OnboardingPreApprovedDTO.sellerId,
              lastModifiedBy: 'CMF_AZURE',
              ...payloadOnBoarding.OnboardingPreApprovedDTO,
            },
          };

          await axiosInstance.post(
            'api/onboarding/UpdateOnboardingPrePre',
            payloadUpdate,
          );

          dispatch({
            type: DIALOG_MESSAGE,
            title: '',
            dialogType: DIALOG_TYPES.SUCCESS,
            message:
              'Lo sentimos, por el momento no es posible tramitar su crédito por politicas financieras de CMF.',
          });
          onSuccess();
          return dispatch({
            onboardingId: onboardingId,
            productId: null,
            customerId: null,
            statusOnboarding: ONBOARDING_STATUS.REJECTED,
            type: FORM_REQUEST_SUCCESS,
          });
        }
      } else {
        onSuccess();
        return dispatch({
          type: DIALOG_MESSAGE,
          title: 'No se hemos podido generar el Onboarding.',
          dialogType: DIALOG_TYPES.ERROR,
          message: 'No se hemos podido generar el Onboarding.',
        });
      }
    } catch (e) {
      const error = e.response;
      let message = error.data?.message;
      if (message) {
        if (message.includes('Identity already exist')) {
          message =
            'Su Cedula, Teléfono o Email ya poseen un documento firmado.';
        }
      } else if (error?.data?.problemasEncontrados[0]?.error) {
        message = error?.data?.problemasEncontrados[0]?.error;
      } else {
        message = 'No hemos podido completar su solicitud.';
      }
      onSuccess();
      return dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: message,
      });
    }
  };
};
/* lista de procesos para actualizacion, activacion de onboarding y activacion de producto */
const UpdateOnboardingCredit = async params => {
  return await axiosInstance.put(`api/onboarding`, params);
};

const ActivarMAtrixOnboarding = async payload => {
  return await axiosInstance.post(
    `api/onboarding/ActiveOnboardingMatrix`,
    payload,
  );
};
const consultCustomerProduct = async (id, origin) => {
  return await axiosInstance.get(
    `api/Customers/GetCustomerOriginbyOnboardingIdandOrigin?onboardingId=${id}&origin=${origin}`,
  );
};
/**
 * Activa el producto y tarjeta de todos los origenes
 */
const ActivarActionProduct = async payload => {
  return await axiosInstance.post(
    `api/Customers/ActiveProductCredTDC`,
    payload,
  );
};
/*Fin de lista de procesos */
export const activateProduct = (
  productId,
  videoId,
  documentId,
  signId,
  onComplete,
  onCompleted,
) => {
  return async (dispatch, getState) => {
    const {KOReducer, RequestCardReducer} = getState();
    const {
      onboardingSaved,
      onboardingId,
      productId,
      origin,
      branchOfficeId,
      statusOnboarindSucces,
      referido,
      personalData,
      form,
    } = RequestCardReducer;
    const {CurrentOnboarding, Matrix, planChubb} = KOReducer;
    const newIdOnb = onboardingId;
    var jsonAnsw;
    //Activar Onboarding
    if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PRECALIFICADO) {
      if (productId === null) {
        let payloadUpdate = {
          action: 'SIG_PRE',
          OnboardingPreDTO: {
            ...onboardingSaved,
            OnboardingPreId: newIdOnb,
            isSignatureConfirm: true,
            electronicVideoId: videoId,
            electronicDocumentId: documentId,
            electronicSignId: signId,
            createdBy: SELLER_ID_TYPE,
            lastModifiedBy: 'CMF_AZURE',
            statusId: ONBOARDING_STATUS.IN_PROCESS,
            referralCode: referido,
            IDExpirationDate: form.IDExpirationDate,
          },
        };
        await axiosInstance
          .post('api/onboarding/UpdateOnboardingPrePre', payloadUpdate)
          .then(async res => {
            const {data} = res;
            await logsOnboarding(
              onboardingId,
              origin,
              'UPDATE_BEFORE_ACTIVATION',
              data,
              payloadUpdate,
              true,
            );
            await axiosInstance
              .post(APP.cmf.endpoints.ActiveOnboardingPre, {
                action: 'ACT_PRE',
                CallActiveOnboardingPreDTO: {
                  OnboardingPreId: newIdOnb,
                  systemId: SYSTEM_TYPES.CMF,
                  approvedBy: SELLER_ID_TYPE,
                  statusId: ONBOARDING_STATUS.ACTIVE,
                  approvedDate: new Date(),
                },
              })
              .then(async res => {
                jsonAnsw = JSON.parse(res?.data?.jsonAnswer);
                const onboardingPreAObject = JSON.parse(res?.data?.jsonAnswer);
                dispatch({
                  productId: onboardingPreAObject.CustomerProductId,
                  customerId: onboardingPreAObject.CustomerId,
                  statusOnboarding: ONBOARDING_STATUS.APPROVED,
                  type: ACTIVATE_ONBOARDING,
                });
                const payload = {
                  customerProductId: jsonAnsw.CustomerProductId,
                  activeBy: SELLER_ID_TYPE,
                  branchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                };
                dispatch({type: ACTIVE_PROD_STATUS_SHOW});
                await axiosInstance
                  .post(APP.cmf.endpoints.activateProduct, payload)
                  .then(async res => {
                    if (res.data.complete) {
                      if (planChubb !== null) {
                        const payload = {
                          OnboardingId: onboardingId,
                          PlanCode: 4,
                          CoverageType: 'MO',
                          Origin: origin,
                          RecordType: 1,
                        };
                        // onComplete(true);
                        await axiosInstance
                          .put(
                            `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                            payload,
                          )
                          .then(async res => {
                            // onComplete(true);
                            dispatch({
                              type: ACTIVATE_PRODUCT,
                            });
                          })
                          .catch(err => {
                            // onComplete(true);
                            dispatch({
                              type: ACTIVATE_PRODUCT,
                            });
                            dispatch({
                              type: DIALOG_MESSAGE,
                              dialogType: DIALOG_TYPES.ERROR,
                              message: err?.response?.data?.problemPublic
                                ? err?.response?.data?.problemPublic
                                : 'Error en proceso de seguro',
                            });
                          });
                      } else if (planChubb === null) {
                        dispatch({
                          type: ACTIVATE_PRODUCT,
                        });
                      }
                    } else {
                      dispatch({
                        type: DIALOG_MESSAGE,
                        dialogType: DIALOG_TYPES.ERROR,
                        message:
                          res?.data?.problemPublic ||
                          'Ha ocurrido un error al activar producto',
                      });
                    }
                  })
                  .catch(err => {
                    dispatch({
                      type: DIALOG_MESSAGE,
                      dialogType: DIALOG_TYPES.ERROR,
                      message: err?.response?.data?.problemPublic
                        ? err?.response?.data?.problemPublic
                        : 'Error activando producto',
                    });
                  })
                  .finally(() => dispatch({type: ACTIVE_PROD_STATUS_HIDE}));
              })
              .catch(err => {
                const error = err.response;
                let errorMsg = error?.data;
                if (error?.data?.problemPublic) {
                  let parse = JSON.parse(error.data.problemPublic);
                  errorMsg = parse[0].Error;
                } else {
                  errorMsg = 'Error actualizando producto.';
                }
                return dispatch({
                  type: DIALOG_MESSAGE,
                  title: 'Error enviando solicitud',
                  dialogType: DIALOG_TYPES.ERROR,
                  message: errorMsg,
                });
              });
          })
          .catch(async err => {
            await logsOnboarding(
              onboardingId,
              origin,
              'UPDATE_BEFORE_ACTIVATION',
              err?.response?.data,
              payloadUpdate,
              false,
            );
            const error = err.response;
            let message;
            if (error?.data?.problemPublic) {
              let parse = JSON.parse(error.data.problemPublic);
              message = parse[0].Error;
            } else if (error?.data?.problemasEncontrados) {
              message = error?.data?.problemasEncontrados[0]?.error;
            } else {
              message = 'Error actualizando producto.';
            }
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message: message,
            });
          });
        ////////HOLAAAAAAAAAAA
      } else {
        const payload = {
          customerProductId: productId,
          activeBy: SELLER_ID_TYPE,
          branchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        };
        dispatch({type: ACTIVE_PROD_STATUS_SHOW});
        await axiosInstance
          .post(APP.cmf.endpoints.activateProduct, payload)
          .then(async res => {
            if (res.data.complete) {
              if (planChubb !== null) {
                const payload = {
                  OnboardingId: onboardingId,
                  PlanCode: 4,
                  CoverageType: 'MO',
                  Origin: origin,
                  RecordType: 1,
                };
                await axiosInstance
                  .put(`${APP.cmf02.endpoints.apiChubb}/${planChubb}`, payload)
                  .then(async res => {
                    // onComplete(true);
                    dispatch({
                      type: ACTIVATE_PRODUCT,
                    });
                  })
                  .catch(err => {
                    // onComplete(true);
                    dispatch({
                      type: ACTIVATE_PRODUCT,
                    });
                    dispatch({
                      type: DIALOG_MESSAGE,
                      dialogType: DIALOG_TYPES.ERROR,
                      message: err?.response?.data?.problemPublic
                        ? err?.response?.data?.problemPublic
                        : 'Error en proceso de seguro',
                    });
                  });
              } else if (planChubb === null) {
                dispatch({
                  type: ACTIVATE_PRODUCT,
                });
              }
            } else {
              dispatch({
                type: DIALOG_MESSAGE,
                dialogType: DIALOG_TYPES.ERROR,
                message:
                  res?.data?.problemPublic ||
                  'Ha ocurrido un error al activar producto',
              });
              onComplete(false);
            }
          })
          .catch(err => {
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message: err?.response?.data?.problemPublic
                ? err?.response?.data?.problemPublic
                : 'Ha ocurrido un error al activar producto',
            });
          })
          .finally(() => dispatch({type: ACTIVE_PROD_STATUS_HIDE}));
      }
    } else {
      if (signId !== null && documentId !== null) {
        if (statusOnboarindSucces.toUpperCase() !== ONBOARDING_STATUS.ACTIVE) {
          const payloadUpdate = {
            ...CurrentOnboarding,
            ...personalData,
            isSignatureConfirm: true,
            electronicDocumentId: documentId,
            electronicSignId: signId,
            createdBy: CurrentOnboarding.sellerId,
            lastModifiedBy: 'CMF_AZURE',
            ReferralCode: referido,
            IDExpirationDate: form.IDExpirationDate,
          };
          await UpdateOnboardingCredit(payloadUpdate)
            .then(async response => {
              const {data, status} = response;
              if (status && status === 200 && data) {
                await logsOnboarding(
                  onboardingId,
                  origin,
                  'UPDATE_BEFORE_ACTIVATION',
                  data,
                  payloadUpdate,
                  true,
                );
                dispatch({
                  type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
                  ob: data,
                });
              }
              let DataToSendActive = {
                onboardingId: CurrentOnboarding.id,
                customerId: CurrentOnboarding.customerId
                  ? CurrentOnboarding.customerId
                  : null,
                statusId: ONBOARDING_STATUS.ACTIVE,
                branchOfficeId:
                  branchOfficeId.length > 1
                    ? branchOfficeId
                    : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                sellerId: CurrentOnboarding.sellerId,
                systemId: CurrentOnboarding.systemId,
                approvedBy: CurrentOnboarding.sellerId,
                approvedDate: new Date(),
                onboardingMotordecisionId: Matrix.id,
              };

              await ActivarMAtrixOnboarding(DataToSendActive)
                .then(async res => {
                  const {data, status} = res;
                  if (status === 200 && data) {
                    let dat = JSON.parse(data.jsonAnswer);
                    dispatch({
                      type: 'ACTIVE_ONBOARDING_SUCCES',
                      ob: data,
                    });
                    dispatch({
                      type: 'IN_PROCESS_ONBOARDING',
                      setStatus: dat.StatusId,
                    });
                    await consultCustomerProduct(CurrentOnboarding.id, 'CREDI')
                      .then(async anws => {
                        const {data, status} = anws;
                        if (status === 200) {
                          let ActiveDataToSendProduct = {
                            customerProductId: data.customerProductId,
                            activeBy: CurrentOnboarding.sellerId,
                            branchOfficeActiveId:
                              branchOfficeId.length > 1
                                ? branchOfficeId
                                : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                            // ReferralCode: referido,
                          };
                          dispatch({type: ACTIVE_PROD_STATUS_SHOW});
                          await ActivarActionProduct(ActiveDataToSendProduct)
                            .then(async res => {
                              if (res.data.complete) {
                                if (planChubb !== null) {
                                  const payload = {
                                    OnboardingId: onboardingId,
                                    PlanCode: 4,
                                    CoverageType: 'MO',
                                    Origin: origin,

                                    RecordType: 1,
                                  };
                                  // onComplete(true);
                                  await axiosInstance
                                    .put(
                                      `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                                      payload,
                                    )
                                    .then(async res => {
                                      // onComplete(true);
                                      dispatch({
                                        type: ACTIVATE_PRODUCT,
                                      });
                                    })
                                    .catch(err => {
                                      // onComplete(true);
                                      dispatch({
                                        type: ACTIVATE_PRODUCT,
                                      });
                                      dispatch({
                                        type: DIALOG_MESSAGE,
                                        dialogType: DIALOG_TYPES.ERROR,
                                        message: err?.response?.data
                                          ?.problemPublic
                                          ? err?.response?.data?.problemPublic
                                          : 'Error en proceso de seguro',
                                      });
                                    });
                                } else if (planChubb === null) {
                                  dispatch({
                                    type: ACTIVATE_PRODUCT,
                                  });
                                }
                              } else {
                                dispatch({
                                  type: DIALOG_MESSAGE,
                                  dialogType: DIALOG_TYPES.ERROR,
                                  message:
                                    res?.data?.problemPublic ||
                                    'Ha ocurrido un error al activar producto',
                                });
                              }
                            })
                            .catch(err => {
                              dispatch({
                                type: DIALOG_MESSAGE,
                                dialogType: DIALOG_TYPES.ERROR,
                                message: err?.response?.data?.problemPublic
                                  ? err?.response?.data?.problemPublic
                                  : 'Error activando producto',
                              });
                            })
                            .finally(() =>
                              dispatch({type: ACTIVE_PROD_STATUS_HIDE}),
                            );
                        }
                      })
                      .catch(err => {
                        dispatch({
                          type: DIALOG_MESSAGE,
                          dialogType: DIALOG_TYPES.ERROR,
                          message: 'Error activando producto',
                        });
                      });
                  }
                })
                .catch(error => {
                  let mens = error?.response?.data?.problemPublic;
                  dispatch({
                    type: DIALOG_MESSAGE,
                    dialogType: DIALOG_TYPES.ERROR,
                    message: mens ? mens : 'Error activando producto.',
                  });
                });
            })
            .catch(async err => {
              let problemEnco = JSON.stringify(err?.response?.data);
              let getError = problemEnco.replace(/]|[,$^#@`~*+-/[{};<>]/g, ' ');
              await logsOnboarding(
                onboardingId,
                origin,
                'UPDATE_BEFORE_ACTIVATION',
                err?.response?.data,
                payloadUpdate,
                false,
              );
              dispatch({
                type: DIALOG_MESSAGE,
                dialogType: DIALOG_TYPES.ERROR,
                message: getError ? getError : 'Error actualizando datos',
              });
            });
        } else {
          await consultCustomerProduct(CurrentOnboarding.id, 'CREDI')
            .then(async anws => {
              const {data, status} = anws;
              if (status === 200) {
                let ActiveDataToSendProduct = {
                  customerProductId: data.customerProductId,
                  activeBy: CurrentOnboarding.sellerId,
                  branchOfficeActiveId:
                    branchOfficeId.length > 1
                      ? branchOfficeId
                      : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                  // ReferralCode: referido,
                };
                dispatch({type: ACTIVE_PROD_STATUS_SHOW});
                await ActivarActionProduct(ActiveDataToSendProduct)
                  .then(async res => {
                    if (res.data.complete) {
                      if (planChubb !== null) {
                        const payload = {
                          OnboardingId: onboardingId,
                          PlanCode: 4,
                          CoverageType: 'MO',
                          Origin: origin,

                          RecordType: 1,
                        };
                        // onComplete(true);
                        await axiosInstance
                          .put(
                            `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                            payload,
                          )
                          .then(async res => {
                            // onComplete(true);
                            dispatch({
                              type: ACTIVATE_PRODUCT,
                            });
                          })
                          .catch(err => {
                            // onComplete(true);
                            dispatch({
                              type: ACTIVATE_PRODUCT,
                            });
                            dispatch({
                              type: DIALOG_MESSAGE,
                              dialogType: DIALOG_TYPES.ERROR,
                              message: err?.response?.data?.problemPublic
                                ? err?.response?.data?.problemPublic
                                : 'Error en proceso de seguro',
                            });
                          });
                      } else if (planChubb === null) {
                        dispatch({
                          type: ACTIVATE_PRODUCT,
                        });
                      }
                    } else {
                      dispatch({
                        type: DIALOG_MESSAGE,
                        dialogType: DIALOG_TYPES.ERROR,
                        message:
                          res?.data?.problemPublic ||
                          'Ha ocurrido un error al activar producto',
                      });
                      onComplete(false);
                    }
                  })
                  .catch(err => {
                    dispatch({
                      type: DIALOG_MESSAGE,
                      dialogType: DIALOG_TYPES.ERROR,
                      message: err?.response?.data?.problemPublic
                        ? err?.response?.data?.problemPublic
                        : 'Error activando producto',
                    });
                  })
                  .finally(() => dispatch({type: ACTIVE_PROD_STATUS_HIDE}));
              }
            })
            .catch(err => {
              dispatch({
                type: DIALOG_MESSAGE,
                dialogType: DIALOG_TYPES.ERROR,
                message: 'Error activando producto',
              });
            });
        }
      } else {
        if (signId === null) {
          NotificationManager.error(
            'El registro de ElectronicSignId es nulo. Contacte con el administrador.',
          );
        } else if (documentId === null) {
          NotificationManager.error(
            'El registro de ElectronicDocumentid es nulo.Contacte con el administrador .',
          );
        } else {
          NotificationManager.error('No podemos continuar con su solicitud.');
        }
      }
    }
  };
};
/* upload document to electronic id */
export const addDocument = (
  name,
  onboardingId,
  origin,
  templateName,
  branchOfficeActiveId,
  url,
  history,
) => {
  return async (dispatch, getState) => {
    const _payload = {
      onboardingId: onboardingId,
      templateName: templateName,
      origin: origin,
      branchOfficeId: branchOfficeActiveId,
    };
    dispatch({type: GET_LOAD_TO_PDF});
    try {
      const _response = await axiosInstance.post(
        'api/print/getOnlyContract',
        _payload,
      );
      if (_response.status === 200 && _response.data.error === false) {
        dispatch({
          payload: _response.data.response,
          type: GET_PDF_TO_SIGN_SUCCESS,
        });
        const payload = {
          name: name,
          document: _response.data.response,
        };
        const response = await axios.post(
          APP.electronicId.endpoints.uploadDocument,
          payload,
          headerEID,
        );
        if (response?.data?.documentId) {
          await logsOnboarding(
            onboardingId,
            origin,
            'SIGID_LOAD_DOCUMENT_ID',
            response?.data,
            name,
            true,
          );
          dispatch({
            documentId: response.data.documentId,
            type: DOCUMENT_UPLOADED_SUCCESS,
          });
        }
      } else if (
        _response.data.toUpperCase() ===
          MESSAGE_VALIDATION.active_product_pdf.toUpperCase() &&
        url
      ) {
        dispatch({
          payload: 'cdm',
          type: 'EXIST_PRODUCT',
        });
      }
    } catch (e) {
      await logsOnboarding(
        onboardingId,
        origin,
        'SIGID_LOAD_DOCUMENT_ID',
        e?.response?.data,
        name,
        false,
      );
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: e?.response?.data
          ? e?.response?.data
          : 'Error al obtener contrato',
      });
      dispatch({
        type: DOCUMENT_UPLOADED_ERROR,
      });
    } finally {
      dispatch({type: GET_LOAD_TO_PDF_HIDE});
    }
  };
};

/**
 * @description: Para firmar un documento se debe solicitar una firma con DNI y DNI válidos.
 * Dependiendo del tipo de proceso de firma, En este caso se envia un SMS en la que el usuario recibira un codigo de 4 digitos.
 * @param {*} payload Trae todo los datos necesarios para el envio del SMS para la firma
 * @returns requestId: id de la solicitud de firma
 */
export const sendSMS = payload => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      origin,
      onboardingGet,
      OriginSign,
      onboardingId,
    } = RequestCardReducer;
    try {
      const res = await axios.post(
        APP.electronicId.endpoints.signatureSms,
        payload,
        headerEID,
      );
      if (res.data.status === STATUS_SIGNATURES.AwaitingConfirmation) {
        await logsOnboarding(
          onboardingGet.id
            ? onboardingGet.id
            : onboardingId
            ? onboardingId
            : onboardingGet.onboardingPreId
            ? onboardingGet.onboardingPreId
            : null,
          origin ? origin : OriginSign,
          'SIGID_SIGNATURE_BY_SMS',
          res?.data,
          payload,
          true,
        );
        dispatch({
          type: UPDATE_SIGNATURE_REQUEST,
          payload: res.data.requestId,
        });
      } else {
        ConsoleHelper('error', 'No se ha enviado la solicitud de firma');
      }
    } catch (e) {
      const error = e?.response?.data?.message;
      await logsOnboarding(
        onboardingGet.id
          ? onboardingGet.id
          : onboardingId
          ? onboardingId
          : onboardingGet.onboardingPreId
          ? onboardingGet.onboardingPreId
          : null,
        origin ? origin : OriginSign,
        'SIGID_SIGNATURE_BY_SMS',
        e?.response?.data,
        payload,
        false,
      );
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: error
          ? `Sistema biométrico, ${error}`
          : 'Sistema biométrico, no se pudo enviar mensaje de texto',
      });
    }
  };
};
/**
 * @description: Para firmar un documento se debe solicitar una firma con DNI y DNI válidos.
 * Dependiendo del tipo de proceso de firma, En este caso se envia una llamada en la que el usuario recibira un codigo de 4 digitos.
 * @param {*} payload Trae todo los datos necesarios para enviar la llamada para la firma
 * @returns requestId: id de la solicitud de firma
 */
export const sendSignByVoice = payload => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      origin,
      onboardingGet,
      OriginSign,
      onboardingId,
    } = RequestCardReducer;
    try {
      const res = await axios.post(
        APP.electronicId.endpoints.signatureCall,
        payload,
        headerEID,
      );
      if (res.data.status === STATUS_SIGNATURES.AwaitingConfirmation) {
        dispatch({
          type: UPDATE_SIGNATURE_REQUEST,
          payload: res.data.requestId,
        });
      } else {
        ConsoleHelper('error', 'No se ha enviado la solicitud de firma');
      }
    } catch (e) {
      const error = e?.response?.data?.message;
      await logsOnboarding(
        onboardingGet.id
          ? onboardingGet.id
          : onboardingId
          ? onboardingId
          : onboardingGet.onboardingPreId
          ? onboardingGet.onboardingPreId
          : '',
        origin ? origin : OriginSign,
        'SIGID_SIGNATURE_BY_VOICE',
        e?.response?.data,
        payload,
        false,
      );
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: error
          ? `Sistema biométrico, ${error}`
          : 'Sistema biométrico, no se pudo enviar mensaje de texto',
      });
    }
  };
};

export const signSms = payload => {
  return async dispatch => {
    try {
      await axios.post(APP.electronicId.endpoints.signSms, payload, headerEID);
    } catch (err) {
      const error = err?.response?.data?.message;
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: error
          ? `Sistema biométrico, ${error}`
          : 'Sistema biométrico, no se pudo firmar el contrato',
      });
      ConsoleHelper('err', err);
    }
  };
};

export const clearPDF = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_PDF,
    });
  };
};
export const AddLogProccess = (
  process,
  uniqueCode,
  newCode,
  problemPublic,
  stringToSave,
  isComplete,
) => {
  return async dispatch => {
    const payload = {
      complete: isComplete,
      Process: process,
      // UniqueCode: uniqueCode,
      NewCode: newCode,
      NewCodeMail: newCode,
      jsonAnswer: JSON.stringify(stringToSave),
      problemPublic: problemPublic,
      createdBy: SELLER_ID_TYPE,
    };
    try {
      await axiosInstance.post(APP.cmf.endpoints.logger, payload);
    } catch (err) {
      ConsoleHelper('err', err);
    }
  };
};
export const AddLog = (
  process,
  uniqueCode,
  newCode,
  stringToSave,
  isComplete,
) => {
  return async dispatch => {
    const payload = {
      complete: isComplete,
      Process: process,
      UniqueCode: uniqueCode,
      NewCode: newCode,
      NewCodeMail: newCode,
      jsonAnswer: JSON.stringify(stringToSave),
      createdBy: SELLER_ID_TYPE,
    };
    try {
      await axiosInstance.post(APP.cmf.endpoints.logger, payload);
    } catch (err) {
      ConsoleHelper('err', err);
    }
  };
};

export const matrixData = (numero, origin) => {
  /* obtiene datos del detalle del cliente*/
  return async dispatch => {
    const originOnb = origin.toUpperCase();
    if (originOnb === ORIGIN.CREDITO || originOnb === ORIGIN.PRECALIFICADO) {
      await axiosInstance
        .get(
          `api/onboarding/GetMotorDecisionByOnboardingIdandOrigin?onboardingId=${numero}&Origin=${origin}`,
        )
        .then(res => {
          const {data} = res;
          dispatch({
            type: DATA_MATRIZ_SIGN,
            payload: data,
          });
          dispatch({
            type: ORIGEN_SIGN_ID,
            payload: origin,
          });
        });
    } else {
      dispatch({
        type: ORIGEN_SIGN_ID,
        payload: origin,
      });
    }
  };
};

/**
 * Proceso donde se crea el el primer registro del usuario al inicar el onboarding, solo se tiene datos basicos
 * @param {*} onComplete  true or false
 * @returns
 */
export const CreateOnboardingCrePre = async (naosId, onComplete, origin) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {branchOfficeId} = RequestCardReducer;
    const ipAddress = await publicIp.v4();
    let payload = {
      action: 'CRE_PRE',
      createOnboardingCrePre: {
        branchOfficeId:
          branchOfficeId.length > 3
            ? branchOfficeId
            : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        statusId: ONBOARDING_STATUS.PREAPROBADO,
        OriginSourceOnboarding: origin,
        createdBy: SELLER_ID_TYPE,
        IpAddress: ipAddress ? ipAddress : '',
      },
    };
    try {
      if (!naosId) {
        const response = await axiosInstance.post(
          `api/onboarding/CreateOnboardingCrePre`,
          payload,
        );
        const {data} = response;
        onComplete(true);
        dispatch({
          payload: data,
          type: 'CREATE_CRE_PRE',
        });
        dispatch({
          type: 'IP_ADDRESS',
          payload: ipAddress,
        });
        dispatch({
          type: 'SET_ORIGIN_ONBOARDING',
          payload: origin,
        });
      } else {
        const response = await axiosInstance.post(
          `api/onboarding/CreateOnboardingCrePre`,
          payload,
        );
        const {data} = response;
        dispatch({
          payload: data,
          type: 'CREATE_CRE_PRE',
        });
        dispatch({
          type: 'IP_ADDRESS',
          payload: ipAddress,
        });
        dispatch({
          type: 'SET_ORIGIN_ONBOARDING',
          payload: origin,
        });
        const _resp = await axiosInstance.post(
          `api/onboarding/GetPurchaseInformationNAOS/${naosId}`,
          {},
        );
        if (_resp.data.complete) {
          onComplete(true);
          dispatch({
            payload: JSON.parse(_resp.data.jsonAnswer),
            type: 'DATA_NAOS_ONBOARDING',
          });
        } else {
          onComplete(false);
          openNotificationWithIcon('error', _resp.data.problemInternal);
        }
      }
    } catch (error) {
      let exception = error?.response?.data?.problemPublic;
      onComplete(false);
      openNotificationWithIcon(
        'error',
        exception ? exception : 'Ha ocurrido un error enviando solicitud',
      );
    }
  };
};
/**
 * Proceso donde se crea el el primer registro del usuario al inicar el onboarding de tarjeta prepago, solo se tiene datos basicos
 * @param {*} onComplete  true or false
 * @returns
 */
export const CreateOnboardingPago = async (naosId, onComplete, origin) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {branchOfficeId} = RequestCardReducer;
    const ipAddress = await publicIp.v4();
    let payload = {
      BranchOfficeId:
        branchOfficeId.length > 3
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      StatusId: ONBOARDING_STATUS.PREAPROBADO,
      Origin: origin,
      createdBy: SELLER_ID_TYPE,
      IpAddress: ipAddress ? ipAddress : '',
    };
    try {
      if (!naosId) {
        const response = await axiosInstance.post(
          `api/OnboardingPrepaid`,
          payload,
        );
        const {data} = response;
        onComplete(true);
        dispatch({
          payload: data,
          type: 'CREATE_CRE_PRE',
        });
        dispatch({
          type: 'IP_ADDRESS',
          payload: ipAddress,
        });
        dispatch({
          type: 'SET_ORIGIN_ONBOARDING',
          payload: origin,
        });
      } else {
        const response = await axiosInstance.post(
          `api/OnboardingPrepaid`,
          payload,
        );
        const {data} = response;
        dispatch({
          payload: data,
          type: 'CREATE_CRE_PRE',
        });
        dispatch({
          type: 'IP_ADDRESS',
          payload: ipAddress,
        });
        dispatch({
          type: 'SET_ORIGIN_ONBOARDING',
          payload: origin,
        });
        const _resp = await axiosInstance.post(
          `api/onboarding/GetPurchaseInformationNAOS/${naosId}`,
          {},
        );
        if (_resp.data.complete) {
          onComplete(true);
          dispatch({
            payload: JSON.parse(_resp.data.jsonAnswer),
            type: 'DATA_NAOS_ONBOARDING',
          });
        } else {
          onComplete(false);
          openNotificationWithIcon('error', _resp.data.problemInternal);
        }
      }
    } catch (error) {
      let exception = error?.response?.data?.problemPublic;
      onComplete(false);
      openNotificationWithIcon(
        'error',
        exception ? exception : 'Ha ocurrido un error enviando solicitud',
      );
    }
  };
};
/**
 * Proceso en ScandID donde se solicita al usuario su numero de identificacion, primer update del onboarding
 * @param {*} onComplete true or false
 * @returns
 */
export const UpdateOnboardingCrePre = onComplete => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {branchOfficeId, routeCreateUser, cedulaState} = RequestCardReducer;
    const newIdOnb = parseInt(routeCreateUser.newCode);

    let payload = {
      action: 'CRECRE_UPDCED',
      istekky: true,
      OnboardingCreDTO: {
        id: newIdOnb,
        statusId: ONBOARDING_STATUS.PREAPROBADO,
        branchOfficeId:
          branchOfficeId.length > 3
            ? branchOfficeId
            : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        createdBy: SELLER_ID_TYPE,
        lastModifiedBy: 'CMF_AZURE',
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        identityNumber: cedulaState,
      },
    };
    try {
      await axiosInstance.post(
        `api/onboarding/UpdateOnboardingCrePre`,
        payload,
      );
      onComplete(true);
    } catch (error) {
      onComplete(false);
      let exception = error.response.data.problemPublic;
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: exception
          ? exception
          : 'Ha ocurrido un error enviando solicitud',
      });
    }
  };
};

export const UpdateOnboardingCreCre = async (request, onComplete) => {
  return async (dispatch, getState) => {
    const {KOReducer, RequestCardReducer} = getState();
    const {branchOfficeId, onboardingId} = RequestCardReducer;
    const newIdOnb = onboardingId;
    const {CurrentOnboarding} = KOReducer;
    let payload = {
      action: 'CRECRE_UPDGEN',
      OnboardingCreDTO: {
        id: newIdOnb,
        ...CurrentOnboarding,
        ...request,
        branchOfficeId:
          branchOfficeId.length > 3
            ? branchOfficeId
            : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        statusId: ONBOARDING_STATUS.IN_PROCESS,
        lastModifiedBy: 'CMF',
      },
    };
    try {
      await axiosInstance.post(
        `api/onboarding/UpdateOnboardingCrePre`,
        payload,
      );
      onComplete(true);
    } catch (error) {
      onComplete(false);
      let exception = error.response.data.problemPublic;
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: exception
          ? exception
          : 'Ha ocurrido un error enviando solicitud',
      });
    }
  };
};
export const UpdateOnboardingCrePreap = (request, onComplete) => {
  return async (dispatch, getState) => {
    const {KOReducer, RequestCardReducer} = getState();
    const {
      branchOfficeId,
      onboardingId,
      preBatchId,
      origin,
      referido,
      requestVideoId,
      identityId,
      form,
    } = RequestCardReducer;
    const newIdOnb = onboardingId;
    const {CurrentOnboarding, acpId} = KOReducer;
    const currentOnboardingData = {
      id: newIdOnb,
      apcId: acpId,
      onboardingBatchPreApprovedId: preBatchId,
      ...CurrentOnboarding,
      ...request,
      branchOfficeId:
        branchOfficeId.length > 3
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      lastModifiedBy: 'CMF',
      Origin: origin,
      ReferralCode: referido,
      electronicSignId: identityId,
      electronicVideoId: requestVideoId,
      idExpirationDate: form.IDExpirationDate,
    };
    const currentOnboardingDataCopy = {
      onboardingBatchPreApprovedId: preBatchId,
      ...CurrentOnboarding,
      ...request,
      apcId: acpId,
      branchOfficeId:
        branchOfficeId.length > 3
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      lastModifiedBy: 'CMF',
      Origin: origin,
      ReferralCode: referido,
      electronicSignId: identityId,
      electronicVideoId: requestVideoId,
      idExpirationDate: form.IDExpirationDate,
    };
    const payload = {
      action: 'CREPRE_UPDGEN',
      OnboardingPreDTO: currentOnboardingData,
    };
    await axiosInstance
      .post(`api/onboarding/UpdateOnboardingPrePre`, payload)
      .then(async response => {
        await logsOnboarding(
          onboardingId,
          origin,
          'UPDATE_PERSONAL_INFO',
          response?.data,
          payload,
          true,
        );
        onComplete(true);
        return dispatch({
          onboardingSaved: currentOnboardingDataCopy,
          type: FORM_REQUEST_UPDATE,
        });
      })
      .catch(async error => {
        await logsOnboarding(
          onboardingId,
          origin,
          'UPDATE_PERSONAL_INFO',
          error?.response?.data,
          payload,
          false,
        );
        onComplete(false);
        return dispatch({
          type: DIALOG_MESSAGE,
          title: 'Notificacion',
          dialogType: DIALOG_TYPES.ERROR,
          message: error?.response?.data?.problemasEncontrados[0]?.error
            ? error?.response?.data?.problemasEncontrados[0]?.error
            : 'Error actualizando datos',
        });
      });
  };
};
/**
 * ( ͡° ͜ʖ ͡° )
 * @author Irving
 * @CreateDate 6/9/2022
 * @modifiedDate 9/1/2023
 * @description Permite realizar la actualización de un onboarding preaprobado en el cual se valida si existe el correo electrónico y el número de teléfono tanto en nuestros servicios web como en EID
 */
export const UpdateOnboardingEvaluationPreap = (request, onComplete) => {
  return async (dispatch, getState) => {
    const {KOReducer, RequestCardReducer} = getState();
    const {
      branchOfficeId,
      onboardingId,
      preBatchId,
      origin,
      referido,
      form,
      requestVideoId,
    } = RequestCardReducer;
    const {identity} = form;
    const newIdOnb = onboardingId;
    const {CurrentOnboarding, acpId} = KOReducer;
    try {
      const response = await getIdentityId(identity);
      if (response.data.id) {
        const currentOnboardingData = {
          id: newIdOnb,
          apcId: acpId,
          onboardingBatchPreApprovedId: preBatchId,
          ...CurrentOnboarding,
          ...request,
          branchOfficeId:
            branchOfficeId.length > 3
              ? branchOfficeId
              : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
          statusId: ONBOARDING_STATUS.IN_PROCESS,
          lastModifiedBy: 'CMF',
          Origin: origin,
          ReferralCode: referido,
          electronicSignId: response.data.id,
          electronicVideoId: requestVideoId,
        };
        const payload = {
          action: 'CREPRE_UPDGEN',
          OnboardingPreDTO: currentOnboardingData,
        };
        const {data} = response;
        dispatch({
          identityId: data?.id,
          type: ADD_IDENTITY_EID_SUCCESS,
        });
        await axiosInstance.post(
          `api/onboarding/UpdateOnboardingPrePre`,
          payload,
        );
        changeStatusPreca({
          onboardingPreId: onboardingId,
          commentary: 'Onboarding en proceso',
          statusId: ONBOARDING_STATUS.IN_PROCESS,
          aprobe: false,
          createdBy: SELLER_ID_TYPE,
        });
        onComplete(true);
        return dispatch({
          onboardingSaved: payload.OnboardingPreDTO,
          type: FORM_REQUEST_UPDATE,
        });
      }
    } catch (error) {
      if (
        error?.response?.data &&
        error?.response?.data?.errorId &&
        error?.response?.status === 400
      ) {
        try {
          const payloadIdentity = {
            email: form.email,
            nif: form.identity,
            phone: form.phone,
            primaryName: form.firstName,
            secondaryName: form.firstSurname,
          };
          const res = await axios.post(
            APP.electronicId.endpoints.onsite,
            payloadIdentity,
            headerEID,
          );
          const {data} = res;
          dispatch({
            identityId: data?.id,
            type: ADD_IDENTITY_EID_SUCCESS,
          });
          const currentOnboardingData = {
            id: newIdOnb,
            apcId: acpId,
            onboardingBatchPreApprovedId: preBatchId,
            ...CurrentOnboarding,
            ...request,
            branchOfficeId:
              branchOfficeId.length > 3
                ? branchOfficeId
                : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
            statusId: ONBOARDING_STATUS.IN_PROCESS,
            lastModifiedBy: 'CMF',
            Origin: origin,
            ReferralCode: referido,
            electronicSignId: res.data.id,
            electronicVideoId: requestVideoId,
          };
          const payload = {
            action: 'CREPRE_UPDGEN',
            OnboardingPreDTO: currentOnboardingData,
          };
          await axiosInstance.post(
            `api/onboarding/UpdateOnboardingPrePre`,
            payload,
          );
          changeStatusPreca({
            onboardingPreId: onboardingId,
            commentary: 'Onboarding en proceso',
            statusId: ONBOARDING_STATUS.IN_PROCESS,
            aprobe: false,
            createdBy: SELLER_ID_TYPE,
          });
          onComplete(true);
          return dispatch({
            onboardingSaved: payload.OnboardingPreDTO,
            type: FORM_REQUEST_UPDATE,
          });
        } catch (e) {
          onComplete(false);
          await logsOnboarding(
            onboardingId,
            origin,
            'UPDATE_ACTIVIDAD_LABORAL',
            e.response,
            [],
            false,
          );
          openNotificationWithIcon(
            'error',
            e?.response?.data?.problemasEncontrados
              ? e?.response?.data?.problemasEncontrados[0]?.error
              : e?.response?.data?.message
              ? e?.response?.data?.message
              : 'Error creando identidad',
          );
        }
      } else {
        onComplete(false);
        openNotificationWithIcon(
          'error',
          error?.response?.data?.problemasEncontrados
            ? error?.response?.data?.problemasEncontrados[0]?.error
            : 'Error actualizando datos',
        );
        await logsOnboarding(
          onboardingId,
          origin,
          'UPDATE_ACTIVIDAD_LABORAL',
          error.response,
          [],
          false,
        );
      }
    }
  };
};
/**
 * ( ͡° ͜ʖ ͡° )
 * @author Irving
 * @CreateDate 6/9/2022
 * @modifiedDate 6/9/2022
 * @description Permite consultar el apc de un usuario en base a su cedula y el tipo de identidad y retorna el apcId que se guardara en el onboarding
 */
export const getApc = (request, onCompleted) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      cedulaState,
      onboardingId,
      preBatchId,
      origin,
      referido,
      requestVideoId,
      identityId,
    } = RequestCardReducer;
    const createdBy = SELLER_ID_TYPE;
    const IdentityType = 1;
    try {
      const response = await axiosInstance.get(
        `api/apc/score?IdentityNumber=${cedulaState}&IdentityType=${IdentityType}&SearchType=1&ScoreVersion=3&CreatedBy=${createdBy}`,
      );
      const {data} = response;
      if (data) {
        const payload = {
          action: 'CREPRE_UPDGEN',
          OnboardingPreDTO: {
            id: Number(onboardingId),
            apcId: data?.id,
            onboardingBatchPreApprovedId: preBatchId,
            ...request,
            branchOfficeId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
            statusId: ONBOARDING_STATUS.IN_PROCESS,
            lastModifiedBy: 'CMF',
            Origin: origin,
            ReferralCode: referido,
            electronicSignId: identityId,
            electronicVideoId: requestVideoId,
          },
        };
        await axiosInstance.post(
          `api/onboarding/UpdateOnboardingPrePre`,
          payload,
        );
        dispatch({
          type: SET_APC_ID,
          payload: data.id,
        });
        dispatch({
          onboardingSaved: payload,
          type: FORM_REQUEST_UPDATE,
        });
        onCompleted(true);
      }
    } catch (error) {
      let seccion = error?.response?.data?.problemasEncontrados[0]?.seccion;
      if (seccion === 'PAIS_SANCIONADO') {
        let params = {
          onboardingPreId: Number(onboardingId),
          Commentary: 'PAIS_SANCIONADO',
          statusId: ONBOARDING_STATUS.REJECTED,
          aprobe: false,
          createdBy: SYSTEM_TYPES.CMF,
          typeRelationPep: 'NINGUNA',
        };
        await axiosInstance.post(
          `api/onboarding/AprobeOnboardingRelPrePEP`,
          params,
        );
        onCompleted(2);
      } else {
        dispatch({
          type: DIALOG_MESSAGE,
          title: 'Notificacion',
          dialogType: DIALOG_TYPES.ERROR,
          message: error?.response?.data?.problemasEncontrados[0]?.error
            ? error?.response?.data?.problemasEncontrados[0]?.error
            : 'Error actualizando datos',
        });
        onCompleted(false);
      }
    }
  };
};
/**
 * Ws para consultar el apc del onboarding de prepago
 * @param {*} request
 * @param {*} onCompleted funcion que permite realizar la redireccion de los onboarding
 * @returns
 */
export const getApcPrepaid = (request, onCompleted) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      cedulaState,
      onboardingId,
      preBatchId,
      origin,
      referido,
      requestVideoId,
      identityId,
      form,
      ipAddress,
    } = RequestCardReducer;
    const createdBy = SELLER_ID_TYPE;
    const IdentityType = 1;

    try {
      const payload = {
        id: Number(onboardingId),
        ...request,
        ...form,
        branchOfficeId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        statusId: ONBOARDING_STATUS.IN_PROCESS,
        lastModifiedDate: new Date(),
        Origin: origin,
        createdBy: SELLER_ID_TYPE,
        lastModifiedBy: 'CMF_AZURE',
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        identityNumber: cedulaState,
        IpAddress: ipAddress,
      };
      let StatusChangePrepaid = {
        onboardingId: Number(onboardingId),
        statusId: ONBOARDING_STATUS.IN_PROCESS,
        commentary: 'Onboarding en proceso',
        createdBy: SELLER_ID_TYPE,
        clientEmail: '',
        clientName: '',
        origin: origin,
        SendMail: false,
      };
      await axiosInstance
        .put(`api/OnboardingPrepaid/${Number(onboardingId)}`, payload)
        .then(reponse => {
          changeStatusPrepaid(StatusChangePrepaid)
            .then(async response => {
              const payloadToEvaluate = {
                OnboardingId: Number(onboardingId),
                CreatedBy: 'CMF',
              };
              await axiosInstance
                .post(
                  `api/OnboardingPrepaid/OnbPrepaidCountryRiskAssessment`,
                  payloadToEvaluate,
                )
                .then(async response => {
                  if (!response.data.complete) {
                    let seccion = response?.data?.problemPublic;
                    if (
                      seccion.includes(
                        'La nacionalidad del cliente es de un pais',
                      )
                    ) {
                      let params = {
                        OnboardingId: Number(onboardingId),
                        Commentary: 'PAIS_SANCIONADO',
                        statusId: ONBOARDING_STATUS.REJECTED,
                        aprobe: false,
                        createdBy: SYSTEM_TYPES.CMF,
                        typeRelationPep: 'NINGUNA',
                      };
                      await axiosInstance.post(
                        `api/OnboardingPrepaid/AprobeOnboardingPrepaidPEP`,
                        params,
                      );
                    }
                    onCompleted(2);
                  } else {
                    await axiosInstance
                      .get(
                        `api/apc/score?IdentityNumber=${cedulaState}&IdentityType=${IdentityType}&SearchType=1&ScoreVersion=3&CreatedBy=${createdBy}`,
                      )
                      .then(async response => {
                        const {data} = response;
                        if (data) {
                          const payload = {
                            id: Number(onboardingId),
                            apcId: data?.id,
                            onboardingBatchPreApprovedId: preBatchId,
                            ...request,
                            branchOfficeId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                            statusId: ONBOARDING_STATUS.IN_PROCESS,
                            lastModifiedBy: 'CMF',
                            Origin: origin,
                            ReferralCode: referido,
                            electronicSignId: identityId,
                            electronicVideoId: requestVideoId,
                          };
                          let StatusChangePrepaid = {
                            onboardingId: Number(onboardingId),
                            statusId: ONBOARDING_STATUS.PREAPROBADO,
                            commentary: 'Onboarding preaprobado',
                            createdBy: SELLER_ID_TYPE,
                            clientEmail: '',
                            clientName: '',
                            origin: origin,
                            SendMail: false,
                          };
                          await axiosInstance.put(
                            `api/OnboardingPrepaid/${Number(onboardingId)}`,
                            payload,
                          );
                          changeStatusPrepaid(StatusChangePrepaid);
                          dispatch({
                            type: SET_APC_ID,
                            payload: data.id,
                          });
                          dispatch({
                            onboardingSaved: payload,
                            type: FORM_REQUEST_UPDATE,
                          });
                          onCompleted(true);
                        }
                      })
                      .catch(async error => {
                        let seccion = error?.response?.data?.problemPublic;
                        await logsOnboarding(
                          Number(onboardingId),
                          origin,
                          'ERROR_APC_SCORE_PREPAID',
                          error,
                          seccion,
                          false,
                        );
                      });
                  }
                })
                .catch(async error => {
                  let seccion = error?.response?.data?.problemPublic;
                  dispatch({
                    type: DIALOG_MESSAGE,
                    title: 'Notificacion',
                    dialogType: DIALOG_TYPES.ERROR,
                    message: seccion ? seccion : 'Error actualizando datos',
                  });
                  await logsOnboarding(
                    Number(onboardingId),
                    origin,
                    'ERROR_COUNTRY_RISK_PREPAID',
                    error,
                    seccion,
                    false,
                  );
                });
            })
            .catch(async error => {
              let seccion = error?.response?.data?.problemPublic;
              dispatch({
                type: DIALOG_MESSAGE,
                title: 'Notificacion',
                dialogType: DIALOG_TYPES.ERROR,
                message: seccion ? seccion : 'Error actualizando datos',
              });
              await logsOnboarding(
                Number(onboardingId),
                origin,
                'ERROR_UPDATE_APC_PREPAID',
                error?.response?.data,
                seccion,
                false,
              );
            });
        })
        .catch(async error => {
          let seccion = error?.response?.data?.problemPublic;
          let errorSeccion = JSON.parse(seccion);
          let errorEdad = errorSeccion[0]?.Error;
          dispatch({
            type: DIALOG_MESSAGE,
            title: 'Notificacion',
            dialogType: DIALOG_TYPES.ERROR,
            message: errorEdad || seccion || 'Error actualizando datos',
          });
          await logsOnboarding(
            Number(onboardingId),
            origin,
            'ERROR_AGE_PREPAID',
            error?.response?.data,
            seccion,
            false,
          );
          onCompleted(false);
        });
    } catch (error) {
      dispatch({
        type: DIALOG_MESSAGE,
        title: 'Notificacion',
        dialogType: DIALOG_TYPES.ERROR,
        message: error?.response?.data?.problemPublic
          ? error?.response?.data?.problemPublic
          : 'Error actualizando datos',
      });
      logsOnboarding(
        onboardingId,
        origin,
        'ERROR_GET_APC_PREPAID',
        error?.response?.data,
        error?.response?.data?.problemPublic,
        false,
      );
      onCompleted(false);
    }
  };
};

const changeStatus = paylodPep => {
  return axiosInstance.post(`api/onboarding/ChangeStatus`, paylodPep);
};

export const changeStatusRisk = paylodPep => {
  return axiosInstance.post(`api/onboarding/ChangeStatus`, paylodPep);
};
/**
 *
 * @param {*} payload
 * @returns
 */
const changeStatusPreca = async payload => {
  try {
    const response = await axiosInstance.post(
      `api/onboarding/AprobeOnboardingPrePEP`,
      payload,
    );
    // La respuesta fue exitosa, devuélvela
    return response;
  } catch (error) {
    // Hubo un error en la solicitud, lanza el error
    throw error;
  }
};

export const changeStatusPrecaRisk = async payload => {
  try {
    const response = await axiosInstance.post(
      `api/onboarding/AprobeOnboardingPrePEP`,
      payload,
    );
    // La respuesta fue exitosa, devuélvela
    return response;
  } catch (error) {
    // Hubo un error en la solicitud, lanza el error
    throw error;
  }
};

/**
 * Api que evalua si una persona seria aprobada o rechazada por el motor de decisiones
 * @param {*} evolutionData Array de datos necesarios para la consulta del api
 * @param {*} onCompleted true o false
 * @returns  solo se toma si complete es true o false
 */
export const evalutionService = async (
  dataPayload,
  evolutionData,
  onCompleted,
) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      cedulaState,
      onboardingId,
      form,
      origin,
      preBatchId,
      referido,
      requestVideoId,
      identityId,
    } = RequestCardReducer;
    const createdBy = SELLER_ID_TYPE;
    const IdentityType = 1;
    const email = form.email;
    const nombre = form.firstName;

    if (origin === ORIGIN.CREDITO) {
      await axiosInstance
        .get(
          `api/apc/score?IdentityNumber=${cedulaState}&IdentityType=${IdentityType}&SearchType=1&ScoreVersion=3&CreatedBy=${createdBy}`,
        )
        .then(async response => {
          dispatch({
            type: SET_APC_ID,
            payload: response.data.id,
          });
          const payload = {
            action: 'CRECRE_UPDGEN',
            OnboardingCreDTO: {
              ...dataPayload,
              apcId: response.data.id,
              statusId: ONBOARDING_STATUS.IN_PROCESS,
              Origin: origin,
              electronicSignId: identityId,
              electronicVideoId: requestVideoId,
            },
          };
          await axiosInstance
            .post(`api/onboarding/UpdateOnboardingCrePre`, payload)
            .then(async response => {
              await logsOnboarding(
                onboardingId,
                origin,
                'UPDATE_APC_EVALUATION',
                response?.data,
                payload,
                true,
              );
              await axiosInstance
                .post(`api/onboarding/OnboardingEvaluation`, evolutionData)
                .then(async response => {
                  const status = await response?.data?.complete;
                  await logsOnboarding(
                    onboardingId,
                    origin,
                    'ONBOARING_EVALUATION',
                    response?.data,
                    evolutionData,
                    true,
                  );
                  if (status === true) {
                    onCompleted(3);
                  } else if (status === false) {
                    let ChangeStatusReject = {
                      onboardingId: onboardingId,
                      statusId: ONBOARDING_STATUS.REJECTED,
                      commentary: 'Onboarding Rechazado',
                      createdBy: createdBy,
                      clientEmail: email,
                      clientName: nombre,
                      isPep: false,
                      origin: origin,
                    };
                    changeStatus(ChangeStatusReject)
                      .then(res => {
                        onCompleted(2);
                      })
                      .catch(err => {
                        let message = err?.response?.data;
                        onCompleted(false);
                        const changeStatusMSG = type => {
                          notification[type]({
                            message: 'Notificación',
                            description: message
                              ? message
                              : 'Error cambiando estatus',
                          });
                        };
                        changeStatusMSG();
                      });
                  }
                })
                .catch(async err => {
                  onCompleted(false);
                  await logsOnboarding(
                    onboardingId,
                    origin,
                    'ONBOARING_EVALUATION',
                    err?.response?.data,
                    evolutionData,
                    false,
                  );
                  dispatch({
                    type: DIALOG_MESSAGE,
                    title: 'Notificacion',
                    dialogType: DIALOG_TYPES.ERROR,
                    message: err?.response?.problemPublic
                      ? err?.response?.problemPublic
                      : 'Error enviando solicitud',
                  });
                });
            })
            .catch(async error => {
              onCompleted(false);
              await logsOnboarding(
                onboardingId,
                origin,
                'UPDATE_APC_EVALUATION',
                error.response,
                dataPayload,
                false,
              );
              dispatch({
                type: DIALOG_MESSAGE,
                title: 'Notificacion',
                dialogType: DIALOG_TYPES.ERROR,
                message: error?.response?.data?.problemasEncontrados[0]?.error
                  ? error?.response?.data?.problemasEncontrados[0]?.error
                  : 'Error actualizando datos',
              });
            });
        })
        .catch(error => {
          onCompleted(false);
          dispatch({
            type: DIALOG_MESSAGE,
            title: 'Notificacion',
            dialogType: DIALOG_TYPES.ERROR,
            message: error?.response?.data?.problemPublic
              ? error?.response?.data?.problemPublic
              : 'Error actualizando datos',
          });
        });
    } else if (origin === ORIGIN.PRECALIFICADO) {
      await axiosInstance
        .get(
          `api/apc/score?IdentityNumber=${cedulaState}&IdentityType=${IdentityType}&SearchType=1&ScoreVersion=3&CreatedBy=${createdBy}`,
        )
        .then(response => {
          const {data} = response;
          if (data) {
            dispatch({
              type: SET_APC_ID,
              payload: data.id,
            });
            const payloadPreca = {
              action: 'CREPRE_UPDGEN',
              OnboardingPreDTO: {
                ...dataPayload,
                apcId: data.id,
                onboardingBatchPreApprovedId: preBatchId,
                statusId: ONBOARDING_STATUS.IN_PROCESS,
                Origin: origin,
                ReferralCode: referido,
                electronicSignId: identityId,
                electronicVideoId: requestVideoId,
              },
            };
            axiosInstance
              .post(`api/onboarding/UpdateOnboardingPrePre`, payloadPreca)
              .then(async response => {
                await logsOnboarding(
                  onboardingId,
                  origin,
                  'UPDATE_APC_EVALUATION',
                  data,
                  payloadPreca,
                  true,
                );
                dispatch({
                  onboardingSaved: payloadPreca.OnboardingPreDTO,
                  type: FORM_REQUEST_UPDATE,
                });
                await axiosInstance
                  .post(`api/onboarding/OnboardingEvaluation`, evolutionData)
                  .then(async response => {
                    const status = response?.data?.complete;
                    await logsOnboarding(
                      onboardingId,
                      origin,
                      'ONBOARING_EVALUATION',
                      response?.data,
                      evolutionData,
                      true,
                    );
                    if (status === true) {
                      onCompleted(3);
                    } else if (status === false) {
                      let payload = {
                        onboardingPreId: onboardingId,
                        commentary: 'Onboarding Rechazado',
                        statusId: ONBOARDING_STATUS.REJECTED,
                        aprobe: false,
                        createdBy: createdBy,
                      };
                      changeStatusPreca(payload)
                        .then(res => {
                          onCompleted(2);
                        })
                        .catch(err => {
                          let message = err?.response?.data;
                          onCompleted(false);
                          const changeStatusMSG = type => {
                            notification[type]({
                              message: 'Notificación',
                              description: message
                                ? message
                                : 'Error cambiando estatus',
                            });
                          };
                          changeStatusMSG();
                        });
                    }
                  })
                  .catch(async err => {
                    onCompleted(false);
                    await logsOnboarding(
                      onboardingId,
                      origin,
                      'ONBOARING_EVALUATION',
                      err?.response?.data,
                      evolutionData,
                      false,
                    );
                    dispatch({
                      type: DIALOG_MESSAGE,
                      title: 'Notificacion',
                      dialogType: DIALOG_TYPES.ERROR,
                      message: err?.response?.problemPublic
                        ? err?.response?.problemPublic
                        : 'Error enviando solicitud',
                    });
                  });
              })
              .catch(async error => {
                let seccion =
                  error?.response?.data?.problemasEncontrados[0]?.seccion;
                if (seccion === 'PAIS_SANCIONADO') {
                  let payload = {
                    onboardingPreId: Number(onboardingId),
                    commentary: 'Onboarding Rechazado',
                    statusId: ONBOARDING_STATUS.REJECTED,
                    aprobe: false,
                    createdBy: SYSTEM_TYPES.CMF,
                  };
                  changeStatusPreca(payload)
                    .then(res => {
                      onCompleted(2);
                    })
                    .catch(err => {
                      let message = err?.response?.data;
                      onCompleted(false);
                      const changeStatusMSG = type => {
                        notification[type]({
                          message: 'Notificación',
                          description: message
                            ? message
                            : 'Error cambiando estatus',
                        });
                      };
                      changeStatusMSG();
                    });
                } else {
                  onCompleted(false);
                  await logsOnboarding(
                    onboardingId,
                    origin,
                    'UPDATE_APC_EVALUATION',
                    error.response,
                    payloadPreca,
                    false,
                  );
                  dispatch({
                    type: DIALOG_MESSAGE,
                    title: 'Notificacion',
                    dialogType: DIALOG_TYPES.ERROR,
                    message: error?.response?.data?.problemasEncontrados[0]
                      ?.error
                      ? error?.response?.data?.problemasEncontrados[0]?.error
                      : 'Error actualizando datos',
                  });
                }
              });
          }
        })
        .catch(error => {
          onCompleted(false);
          dispatch({
            type: DIALOG_MESSAGE,
            title: 'Notificacion',
            dialogType: DIALOG_TYPES.ERROR,
            message: error?.response?.problemPublic
              ? error?.response?.problemPublic
              : 'Error actualizando datos',
          });
        });
    }
  };
};

/**
 * Recibe datos de la api para el envio de la solicitud de creacion de onboarding y el envio de la evaluacion
 * @param {*} payload
 * @param {*} onSuccess
 * @returns
 */
export const createdOnboardingCreditOrPreca = async (payload, onSuccess) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {
      origin,
      preBatchId,
      onboardingId,
      form,
      requestVideoId,
    } = RequestCardReducer;
    const {identity} = form;
    try {
      const resp = await getIdentityId(identity);
      if (resp.data.id) {
        const currentOnboarding = {
          ...payload,
          statusId: ONBOARDING_STATUS.IN_PROCESS,
          Origin: origin,
          electronicSignId: resp.data.id,
          electronicVideoId: requestVideoId,
        };
        const {data} = resp;
        dispatch({
          identityId: data?.id,
          type: ADD_IDENTITY_EID_SUCCESS,
        });
        if (origin === ORIGIN.CREDITO) {
          const payloadCredi = {
            action: 'CRECRE_UPDGEN',
            OnboardingCreDTO: currentOnboarding,
          };
          await axiosInstance.post(
            `api/onboarding/UpdateOnboardingCrePre`,
            payloadCredi,
          );
          onSuccess(true);
          return dispatch({
            type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
            payload: currentOnboarding,
          });
        } else if (origin === ORIGIN.PRECALIFICADO) {
          const currentOnboardingData = {
            onboardingBatchPreApprovedId: preBatchId,
            ...payload,
            statusId: ONBOARDING_STATUS.IN_PROCESS,
            Origin: origin,
          };
          const payloadPreca = {
            action: 'CREPRE_UPDGEN',
            OnboardingPreDTO: currentOnboardingData,
          };
          await axiosInstance.post(
            `api/onboarding/UpdateOnboardingPrePre`,
            payloadPreca,
          );
          changeStatusPreca({
            onboardingPreId: onboardingId,
            commentary: 'Onboarding en proceso',
            statusId: ONBOARDING_STATUS.IN_PROCESS,
            aprobe: false,
            createdBy: SELLER_ID_TYPE,
          });
          onSuccess(true);
        }
      }
    } catch (error) {
      if (
        error?.response?.data &&
        error?.response?.data?.errorId &&
        error?.response?.status === 400
      ) {
        try {
          const payloadIdentity = {
            email: form.email,
            nif: form.identity,
            phone: form.phone,
            primaryName: form.firstName,
            secondaryName: form.firstSurname,
          };
          const resp = await createOnsite(payloadIdentity);
          const {data} = resp;
          dispatch({
            identityId: data?.id,
            type: ADD_IDENTITY_EID_SUCCESS,
          });
          const currentOnboarding = {
            ...payload,
            statusId: ONBOARDING_STATUS.IN_PROCESS,
            Origin: origin,
            electronicSignId: resp.data.id,
            electronicVideoId: requestVideoId,
          };
          if (origin === ORIGIN.CREDITO) {
            const payloadCredi = {
              action: 'CRECRE_UPDGEN',
              OnboardingCreDTO: currentOnboarding,
            };
            await axiosInstance.post(
              `api/onboarding/UpdateOnboardingCrePre`,
              payloadCredi,
            );
            onSuccess(true);
            return dispatch({
              type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
              payload: currentOnboarding,
            });
          } else if (origin === ORIGIN.PRECALIFICADO) {
            const currentOnboardingData = {
              onboardingBatchPreApprovedId: preBatchId,
              ...payload,
              statusId: ONBOARDING_STATUS.IN_PROCESS,
              Origin: origin,
            };
            const payloadPreca = {
              action: 'CREPRE_UPDGEN',
              OnboardingPreDTO: currentOnboardingData,
            };
            await axiosInstance.post(
              `api/onboarding/UpdateOnboardingPrePre`,
              payloadPreca,
            );
            changeStatusPreca({
              onboardingPreId: onboardingId,
              commentary: 'Onboarding en proceso',
              statusId: ONBOARDING_STATUS.IN_PROCESS,
              aprobe: false,
              createdBy: SELLER_ID_TYPE,
            });
            onSuccess(true);
          }
        } catch (e) {
          onSuccess(false);
          await logsOnboarding(
            onboardingId,
            origin,
            'UPDATE_ACTIVIDAD_LABORAL',
            e.response,
            payload,
            false,
          );
          openNotificationWithIcon(
            'error',
            e?.response?.data?.problemasEncontrados
              ? e?.response?.data?.problemasEncontrados[0]?.error
              : e?.response?.data?.message
              ? e?.response?.data?.message
              : 'Error creando identidad',
          );
        }
      } else {
        onSuccess(false);
        openNotificationWithIcon(
          'error',
          error?.response?.data?.problemasEncontrados
            ? error?.response?.data?.problemasEncontrados[0]?.error
            : 'Error actualizando datos',
        );
        await logsOnboarding(
          onboardingId,
          origin,
          'UPDATE_ACTIVIDAD_LABORAL',
          error.response,
          [],
          false,
        );
      }
    }
  };
};
/**
 * obtiene y guarda el codigo de un referido si existe
 * @param {*} code codigo embediodo en el link
 * @returns
 */
export const codeReferidos = code => {
  return async dispatch => {
    dispatch({
      type: 'SET_VARIABLE_REFERIDO',
      payload: code,
    });
  };
};
/**
 *
 * @param {*} onboardingId  id del onboarding
 * @param {*} origin  origin del onboarding CREDI,PRECA O PREAP
 * @param {*} commentary Nombre del proceso
 * @param {*} respuestaJson Respuesta de api que se guardara para validar errores
 * @param {*} problem error obtenido a partir de la respuesta del servicio
 * @param {*} complete true si fue exitosa o false si no
 * @returns
 */
export const logsOnboarding = async (
  onboardingId,
  origin,
  commentary,
  respuestaJson,
  problem,
  complete,
) => {
  const payload = {
    complete: complete,
    Process: commentary,
    UniqueCode: origin,
    NewCode: onboardingId?.toString(),
    jsonAnswer: JSON.stringify(respuestaJson),
    problemPublic: JSON.stringify(problem),
    createdBy: 'CMF_ONBOARDING',
  };
  return await axiosInstance.post(APP.cmf.endpoints.logger, payload);
};
export const updatePersonal = payload => {
  return async dispatch => {
    dispatch({
      type: 'SET_PERSONAL_DATA',
      payload: payload,
    });
  };
};

export const updateImgOnboardingCurrent = (
  imageName,
  canvasSign,
  id,
  origin,
  template,
  _onComplete,
) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer, KOReducer} = getState();
    const {onboardingSaved, personalData, branchOfficeId} = RequestCardReducer;
    const {CurrentOnboarding} = KOReducer;
    dispatch({type: SET_LOAD_SIGN, payload: true});
    const base64 = `${canvasSign}`;
    const blob = await (await fetch(base64)).blob();
    const formData = new FormData();
    const _file = new File([blob], imageName);
    formData.append('SourceFile', _file);
    formData.append('FileIdentifier', imageName);
    try {
      const res = await axiosInstance.post(`api/blob/upload`, formData);
      const file_upload = res?.data?.fileUrl;
      if (file_upload) {
        dispatch({type: 'SET_DATA_IMG', payload: file_upload});

        if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PRECALIFICADO) {
          const images = [
            ...onboardingSaved.images,
            {
              typeImageId: 'A954139C-8D35-4C81-B475-54C41BB974C7',
              description: 'Firma',
              link: file_upload,
              thumbnails: null,
              createdBy: SELLER_ID_TYPE,
            },
          ];
          const payload = {
            action: 'UPD_PRE',
            OnboardingPreDTO: {
              ...onboardingSaved,
              images,
            },
          };
          await axiosInstance.post(
            'api/onboarding/UpdateOnboardingPrePre',
            payload,
          );
          dispatch({type: 'SET_DATA_IMG', payload: file_upload});
          dispatch({
            onboardingSaved: payload.OnboardingPreDTO,
            type: FORM_REQUEST_UPDATE,
          });
          const _payload = {
            onboardingId: id,
            templateName: template,
            origin: origin,
            branchOfficeId:
              branchOfficeId.length > 1
                ? branchOfficeId
                : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
          };
          try {
            const _response = await axiosInstance.post(
              'api/print/getOnlyContract',
              _payload,
            );
            if (_response.status === 200) {
              const payloadEID = {
                name: 'documentFile.pdf',
                document: _response.data.response,
              };
              const response = await axios.post(
                APP.electronicId.endpoints.uploadDocument,
                payloadEID,
                headerEID,
              );
              if (response?.data?.documentId) {
                await logsOnboarding(
                  id,
                  origin,
                  'SIGID_LOAD_DOCUMENT_ID',
                  response?.data,
                  'documentFile.pdf',
                  true,
                );
                _onComplete(true);
                dispatch({
                  documentId: response.data.documentId,
                  type: DOCUMENT_UPLOADED_SUCCESS,
                });
              }
            }
          } catch (e) {
            _onComplete(false);
            await logsOnboarding(
              id,
              origin,
              'SIGID_LOAD_DOCUMENT_ID',
              e?.response?.data,
              'documentFile.pdf',
              false,
            );
            dispatch({
              type: DOCUMENT_UPLOADED_ERROR,
            });
          }
        } else {
          const images = [
            ...(CurrentOnboarding?.images || []),
            {
              typeImageId: 'A954139C-8D35-4C81-B475-54C41BB974C7',
              description: 'Firma',
              link: file_upload,
              thumbnails: null,
              createdBy: CurrentOnboarding?.sellerId,
            },
          ];
          const payload = {
            ...CurrentOnboarding,
            ...personalData,
            images,
          };

          const response = await UpdateOnboardingCredit(payload);
          const {data} = response;
          dispatch({
            type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
            ob: payload,
          });
          const approvedDates = data?.lastModifiedDate;
          dispatch({
            payload: approvedDates,
            type: 'DATA_CREATED',
          });

          const _payload = {
            onboardingId: id,
            templateName: template,
            origin: origin,
            branchOfficeId:
              branchOfficeId.length > 1
                ? branchOfficeId
                : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
          };
          try {
            const _response = await axiosInstance.post(
              'api/print/getOnlyContract',
              _payload,
            );
            if (_response.status === 200) {
              const payloadEID = {
                name: 'documentFile.pdf',
                document: _response.data.response,
              };
              const response = await axios.post(
                APP.electronicId.endpoints.uploadDocument,
                payloadEID,
                headerEID,
              );
              if (response?.data?.documentId) {
                _onComplete(true);
                dispatch({
                  documentId: response.data.documentId,
                  type: DOCUMENT_UPLOADED_SUCCESS,
                });
              }
            }
          } catch (e) {
            _onComplete(false);
            await logsOnboarding(
              id,
              origin,
              'SIGID_LOAD_DOCUMENT_ID',
              e?.response?.data,
              'documentFile.pdf',
              false,
            );
            dispatch({
              type: DOCUMENT_UPLOADED_ERROR,
            });
          }
        }
        return file_upload;
      } else {
        openNotificationWithIcon(
          'error',
          'Se ha producido un error al registrar la firma.',
        );
        logsOnboarding(id, origin, 'UPLOAD_IMG_SIGN', res, false);
        _onComplete(false);
      }
    } catch (error) {
      _onComplete(false);
      logsOnboarding(id, origin, 'SEND_DATA_WITH_IMG', error, false);
      openNotificationWithIcon('error', 'Error en firma');
    } finally {
      dispatch({type: SET_LOAD_SIGN, payload: false});
    }
  };
};
/**
 * @description
 *  ESTA SECCIÓN CONTENDRÁ TODOS LOS ENDPOINTS QUE SE CONSUMEN CUANDO UN USUARIO ES PEP Y ES APROBADO DESDE EL PORTAL ADMINISTRATIVO
 *  LA PANTALLA QUE SE LE MOSTRARÁ AL USUARIO ES LA DE (ReadAndSignContrato) QUE LE MOSTRARA EL PDF DE CONTRATO, OB Y TÉRMINOS Y CONDICIONES
 * @author Irving_Vasquez
 * @date 6/22/2020
 */
export const activateProductSign = (
  productId,
  requestVideoId,
  documentId,
  onboardingNumber,
  originOnboarding,
  signDocument,
  onComplete,
) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer, KOReducer} = getState();
    const {onboardingGet, statusOnboarindSucces} = RequestCardReducer;
    const {planChubb} = KOReducer;
    if (
      originOnboarding === ORIGIN.PREAPROBADO ||
      originOnboarding === ORIGIN.PRECALIFICADO
    ) {
      let jsonAnsw;
      const payloadUpdate = {
        action: 'SIG_PRE',
        OnboardingPreDTO: {
          ...onboardingGet,
          OnboardingPreId: onboardingNumber,
          id: onboardingNumber,
          isSignatureConfirm: true,
          electronicVideoId: onboardingGet.electronicVideoId,
          electronicDocumentId: documentId,
          electronicSignId: signDocument,
          createdBy: onboardingGet.sellerId,
          lastModifiedBy: 'CMF_AZURE',
          statusId: ONBOARDING_STATUS.IN_PROCESS,
        },
      };
      await axiosInstance
        .post('api/onboarding/UpdateOnboardingPrePre', payloadUpdate)
        .then(res => {
          axiosInstance
            .post(APP.cmf.endpoints.ActiveOnboardingPre, {
              action: 'ACT_PRE',
              CallActiveOnboardingPreDTO: {
                OnboardingPreId: onboardingNumber,
                systemId: SYSTEM_TYPES.CMF,
                approvedBy: SELLER_ID_TYPE,
                statusId: ONBOARDING_STATUS.ACTIVE,
                approvedDate: new Date(),
              },
            })
            .then(async res => {
              jsonAnsw = JSON.parse(res?.data?.jsonAnswer);
              const onboardingPreAObject = JSON.parse(res?.data?.jsonAnswer);
              dispatch({
                productId: onboardingPreAObject.CustomerProductId,
                customerId: onboardingPreAObject.CustomerId,
                statusOnboarding: ONBOARDING_STATUS.APPROVED,
                type: ACTIVATE_ONBOARDING,
              });
              const payload = {
                customerProductId: jsonAnsw.CustomerProductId,
                activeBy: SELLER_ID_TYPE,
                branchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
              };

              dispatch({type: 'ACTIVE_PROD_STATUS_SHOW'});
              await axiosInstance
                .post(APP.cmf.endpoints.activateProduct, payload)
                .then(res => {
                  if (onComplete) {
                    onComplete();
                  }
                  if (planChubb !== null) {
                    const payload = {
                      OnboardingId: onboardingNumber,
                      PlanCode: 4,
                      CoverageType: 'MO',
                      Origin: originOnboarding,

                      RecordType: 1,
                    };
                    // onComplete(true);
                    axiosInstance
                      .put(
                        `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                        payload,
                      )
                      .then(async res => {
                        // onComplete(true);
                        dispatch({
                          type: ACTIVATE_PRODUCT,
                        });
                      })
                      .catch(err => {
                        // onComplete(true);
                        dispatch({
                          type: ACTIVATE_PRODUCT,
                        });
                        dispatch({
                          type: DIALOG_MESSAGE,
                          dialogType: DIALOG_TYPES.ERROR,
                          message: err?.response?.data?.problemPublic
                            ? err?.response?.data?.problemPublic
                            : 'Error en proceso de seguro',
                        });
                      });
                  } else if (planChubb === null) {
                    dispatch({
                      type: ACTIVATE_PRODUCT,
                    });
                  }
                })
                .catch(err => {
                  dispatch({
                    type: DIALOG_MESSAGE,
                    dialogType: DIALOG_TYPES.ERROR,
                    message: err?.response?.data?.problemPublic
                      ? err?.response?.data?.problemPublic
                      : 'Error activando producto',
                  });
                })
                .finally(() => dispatch({type: 'ACTIVE_PROD_STATUS_HIDE'}));
            })
            .catch(err => {
              const error = err.response;
              let errorMsg = error?.data;
              if (error?.data?.problemPublic) {
                let parse = JSON.parse(error.data.problemPublic);
                errorMsg = parse[0].Error;
              } else {
                errorMsg = 'Error actualizando producto.';
              }
              return dispatch({
                type: DIALOG_MESSAGE,
                title: 'Error enviando solicitud',
                dialogType: DIALOG_TYPES.ERROR,
                message: errorMsg,
              });
            });
        })
        .catch(err => {
          const error = err.response;
          let message = error.data?.message;
          if (error?.data?.problemPublic) {
            let parse = JSON.parse(error.data.problemPublic);
            message = parse[0].Error;
          } else if (error?.data?.problemasEncontrados) {
            message = error?.data?.problemasEncontrados[0]?.error;
          } else {
            message = 'Error actualizando producto.';
          }
          dispatch({
            type: DIALOG_MESSAGE,
            dialogType: DIALOG_TYPES.ERROR,
            message: message,
          });
        });
    } else {
      // se tienen que hacer las mismas validaciones que se hace en el onboading normal
      if (statusOnboarindSucces.toUpperCase() !== ONBOARDING_STATUS.ACTIVE) {
        const payloadUpdate = {
          ...onboardingGet,
          isSignatureConfirm: true,
          electronicDocumentId: documentId,
          electronicSignId: signDocument,
          createdBy: onboardingGet.sellerId,
          lastModifiedBy: 'CMF_AZURE',
        };
        await UpdateOnboardingCredit(payloadUpdate)
          .then(async response => {
            const {data, status} = response;
            if (status && status === 200 && data) {
              dispatch({
                type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
                ob: data,
              });
              await axiosInstance
                .get(
                  `api/onboarding/GetMotorDecisionByOnboardingId?onboardingId=${onboardingNumber}`,
                )
                .then(async res => {
                  let DataToSendActive = {
                    onboardingId: onboardingNumber,
                    customerId: onboardingGet.customerId
                      ? onboardingGet.customerId
                      : null,
                    statusId: ONBOARDING_STATUS.ACTIVE,
                    branchOfficeId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                    sellerId: onboardingGet.sellerId,
                    systemId: onboardingGet.systemId,
                    approvedBy: onboardingGet.sellerId,
                    approvedDate: new Date(),
                    onboardingMotordecisionId: res.data[0].id,
                  };
                  await ActivarMAtrixOnboarding(DataToSendActive)
                    .then(async res => {
                      const {data} = res;
                      let dat = JSON.parse(data.jsonAnswer);
                      dispatch({
                        type: 'ACTIVE_ONBOARDING_SUCCES',
                        ob: data,
                      });
                      dispatch({
                        type: 'IN_PROCESS_ONBOARDING',
                        setStatus: dat.StatusId,
                      });
                      await consultCustomerProduct(onboardingNumber, 'CREDI')
                        .then(async res => {
                          const {data, status} = res;
                          if (status === 200) {
                            let ActiveDataToSendProduct = {
                              customerProductId: data.customerProductId,
                              activeBy: onboardingGet.sellerId,
                              branchOfficeActiveId:
                                BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                            };
                            dispatch({type: ACTIVE_PROD_STATUS_SHOW});
                            ActivarActionProduct(ActiveDataToSendProduct)
                              .then(res => {
                                if (planChubb !== null) {
                                  const payload = {
                                    OnboardingId: onboardingNumber,
                                    PlanCode: 4,
                                    CoverageType: 'MO',
                                    Origin: originOnboarding,

                                    RecordType: 1,
                                  };
                                  // onComplete(true);
                                  axiosInstance
                                    .put(
                                      `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                                      payload,
                                    )
                                    .then(async res => {
                                      // onComplete(true);
                                      dispatch({
                                        type: ACTIVATE_PRODUCT,
                                      });
                                    })
                                    .catch(err => {
                                      // onComplete(true);
                                      dispatch({
                                        type: ACTIVATE_PRODUCT,
                                      });
                                      dispatch({
                                        type: DIALOG_MESSAGE,
                                        dialogType: DIALOG_TYPES.ERROR,
                                        message: err?.response?.data
                                          ?.problemPublic
                                          ? err?.response?.data?.problemPublic
                                          : 'Error en proceso de seguro',
                                      });
                                    });
                                } else if (planChubb === null) {
                                  dispatch({
                                    type: ACTIVATE_PRODUCT,
                                  });
                                }
                              })
                              .catch(err => {
                                dispatch({
                                  type: DIALOG_MESSAGE,
                                  dialogType: DIALOG_TYPES.ERROR,
                                  message: err?.response?.data?.problemPublic
                                    ? err?.response?.data?.problemPublic
                                    : 'Error activando producto',
                                });
                              })
                              .finally(() =>
                                dispatch({type: ACTIVE_PROD_STATUS_HIDE}),
                              );
                          }
                        })
                        .catch(err => {
                          dispatch({
                            type: DIALOG_MESSAGE,
                            dialogType: DIALOG_TYPES.ERROR,
                            message: 'Error activando producto',
                          });
                        });
                    })
                    .catch(error => {
                      let mens = error?.response?.data?.Message;
                      dispatch({
                        type: DIALOG_MESSAGE,
                        dialogType: DIALOG_TYPES.ERROR,
                        message: mens ? mens : 'Error activando producto.',
                      });
                    });
                })
                .catch(err => {
                  ConsoleHelper('!!!', err);
                });
            }
          })
          .catch(() => {
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message: 'No se ha podido guardar.',
            });
          });
      } else {
        await consultCustomerProduct(onboardingNumber, 'CREDI')
          .then(async res => {
            const {data, status} = res;
            if (status === 200) {
              let ActiveDataToSendProduct = {
                customerProductId: data.customerProductId,
                activeBy: onboardingGet.sellerId,
                branchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
              };
              dispatch({type: ACTIVE_PROD_STATUS_SHOW});
              ActivarActionProduct(ActiveDataToSendProduct)
                .then(res => {
                  if (planChubb !== null) {
                    const payload = {
                      OnboardingId: onboardingNumber,
                      PlanCode: 4,
                      CoverageType: 'MO',
                      Origin: originOnboarding,

                      RecordType: 1,
                    };
                    // onComplete(true);
                    axiosInstance
                      .put(
                        `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                        payload,
                      )
                      .then(async res => {
                        // onComplete(true);
                        dispatch({
                          type: ACTIVATE_PRODUCT,
                        });
                      })
                      .catch(err => {
                        // onComplete(true);
                        dispatch({
                          type: ACTIVATE_PRODUCT,
                        });
                        dispatch({
                          type: DIALOG_MESSAGE,
                          dialogType: DIALOG_TYPES.ERROR,
                          message: err?.response?.data?.problemPublic
                            ? err?.response?.data?.problemPublic
                            : 'Error en proceso de seguro',
                        });
                      });
                  } else if (planChubb === null) {
                    dispatch({
                      type: ACTIVATE_PRODUCT,
                    });
                  }
                })
                .catch(err => {
                  dispatch({
                    type: DIALOG_MESSAGE,
                    dialogType: DIALOG_TYPES.ERROR,
                    message: err?.response?.data?.problemPublic
                      ? err?.response?.data?.problemPublic
                      : 'Error activando producto',
                  });
                })
                .finally(() => dispatch({type: ACTIVE_PROD_STATUS_HIDE}));
            }
          })
          .catch(err => {
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message: 'Error activando producto',
            });
          });
      }
    }
  };
};

/**
 * Servicio que consultara las horas en que el onboarding se encontrara en mantenimiento
 * @returns
 */
export const getBannerActive = token => {
  return async dispatch => {
    try {
      const response = await axios.get(`${Cmf_Base}api/banner`, {
        headers: {
          'x-ping': token,
        },
      });
      const {data} = await response;
      dispatch({
        payload: data,
        type: GET_ACTIVE_BANNER,
      });
    } catch (error) {
      let exception = error.response.data.problemPublic;
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: exception
          ? exception
          : 'Ha ocurrido un error enviando solicitud',
      });
    }
  };
};

export const getClearBannerActive = () => {
  return async dispatch => {
    try {
      dispatch({
        payload: [],
        type: GET_CLEAN_BANNER,
      });
    } catch (error) {
      let exception = error.response.data.problemPublic;
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: exception
          ? exception
          : 'Ha ocurrido un error enviando solicitud',
      });
    }
  };
};

export const getUpdateTE = (identity, onSuccess) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {KOReducer} = getState();
    const {
      branchOfficeId,
      preBatchId,
      onboardingId,
      form,
      origin,
      referido,
      requestVideoId,
      identityId,
      emailForm,
      phoneForm,
    } = RequestCardReducer;
    const {acpId} = KOReducer;
    try {
      if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PRECALIFICADO) {
        let phones = [];
        if (phoneForm) {
          phones.push({
            id: 1,
            typePhone: 'CE',
            area: '507',
            phone: phoneForm,
            isDefault: true,
            createdBy: SELLER_ID_TYPE,
            extension: null,
            lastModifiedBy: null,
          });
        }
        if (form.phone) {
          phones.push({
            typePhone: 'CA',
            area: '507',
            phone: form.phone,
            isDefault: false,
            createdBy: SELLER_ID_TYPE,
          });
        }
        const payload = {
          action: 'CREPRE_UPDGEN',
          OnboardingPreDTO: {
            id: Number(onboardingId),
            apcId: acpId,
            onboardingBatchPreApprovedId: preBatchId,
            statusId: ONBOARDING_STATUS.REJECTED,
            branchOfficeId:
              branchOfficeId.length > 3
                ? branchOfficeId
                : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
            createdBy: SELLER_ID_TYPE,
            lastModifiedBy: 'CMF_AZURE',
            typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
            identityNumber: form.identity,
            firstName: form.firstName,
            secondName: form.secondName || null,
            firstSurname: form.lastName,
            secondSurname: form.secondLastName,
            dateOfBirth: form.birthdate,
            genderId: form.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
            countryId: form.country,
            countryBirthId: form.countryOfBirth,
            nationalityId: form.nationality,
            images: [
              {
                typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
                description: 'Cedula Frontal',
                link: form.frontIdentityImage,
                thumbnails: null,
                createdBy: 'CMF',
              },
              {
                typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
                description: 'Cedula Reverso',
                link: form.backIdentityImage,
                thumbnails: null,
                createdBy: 'CMF',
              },
              {
                typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
                description: 'Biometrico',
                link: form.faceImage,
                thumbnails: null,
                createdBy: 'CMF',
              },
            ],
            phones: phones,
            emails: [
              {
                typeEmail: 'PE',
                email: emailForm,
                isDefault: true,
                createdBy: SELLER_ID_TYPE,
              },
            ],
            Origin: origin,
            ReferralCode: referido,
            electronicSignId: identityId,
            electronicVideoId: requestVideoId,
          },
          // VideoConfirmDTO: {
          //   ...form,
          //   typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
          //   OnboardingBatchPreApprovedId: preBatchId,
          //   Origin: origin,
          // },
        };
        await axiosInstance.post(
          `api/onboarding/UpdateOnboardingPrePre`,
          payload,
        );
        dispatch({
          type: SET_APC_ID,
          payload: acpId,
        });
        dispatch({
          onboardingSaved: payload,
          type: FORM_REQUEST_UPDATE,
        });
      } else if (origin === ORIGIN.PREPAGO) {
        //NEW ONBOARDING PREPAGO
        let phones = [];
        if (phoneForm) {
          phones.push({
            id: 1,
            typePhone: 'CE',
            area: '507',
            phone: phoneForm,
            isDefault: true,
            createdBy: SELLER_ID_TYPE,
            extension: null,
            lastModifiedBy: null,
          });
        }
        if (form.phone) {
          phones.push({
            typePhone: 'CA',
            area: '507',
            phone: form.phone,
            isDefault: false,
            createdBy: SELLER_ID_TYPE,
          });
        }
        const payload = {
          id: Number(onboardingId),
          apcId: acpId,
          onboardingBatchPreApprovedId: preBatchId,
          statusId: ONBOARDING_STATUS.REJECTED,
          branchOfficeId:
            branchOfficeId.length > 3
              ? branchOfficeId
              : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
          createdBy: SELLER_ID_TYPE,
          lastModifiedBy: 'CMF_AZURE',
          typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
          identityNumber: form.identity,
          firstName: form.firstName,
          secondName: form.secondName || null,
          firstSurname: form.lastName,
          secondSurname: form.secondLastName,
          dateOfBirth: form.birthdate,
          genderId: form.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
          countryId: form.country,
          countryBirthId: form.countryOfBirth,
          nationalityId: form.nationality,
          images: [
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Frontal',
              link: form.frontIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
              description: 'Cedula Reverso',
              link: form.backIdentityImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
            {
              typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
              description: 'Biometrico',
              link: form.faceImage,
              thumbnails: null,
              createdBy: 'CMF',
            },
          ],
          phones: phones,
          emails: [
            {
              typeEmail: 'PE',
              email: emailForm,
              isDefault: true,
              createdBy: SELLER_ID_TYPE,
            },
          ],
          Origin: origin,
          ReferralCode: referido,
          electronicSignId: identityId,
          electronicVideoId: requestVideoId,
        };
        let StatusChangePrepaid = {
          onboardingId: Number(onboardingId),
          statusId: ONBOARDING_STATUS.REJECTED,
          commentary: 'Onboarding rechazado',
          createdBy: SELLER_ID_TYPE,
          clientEmail: '',
          clientName: '',
          origin: origin,
          SendMail: false,
        };
        //NEW ONBOARDING PREPAGO
        await axiosInstance
          .put(`api/OnboardingPrepaid/${Number(onboardingId)}`, payload)
          .then(response => {
            changeStatusPrepaid(StatusChangePrepaid);
            dispatch({
              type: SET_APC_ID,
              payload: acpId,
            });
            dispatch({
              onboardingSaved: payload,
              type: FORM_REQUEST_UPDATE,
            });
          })
          .catch(async error => {
            await logsOnboarding(
              onboardingId,
              origin,
              'ERROR_UPDATE_PREPAID_TE',
              error?.response?.data,
              error?.response?.data?.problemPublic,
              false,
            );
          });
      } else {
        const payload = {
          action: 'CRECRE_COMCED',
          OnboardingCreDTO: {
            id: Number(onboardingId),
            statusId: ONBOARDING_STATUS.REJECTED,
            branchOfficeId:
              branchOfficeId.length > 3
                ? branchOfficeId
                : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
            createdBy: SELLER_ID_TYPE,
            lastModifiedBy: 'CMF_AZURE',
            typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
            identityNumber: form.identity,
            firstName: form.firstName,
            secondName: form.secondName || null,
            firstSurname: form.lastName,
            secondSurname: form.secondLastName,
            dateOfBirth: form.birthdate,
            genderId: form.gender === 1 ? GENDER_TYPE.MALE : GENDER_TYPE.FEMALE,
            images: [
              {
                typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
                description: 'Cedula Frontal',
                link: form.frontIdentityImage,
                thumbnails: null,
                createdBy: 'CMF',
              },
              {
                typeImageId: 'FB20BDC0-2E41-44D7-ABBA-9ECEE6CB5045',
                description: 'Cedula Reverso',
                link: form.backIdentityImage,
                thumbnails: null,
                createdBy: 'CMF',
              },
              {
                typeImageId: 'A7398CE9-96CA-41AE-9513-AE233C6FEB02',
                description: 'Biometrico',
                link: form.faceImage,
                thumbnails: null,
                createdBy: 'CMF',
              },
            ],
          },
          VideoConfirmDTO: {
            ...form,
            typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
            OnboardingBatchPreApprovedId: preBatchId,
            Origin: origin,
          },
        };
        await axiosInstance.post(
          `api/onboarding/UpdateOnboardingCrePre`,
          payload,
        );
      }

      onSuccess(true);
    } catch (error) {
      onSuccess(false);
      let exception = error.response.data.problemPublic;
      await logsOnboarding(
        onboardingId,
        origin,
        'ERROR_GET_UPDATE_TE',
        error?.response?.data,
        exception,
        false,
      );
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: exception
          ? exception
          : 'Ha ocurrido un error enviando solicitud',
      });
    }
  };
};

/**
 * Sends an email to verify the provided email address.
 *
 * @param {string} email - The email address to verify.
 * @param {function} onComplete - Callback function to be called upon completion.
 * @returns {function} A thunk function that dispatches actions.
 *
 * @async
 * @function
 * @param {function} dispatch - Redux dispatch function.
 *
 * @throws Will throw an error if the request fails.
 */
export const sendEmailToVerify = (email, origin, onComplete) => {
  return async dispatch => {
    dispatch({type: 'SET_LOADING_EMAIL', payload: true});
    try {
      const responses = await axiosInstance.get(
        `api/Customers/CheckEmailExists?email=${email}&origin=${origin}`,
      );
      let exist_data = JSON.parse(responses.data.jsonAnswer);
      if (!exist_data.Exists) {
        const response = await axiosInstance.post(
          'api/EmailServices/sendEmailverify',
          {
            to: email,
            username: '',
          },
        );
        if (response.data.complete) {
          onComplete(true);
        }
      } else {
        onComplete(null);
      }
    } catch (error) {
      let exception = error?.response?.data?.problemPublic;
      if (
        exception?.toUpperCase() ===
        MESSAGE_VALIDATION?.twilio_max_attemps?.toUpperCase()
      ) {
        dispatch({type: 'SET_ERROR_MAIL', payload: true});
        openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
      } else {
        dispatch({
          type: DIALOG_MESSAGE,
          dialogType: DIALOG_TYPES.ERROR,
          message: exception
            ? 'Cantidad de intentos superados, por favor inténtelo mas tarde'
            : 'Ha ocurrido un error enviando solicitud',
        });
      }
    } finally {
      dispatch({type: 'SET_LOADING_EMAIL', payload: false});
    }
  };
};

/**
 * Sends an SMS to verify the provided phone number.
 *
 * @param {string} phone - The phone number to verify.
 * @param {function} onComplete - Callback function to be called upon completion.
 * @returns {function} A thunk function that dispatches actions.
 */
export const sendSmsToVerify = (phone, origin, onComplete) => {
  return async dispatch => {
    dispatch({type: 'SET_LOADING_SMS', payload: true});
    try {
      const responses = await axiosInstance.get(
        `api/Customers/CheckPhoneExists?phone=${phone}&origin=${origin}`,
      );
      let exist_data = JSON.parse(responses.data.jsonAnswer);
      if (!exist_data.Exists) {
        const response = await axiosInstance.post(
          'api/EmailServices/sendVerify',
          {
            to: TYPE_PHONE.PA + phone,
            username: '',
          },
        );

        if (response.data.complete) {
          onComplete(true);
        }
      } else {
        onComplete(null);
      }
    } catch (error) {
      let exception = error.response.data.problemPublic;
      if (
        exception.toUpperCase() ===
        MESSAGE_VALIDATION.twilio_max_attemps.toUpperCase()
      ) {
        dispatch({type: 'SET_ERROR_PHONE', payload: true});
        openNotificationWithIcon('error', MAX_ATTEMPTS_MESSAGE);
      } else {
        openNotificationWithIcon(
          'error',
          exception
            ? 'Cantidad de intentos superados, por favor inténtelo mas tarde'
            : 'Ha ocurrido un error enviando solicitud',
        );
      }
    } finally {
      dispatch({type: 'SET_LOADING_SMS', payload: false});
    }
  };
};
//NEW ONBOARDING PREPAGO
export const registerInitial = (payload, onSucces) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {originSourceOnboarding, routeCreateUser} = RequestCardReducer;
    const id = routeCreateUser.newCode;
    let StatusChangePrepaid = {
      onboardingId: id,
      statusId: ONBOARDING_STATUS.PREAPROBADO,
      commentary: 'Onboarding preaprobado',
      createdBy: SELLER_ID_TYPE,
      clientEmail: '',
      clientName: '',
      origin: originSourceOnboarding,
      SendMail: false,
    };
    try {
      if (originSourceOnboarding === ORIGIN.PREPAGO) {
        await axiosInstance.put(`api/OnboardingPrepaid/${Number(id)}`, payload);
        changeStatusPrepaid(StatusChangePrepaid);
      } else {
        await axiosInstance.post(
          `api/onboarding/UpdateOnboardingCrePre`,
          payload,
        );
      }
      onSucces(true);
      dispatch({
        type: 'COMPLETE_VALIDATION_INFO',
        payload: payload,
      });
    } catch (error) {
      const problemPublicParsed = JSON.parse(error.response.data.problemPublic);
      onSucces(false);
      let seccion = error?.response?.data?.problemasEncontrados?.error
        ? error?.response?.data?.problemasEncontrados?.error
        : problemPublicParsed[0].Error;
      openNotificationWithIcon('error', seccion);
      await logsOnboarding(
        id,
        origin,
        'ERROR_REGISTER_INICIAL',
        error?.response?.data,
        seccion,
        false,
      );
    }
  };
};

/*generate todo el proceso de onboarding*/

/*TODO Mejora de logica solo para onboardings TDC y PREPAGO*/
/**
 * Updates the information and scans the biometric data for a request card.
 * @param {Function} onComplete - Callback function to be called after the update is complete.
 * @returns {Function} - Async function that dispatches the update action.
 */
export const updateInfoScanBiometric = onComplete => async (
  dispatch,
  getState,
) => {
  const {RequestCardReducer} = getState();
  const {
    branchOfficeId,
    routeCreateUser,
    cedulaState,
    form,
    originSourceOnboarding,
    emailForm,
    phoneForm,
  } = RequestCardReducer;
  const newIdOnb = parseInt(routeCreateUser.newCode);
  const emailSend = form.email === null ? emailForm : form.email;
  const phoneSend = form.cellphone === null ? phoneForm : form.cellphone;
  const payload = {
    action: 'CRECRE_UPDCED',
    istekky: false,
    OnboardingCreDTO: {
      id: newIdOnb,
      statusId: ONBOARDING_STATUS.PREAPROBADO,
      emails: [
        {
          typeEmail: 'PE',
          email: emailSend,
          isDefault: true,
          createdBy: SELLER_ID_TYPE,
          lastModifiedBy: new Date().toISOString,
          createdDate: new Date().toUTCString(),
        },
      ],
      phones: [
        {
          id: '1',
          typePhone: 'CE',
          area: '507',
          phone: phoneSend,
          isDefault: true,
          createdBy: SELLER_ID_TYPE,
          extension: null,
          lastModifiedBy: null,
        },
      ],
      branchOfficeId:
        branchOfficeId.length > 3
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      createdBy: SELLER_ID_TYPE,
      lastModifiedBy: 'CMF_AZURE',
      typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
      identityNumber: cedulaState,
    },
  };
  const payloadPrepaid = {
    id: newIdOnb,
    statusId: ONBOARDING_STATUS.PREAPROBADO,
    origin: ORIGIN.PREPAGO,
    emails: [
      {
        Id: 0,
        TypeEmail: 'PE',
        Email: emailSend,
        IsDefault: true,
        CreatedBy: SELLER_ID_TYPE,
        lastModifiedDate: new Date(),
        createdDate: new Date(),
      },
    ],
    phones: [
      {
        id: '1',
        typePhone: 'CE',
        area: '507',
        phone: phoneSend,
        isDefault: true,
        createdBy: SELLER_ID_TYPE,
        extension: null,
        lastModifiedBy: null,
        lastModifiedDate: new Date(),
        createdDate: new Date(),
      },
    ],
    branchOfficeId:
      branchOfficeId.length > 3
        ? branchOfficeId
        : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
    createdBy: SELLER_ID_TYPE,
    lastModifiedBy: 'CMF_AZURE',
    typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
    identityNumber: cedulaState,
  };
  let StatusChangePrepaid = {
    onboardingId: newIdOnb,
    statusId: ONBOARDING_STATUS.PREAPROBADO,
    commentary: 'Onboarding preaprobado',
    createdBy: SELLER_ID_TYPE,
    clientEmail: '',
    clientName: '',
    origin: ORIGIN.PREPAGO,
    SendMail: false,
  };
  //NEW ONBOARDING PREPAGO
  try {
    if (originSourceOnboarding === ORIGIN.PREPAGO) {
      await axiosInstance.put(
        `api/OnboardingPrepaid/${newIdOnb}`,
        payloadPrepaid,
      );
      changeStatusPrepaid(StatusChangePrepaid);
    } else {
      await axiosInstance.post(
        `api/onboarding/UpdateOnboardingCrePre`,
        payload,
      );
    }
    onComplete(true);
  } catch (error) {
    const problemPublicParsed = JSON.parse(
      error?.response?.data?.problemPublic,
    );

    const problemPublic = problemPublicParsed[0]?.Error;
    if (
      problemPublic.includes(
        'La dirección de correo ya se encuentra utilizada. por favor utilizar otra valida.',
      ) ||
      problemPublic.includes(
        'Información de Celular no procesable. El teléfono celular ya está siendo usado por otro cliente.',
      )
    ) {
      onComplete(6);
    }
    if (originSourceOnboarding === ORIGIN.PREPAGO) {
      if (
        problemPublic.includes(
          'El numero de identificacion existe como cliente y tiene un producto de prepago activo.',
        )
      ) {
        onComplete(2);
        dispatch({type: 'SHOW_INFO_TEXT_TDC', payload: false});
        dispatch({type: 'STOP_PREPAID', payload: true});
      } else if (problemPublic.includes('Ya tiene un onboarding aprobado')) {
        onComplete(3);
        dispatch({type: 'SHOW_INFO_TEXT_TDC', payload: false});
        dispatch({type: 'STOP_PREPAID', payload: true});
      } else if (
        problemPublic.includes('Tiene un onboarding en proceso de aprobación')
      ) {
        onComplete(4);
        dispatch({type: 'SHOW_INFO_TEXT_TDC', payload: false});
      } else {
        onComplete(false);
      }
    }

    let seccion = error?.response?.data?.problemasEncontrados?.error
      ? error?.response?.data?.problemasEncontrados?.error
      : problemPublicParsed[0]?.Error;
    openNotificationWithIcon('error', seccion);
    await logsOnboarding(
      newIdOnb,
      origin,
      'UPDATE_INFO_SCAN',
      error?.response?.data,
      seccion,
      false,
    );
  }
};
export const getSwitchStatusMotor2 = async () => {
  return (dispatch, getState) => {
    axiosInstance
      .get(`api/onboarding/getSwitchStatusMotor2`)
      .then(response => {
        const {data, status} = response;
        const jsonAnswerObj = data.jsonAnswer;

        if ((data, status)) {
          dispatch({type: 'GET_STATUS_SWITCH', payload: jsonAnswerObj});
          dispatch({
            type: 'GET_STATUS_VALUE_SWITCH',
            payload: jsonAnswerObj.Active,
          });
        }
      })
      .catch(error =>
        dispatch({type: 'GET_STATUS_SWITCH_ERROR', payload: error}),
      )
      .finally(() => dispatch({type: 'DETAILS_HIDE'}));
  };
};

export const UpdateOnboardingCrePPAGO = (request, onComplete) => {
  return async (dispatch, getState) => {
    const {KOReducer, RequestCardReducer} = getState();
    const {
      branchOfficeId,
      onboardingId,
      preBatchId,
      origin,
      referido,
      requestVideoId,
      identityId,
      form,
      onboardingSaved,
    } = RequestCardReducer;
    const newIdOnb = onboardingId;
    const {acpId} = KOReducer;
    const payload = {
      id: newIdOnb,
      apcId: acpId,
      onboardingBatchPreApprovedId: preBatchId,
      ...onboardingSaved,
      ...request,
      branchOfficeId:
        branchOfficeId.length > 3
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      lastModifiedBy: 'CMF',
      Origin: origin,
      ReferralCode: referido,
      electronicSignId: identityId,
      electronicVideoId: requestVideoId,
      idExpirationDate: form.IDExpirationDate,
    };
    const currentOnboardingDataCopy = {
      onboardingBatchPreApprovedId: preBatchId,
      ...onboardingSaved,
      ...request,
      apcId: acpId,
      branchOfficeId:
        branchOfficeId.length > 3
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      lastModifiedBy: 'CMF',
      Origin: origin,
      ReferralCode: referido,
      electronicSignId: identityId,
      electronicVideoId: requestVideoId,
      idExpirationDate: form.IDExpirationDate,
    };
    let StatusChangePrepaid = {
      onboardingId: newIdOnb,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      commentary: 'Onboarding en proceso',
      createdBy: SELLER_ID_TYPE,
      clientEmail: '',
      clientName: '',
      origin: origin,
      SendMail: false,
    };
    //NEW ONBOARDING PREPAGO
    await axiosInstance
      .put(`api/OnboardingPrepaid/${newIdOnb}`, payload)
      .then(async response => {
        await logsOnboarding(
          onboardingId,
          origin,
          'UPDATE_PERSONAL_INFO',
          response?.data,
          payload,
          true,
        );
        onComplete(true);
        changeStatusPrepaid(StatusChangePrepaid);
        return dispatch({
          onboardingSaved: currentOnboardingDataCopy,
          type: FORM_REQUEST_UPDATE,
        });
      })
      .catch(async error => {
        await logsOnboarding(
          onboardingId,
          origin,
          'ERROR_UPDATE_PERSONAL_INFO',
          error?.response?.data,
          error?.response?.data.problemPublic,
          false,
        );
        onComplete(false);
        return dispatch({
          type: DIALOG_MESSAGE,
          title: 'Notificacion',
          dialogType: DIALOG_TYPES.ERROR,
          message: error?.response?.data.problemPublic
            ? error?.response?.data.problemPublic
            : 'Error actualizando datos',
        });
      });
  };
};

export const ValidateCustomerWithEmailAndPhonePrepaid = async (
  email,
  phone,
  onComplete,
) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {originSourceOnboarding, onboardingId} = RequestCardReducer;
    let newIdOnb = onboardingId;
    try {
      const response = await axiosInstance.get(
        `api/OnboardingPrepaid/GetCustomerByPhoneEmail?email=${email}&phone=${phone}`,
      );
      const JsonAnswerPpaid = JSON.parse(response.data.jsonAnswer);
      const {LasOnboardingId, CustomerProducts} = JsonAnswerPpaid || {};
      newIdOnb = LasOnboardingId;
      const ProductsActivePrepago =
        CustomerProducts?.filter(
          product =>
            product.ProductTypeId.toUpperCase() ===
            PRODUCTS_TYPES.PREPAGO.toUpperCase(),
        ) || [];
      const ProductsActive =
        CustomerProducts?.filter(
          product =>
            product.ProductTypeId.toUpperCase() !==
            PRODUCTS_TYPES.PREPAGO.toUpperCase(),
        ) || [];
      if (ProductsActivePrepago.length > 0) {
        onComplete(true);
      } else if (ProductsActive.length > 0) {
        onComplete(false);
      } else {
        onComplete(1);
      }
    } catch (error) {
      await logsOnboarding(
        newIdOnb,
        originSourceOnboarding,
        'VALIDATE_EMAIL_PHONE_ERROR',
        error?.response?.data,
        false,
      );
    }
  };
};

//NEW ONBOARDING PREPAGO
export const ActiveProductPrepaid = (onboardingId, payload, onSucces) => {
  return async (dispatch, getState) => {
    try {
      const response = await axiosInstance.put(
        `api/OnboardingPrepaid/${Number(onboardingId)}`,
        payload,
      );
      if (response.data.complete) {
        await axiosInstance
          .post(`api/Customers/ActiveProductPrepaid`, {
            OnboardingId: onboardingId,
            CustomerId: null,
            ActiveBy: SELLER_ID_TYPE,
          })
          .then(res => {
            onSucces(true);
          })
          .catch(async error => {
            const errorResponse = error?.response?.data?.problemPublic;
            let seccion = errorResponse
              ? errorResponse
              : errorResponse?.[0].Error;
            await logsOnboarding(
              onboardingId,
              origin.PREPAGO,
              'ERROR_ACTIVE_PRODUCT_PREPAID',
              error?.response?.data,
              seccion,
              false,
            );
            onSucces(false);
          });
      }
    } catch (error) {
      const errorResponse = JSON.parse(error?.response?.data?.problemPublic);
      onSucces(false);
      let seccion = errorResponse
        ? errorResponse?.[0].Error
        : error?.response?.data?.problemPublic;
      openNotificationWithIcon('error', seccion);
    }
  };
};

export const loadToGetDocumentId = (onboarding, origin, templateName) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {branchOfficeId} = RequestCardReducer;
    const onboardingId = parseInt(onboarding);
    const payload = {
      onboardingId,
      templateName,
      origin,
      branchOfficeId:
        branchOfficeId.length > 1
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      TermsAndConditions: true,
    };
    dispatch({type: 'GET_LOAD_TO_PDF_TERMS', payload: true});
    try {
      const response = await axiosInstance.post(
        'api/print/getOnlyContract',
        payload,
      );
      if (response.status === 200 && response.data.error === false) {
        if (origin === ORIGIN.PREPAGO) {
          const payloadEID = {
            name: 'documentFile.pdf',
            document: response.data.response,
          };
          const respon = await axios.post(
            APP.electronicId.endpoints.uploadDocument,
            payloadEID,
            headerEID,
          );
          if (respon?.data?.documentId) {
            dispatch({
              documentId: respon.data.documentId,
              type: DOCUMENT_UPLOADED_SUCCESS,
            });
          }
        }
      }
    } catch (e) {
      await logsOnboarding(
        onboarding,
        'PPAGO',
        'PDF_ID',
        e?.response?.data,
        payload,
        false,
      );
      const error = e.response.data;
      dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: error,
      });
    } finally {
      dispatch({type: 'GET_LOAD_TO_PDF_TERMS', payload: false});
    }
  };
};

export const getToken = () => {
  return async dispatch => {
    try {
      const response = await getTokenMdl03();
      dispatch({
        payload: response.data,
        type: 'SAVE_MDL03',
      });
      return response.data;
    } catch (e) {}
  };
};

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

// Define la función GuestGuard que toma un objeto "children" como propiedad
GuestGuard.propTypes = {
  children: PropTypes.node,
}

export default function GuestGuard({children}) {
  // Utiliza useSelector para acceder al estado de AuthenticationReducer
  const {Authenticated} = useSelector(state => state.AuthenticationReducer)

  // Si el usuario está autenticado, redirige a la página de dashboard
  if (Authenticated) {
    return <Navigate to="/home" />
  }

  // Si el usuario no está autenticado, muestra los componentes hijos
  return <>{children}</>
}

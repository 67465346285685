/**
 * @flow
 */
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import { default as React } from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import NotFound from 'src/screens/404';
import ThemeConfig from 'src/theme';
import { URL_PHONE } from 'src/utils/Constants';
import { persistor, store } from '../config/configStore';
import App from '../routers/routers';
import './root.css';
moment.locale('es');

const isValidRoute = pathname => {
  return (
    pathname.startsWith('/site') &&
    (pathname === '/site' || pathname.startsWith('/site/'))
  );
};


const APP = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;
  const hash = window.location.hash;

  if (pathname.includes('/cardrequest') && !pathname.includes('/site/cardrequest')) {
    window.location.replace(`/site/cardrequest${search}${hash}`);
    return null; 
  }

  const isValid = isValidRoute(pathname);
  if (!isValid) {
    return <NotFound />;
  }
  const isThemeConfigRoute = window.location.pathname === `/${URL_PHONE}`;

  return (
    <>
      {isThemeConfigRoute ? (
        <>
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <BrowserRouter basename='/site'>
                <NotificationContainer />
                <PersistGate loading={null} persistor={persistor}>
                  <ConfigProvider>
                    {/* <ErrorHandler> */}
                      <App />
                    {/* </ErrorHandler> */}
                  </ConfigProvider>
                </PersistGate>
              </BrowserRouter>
            </LocalizationProvider>
          </Provider>
        </>
      ) : (
        <ThemeConfig>
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <BrowserRouter basename='/site'>
                <NotificationContainer />
                <PersistGate loading={null} persistor={persistor}>
                  {/* <ErrorHandler> */}
                    <App />
                  {/* </ErrorHandler> */}
                </PersistGate>
              </BrowserRouter>
            </LocalizationProvider>
          </Provider>
        </ThemeConfig>
      )}
    </>
  );
};
export default APP;

import {CircularProgress} from '@mui/material';
import React, {
  lazy,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  isAndroid,
  isChrome,
  isEdge,
  isFirefox,
  isIOS,
  isMobile,
  isSafari,
} from 'react-device-detect';
import {NotificationManager} from 'react-notifications';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import CMFLogoBlack from 'src/assets/images/logos/Logo-CMF-black.svg';
import {URL_CARD} from 'src/utils/Constants';
import {setStep} from '../../store/actions/Steps.Action';
import CardsOnboarding from './CardsOnboarding';
import ModalWebView from './ModalWebView';
import SCREENS from './screens';
import {
  Container,
  DivWelcome,
  ImgLogo,
  RequirementsDiv,
  SubtitleSpan,
  SubtitleSpanBold,
  TitleSpan,
} from './Welcome.style';

const ModalPersonalData = lazy(() => import('./Modals/ModalPersonalData'));

const Welcome = memo(props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [valid, setValid] = useState(true);
  const [openModalWebView, setOpenModalWebView] = useState(false);
  const [visibleWebViewIOS, setVisibleWebViewIOS] = useState(false);
  const [linkReferral, setLink] = useState(`${URL_CARD}`);
  const modalPersonaDataRef = useRef();

  const onConfirm = useCallback(() => {
    dispatch(setStep(SCREENS.Recommendation));
    props.goToStep(SCREENS.Recommendation);
  }, [dispatch, props.goToStep]);

  const onReject = useCallback(() => {
    props.goToStep(SCREENS.ErrorScreen);
  }, [props.goToStep]);

  const copyLinkURL = useCallback(() => {
    navigator.clipboard.writeText(linkReferral);
    NotificationManager.success('Enlace copiado correctamente');
  }, [linkReferral]);

  useEffect(() => {
    dispatch({type: 'RESET_ONBOARDING_PHONE'});
    dispatch({type: 'CLEAN_DIALOG'});
    dispatch({type: 'SET_URL_PATH', payload: window.location.pathname});
  }, [dispatch]);

  useEffect(() => {
    setLink(window.location.href);
    const url = window.location.href;
    if (isMobile && isIOS && !isSafari && !isFirefox && !isChrome && !isEdge) {
      window.location.href = `googlechrome://${url.replace(
        /^https?:\/\//,
        '',
      )}`;
      setOpenModalWebView(true);
      setVisibleWebViewIOS(true);
      setValid(false);
    } else if (isMobile && isAndroid && !isFirefox && !isChrome && !isEdge) {
      window.location.href = `intent://${url.replace(
        /^https?:\/\//,
        '',
      )}#Intent;scheme=https;package=com.android.chrome;end`;
      setOpenModalWebView(true);
      setValid(false);
    }
  }, [openModalWebView]);

  useEffect(() => {
    if (window.location.protocol === 'http:') {
      window.location.href = `https://${window.location.host}${location.pathname}${location.search}`;
    }
  }, [location]);

  return (
    <>
      <link rel="preload" href={CMFLogoBlack} as="image" type="image/svg+xml" />
      <Container id="Container">
        <DivWelcome>
          <span style={{margin: '15px 0px'}}></span>
          <TitleSpan>
            Con
            <ImgLogo src={CMFLogoBlack} alt="CMF" />
            tenemos la tarjeta
          </TitleSpan>
          <TitleSpan>perfecta para ti</TitleSpan>
          <SubtitleSpan>
            Solicita la tuya
            <SubtitleSpanBold>¡Fácil y sin costo ni papeleos!</SubtitleSpanBold>
          </SubtitleSpan>
          <CardsOnboarding
            valid={valid}
            modalPersonaDataRef={modalPersonaDataRef}
          />
          <RequirementsDiv>
            Recuerda: Tener a mano tu cédula y asegúrate de que esté limpia.
            Asegúrate de tener acceso a tu celular y a tu correo electrónico
          </RequirementsDiv>
        </DivWelcome>
      </Container>
      <Suspense fallback={<CircularProgress color="inherit" />}>
        <ModalPersonalData
          ref={modalPersonaDataRef}
          onConfirm={onConfirm}
          onClose={onReject}
        />
      </Suspense>
      {openModalWebView && (
        <ModalWebView
          visibleWebViewIOS={visibleWebViewIOS}
          openModalWebView={openModalWebView}
          copyLinkURL={copyLinkURL}
        />
      )}
    </>
  );
});

export default Welcome;

import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIdleTimer } from 'react-idle-timer/legacy';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { URL } from 'src/Data/Api';
import FooterComponent from 'src/components/Footer/footer.component';
import ModalTimeOut from 'src/components/Modal/ModalTimout/TimeOut';
import useResponsive from 'src/hooks/useResponsive';
import {
  ActiveProduct,
  getStatusChubb,
} from 'src/store/actions/ActiveProductAction';
import { resetState } from 'src/store/actions/actions';
import ModalError from '../Pages/ModalError';
import Login from '../auth/login.cmf';
import Header from './header';
import Nav from './nav';
import './style.css';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')(({theme}) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: '#F9FAFB',
  [theme.breakpoints.down('md')]: {
    minHeight: '80%',
  },
}));

const Main = styled('div')(({theme}) => ({
  // flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  minHeight: '100%',
  width: '100%',
  backgroundColor: theme.palette.primary.background,
  paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------
const timeout = 300000;
const promptBeforeIdle = 60000;
export default function DashboardLayout() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive('up', 'md');
  const {CurrentUser, Authenticated} = useSelector(
    state => state.AuthenticationReducer,
  );
  useEffect(() => {
    if (
      CurrentUser &&
      Object.keys(CurrentUser).length > 0 &&
      !CurrentUser.shouldChangePassword &&
      Authenticated
    ) {
      dispatch(getStatusChubb());
    }
  }, [CurrentUser, Authenticated, dispatch]);

  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const resetLogin = async () => {
    await fetch(`${URL}/site/cmfenlinea/api/logout`, {
      method: 'GET',
      credentials: 'include',
    });
    window.onbeforeunload = null;
    localStorage.removeItem('selectedIndex');
    navigate('/');
    dispatch(resetState());
    dispatch({
      type: 'CLEAN_LOGIN',
    });
  };
  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  useEffect(() => {
    if (
      !CurrentUser.error &&
      (selectedProduct === null || selectedProduct === undefined) &&
      !CurrentUser.mustChangePassword
    ) {
      dispatch(ActiveProduct(onSuccess));
    }
  }, [CurrentUser, selectedProduct]);

  const onSuccess = success => {
    setOpenModal(success);
  };

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 4201) {
        resetLogin();
        dispatch({
          type: 'SHOW_SCREEN_LOGIN',
          payload: true,
        });
      }
      return Promise.reject(error);
    },
  );

  const [tiempoRestante, setTiempoRestante] = useState(timeout);
  const [esPromptVisible, setEsPromptVisible] = useState(false);

  const enInactividad = () => {
    resetLogin();
    dispatch({
      type: 'SHOW_SCREEN_LOGIN',
      payload: true,
    });
  };

  const enActivo = () => {
    setEsPromptVisible(false);
  };

  const enPrompt = () => {
    setEsPromptVisible(true);
  };

  const {getRemainingTime, activate} = useIdleTimer({
    onIdle: enInactividad,
    onActive: enActivo,
    onPrompt: enPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const intervalo = setInterval(() => {
      const tiempoRestanteActual = getRemainingTime();
      setTiempoRestante(Math.ceil(tiempoRestanteActual / 1000));
    }, 500);

    return () => {
      clearInterval(intervalo);
    };
  }, [getRemainingTime]);

  const handleStillHere = () => {
    activate();
  };

  window.addEventListener('beforeunload', () => {
    dispatch(resetState());
  });

  return (
    <React.Fragment>
      <ModalTimeOut
        isNotified={esPromptVisible}
        remaining={tiempoRestante}
        handleStillHere={handleStillHere}
      />
      {Authenticated === 3 ? (
        <Login route="reset" />
      ) : (
        <>
          <StyledRoot>
            <Helmet>
              <meta charSet="utf-8" />
              <title>CMF en línea</title>
            </Helmet>
            <Header onOpenNav={() => setOpen(true)} />
            <Nav openNav={open} onCloseNav={() => setOpen(false)} />
            <Main>
              <Outlet />
              {isDesktop && <FooterComponent />}
              {openModal ? <ModalError open={onSuccess} /> : <></>}
            </Main>
          </StyledRoot>

          {!isDesktop && <FooterComponent />}
        </>
      )}

      {!isDesktop && <FooterComponent />}
    </React.Fragment>
  );
}

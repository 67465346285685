import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {TextField, styled} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import {useFormik} from 'formik';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import passwordCMF from 'src/assets/images/cmf_linea/passwordLoginCMF.svg';
import {ResetPasswordChangePassword} from 'src/store/actions/LoginAction';
import * as yup from 'yup';
import '../../../App.css';
import * as reset from './resetPassword.style';
import {SendEmailToChangePassword} from 'src/store/actions/LoginAction';
const RedditTextField = styled(props => (
  <TextField InputProps={{disableUnderline: true}} {...props} />
))(({theme}) => ({
  '& .MuiInputAdornment-root': {
    marginTop: '0!important',
  },
  '& .MuiFormLabel-root-MuiInputLabel-root': {
    left: '30px',
  },
  '& .MuiInputBase-input-MuiFilledInput-input': {
    paddingLeft: '3px !important',
  },

  '& .MuiInputLabel-root': {
    position: 'absolute',
    left: '30px',
    marginLeft: '14px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  '& .MuiFilledInput-root': {
    borderRadius: '16px',
    border: '1px solid var(--specific-light-stroke-l, #D0D5DD)',
    background: 'var(--specific-light-background-white, #FFF)',
    overflow: 'hidden',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
  },
}));

const NewPassword = ({email, onNextPassword, getPassword, isOut}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const {Authenticated, emailUser, ResetLoading} = useSelector(
    state => state.AuthenticationReducer,
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSuccess = success => {
    if (success) {
      setLoading(false);
      if (Authenticated === 3) {
        dispatch({
          type: 'PROCCESS_LOGIN',
          payload: true,
        });
        navigate('/home');
      } else {
        navigate('/');
      }
    } else {
      setLoading(false);
    }
  };
  const validationSchemaBank = yup.object({
    password: yup
      .string('Introduce el codigo')
      .required('Campo requerido')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/,
        'Tu contraseña no cumple con el formato indicado',
      ),
    newPassword: yup
      .string('Introduce el contraseña')
      .required('Campo requerido')
      .oneOf([yup.ref('password')], 'Las contraseñas deben ser iguales'),
  });
  const formikPassword = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
    },
    validationSchema: validationSchemaBank,
    onSubmit: values => {
      setLoading(true);
      if (isOut) {
        dispatch(
          SendEmailToChangePassword(
            Authenticated === 3 ? emailUser : email,
            onNextPassword,
          ),
        );
        getPassword(values.newPassword);
      } else {
        dispatch(
          ResetPasswordChangePassword(
            {
              to: Authenticated === 3 ? emailUser : email,
              password: values.password,
              newPassword: values.newPassword,
            },
            onSuccess,
          ),
        );
      }
    },
  });
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowNewPassword = () => setShowNewPassword(show => !show);
  return (
    <>
      <reset.text>
        <Typography variant="titleLogin">Crea tu contraseña</Typography>
      </reset.text>
      <form onSubmit={formikPassword.handleSubmit} style={{width: '100%'}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <RedditTextField
            fullWidth
            label="Contraseña"
            id="password"
            variant="filled"
            style={{marginTop: 11}}
            name="password"
            value={formikPassword.values.password}
            onChange={formikPassword.handleChange}
            onBlur={formikPassword.handleBlur}
            error={
              formikPassword.touched.password &&
              Boolean(formikPassword.errors.password)
            }
            helperText={
              formikPassword.touched.password && formikPassword.errors.password
            }
            // onChange={handleOnChangePassword}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={passwordCMF}
                    style={{width: '1.5rem', margin: '0px 8px'}}
                    alt="Email"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? (
                      <Visibility style={{color: '#026E18', margin: '7px'}} />
                    ) : (
                      <VisibilityOff
                        style={{color: '#026E18', margin: '7px'}}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Contraseña"
            required
          />
          <RedditTextField
            fullWidth
            label="Confirmar Contraseña"
            id="confirmPassword"
            variant="filled"
            name="newPassword"
            style={{marginTop: 11}}
            value={formikPassword.values.newPassword}
            onChange={formikPassword.handleChange}
            onBlur={formikPassword.handleBlur}
            error={
              formikPassword.touched.newPassword &&
              Boolean(formikPassword.errors.newPassword)
            }
            helperText={
              formikPassword.touched.newPassword &&
              formikPassword.errors.newPassword
            }
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={passwordCMF}
                    style={{width: '1.5rem', margin: '0px 8px'}}
                    alt="Email"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showNewPassword ? (
                      <Visibility style={{color: '#026E18', margin: '7px'}} />
                    ) : (
                      <VisibilityOff
                        style={{color: '#026E18', margin: '7px'}}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Confirmar Contraseña"
            required
          />
        </div>
        <br />
        <reset.pCopy>Tu contraseña debe tener lo siguiente:</reset.pCopy>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}>
          <reset.PasswordRules>
            <reset.Rules>
              <Typography
                variant="H5"
                color="rgba(16, 24, 40, 0.64)"
                gutterBottom>
                a
              </Typography>
              <Typography variant="H4" gutterBottom>
                Minúscula
              </Typography>
            </reset.Rules>
            <reset.Rules>
              <Typography
                variant="H5"
                color="rgba(16, 24, 40, 0.64)"
                gutterBottom>
                A
              </Typography>
              <Typography variant="H4" gutterBottom>
                Mayúscula
              </Typography>
            </reset.Rules>
            <reset.Rules>
              <Typography
                variant="H5"
                color="rgba(16, 24, 40, 0.64)"
                gutterBottom>
                %
              </Typography>
              <Typography variant="H4" gutterBottom>
                Simbolo
              </Typography>
            </reset.Rules>
            <reset.Rules>
              <Typography
                variant="H5"
                color="rgba(16, 24, 40, 0.64)"
                gutterBottom>
                123
              </Typography>
              <Typography variant="H4" gutterBottom>
                Número
              </Typography>
            </reset.Rules>
            <reset.Rules>
              <Typography
                variant="H5"
                color="rgba(16, 24, 40, 0.64)"
                gutterBottom>
                8+
              </Typography>
              <Typography variant="H4" gutterBottom>
                Caracteres
              </Typography>
            </reset.Rules>
          </reset.PasswordRules>
        </div>
        <br />
        <reset.ContainerButton>
          <reset.ButtonReset
            variant="contained"
            loading={ResetLoading}
            type="submit">
            Continuar
          </reset.ButtonReset>
        </reset.ContainerButton>
      </form>
    </>
  );
};

export default NewPassword;

// CorporateCard.jsx
import { styled } from '@material-ui/core/styles';
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconBlock from 'src/assets/icons/cmf_linea/svgs/IconBlock';
import BlockCards from 'src/components/Drawers/BlockCards';
import { Cmf_Base, PointsApi } from 'src/Data/Api';
import { setProductData } from 'src/store/actions/actions';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { skeletonSx } from 'src/theme/constants';
import { PRODUCTS_TYPES } from 'src/utils/Constants';
import {
  CantidadTotalEnPorcentaje,
  formatoMoneda,
  ocultarNumero,
} from 'src/utils/Functions';

const Cicular = styled(CircularProgress)(({theme}) => ({
  color: theme.palette.common.white,
  width: '28px !important',
  position: 'absolute',
  '& .MuiCircularProgress': {
    backgroundSize: '100% 100%',
    border: '4px solid',
    borderColor: '#d0d5dd29',
    borderRadius: '14.25px',
    height: '28px',
    left: '1px',
    position: 'relative',
    top: '1px',
    width: '28px',
  },
}));

function CorporateCard({
  item,
  isSelected,
  isShowBlock,
  onClick,
  click,
  isStatusBlock,
  isDetailsPage,
}) {
  const {CurrentUser} = useSelector(state => state.AuthenticationReducer);
  const [dataAccount, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageCardBackground = item?.customerAccountCards[0]?.cardImage;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [stateBlock, setStateBlock] = useState(false);
  const [visibleBlock, setVisibleBlock] = React.useState(false);
  const [clickCard, setClickCard] = useState(click);
  const dispatch = useDispatch();
  useEffect(() => {
    // Función para obtener el saldo del servicio web
    const fetchBalance = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Cmf_Base}${PointsApi}?anyo=0&Mes=0&Cuenta=${encodeURIComponent(
            item?.productAccount,
          )}&CreatedBy=${CurrentUser?.customer?.fullname}`,
          {
            headers: {
              Authorization: `Bearer ${CurrentUser?.access_token}`,
            },
          },
        );
        const setDataAccount = JSON.parse(response?.data.jsonAnswer);
        setBalance(setDataAccount);
        dispatch(setProductData(item.customerProductId, setDataAccount));
      } catch (error) {
        console.error('Error fetching balance:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, [item.customerProductId]);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);
  const handleToggle = () => {
    setClickCard(false);
    setVisibleBlock(true);
  };
  return (
    <React.Fragment>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={282}
          height={160}
          sx={skeletonSx}
        />
      ) : (
        <Box
          sx={{
            border:
              isShowBlock && isStatusBlock ? '1.6px solid #026E18' : 'none',
            borderRadius: '20px',
            height: '172px',
          }}>
          <Box
            onClick={() => {
              if (clickCard) {
                onClick();
              }
            }}
            sx={{
              backgroundImage: `url(${imageCardBackground})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '20px',
              height: '170px',
              overflow: 'hidden',
              position: 'relative',
              width: '282px',
              filter: `${
                isSelected || isDetailsPage
                  ? 'brightness(1.0)'
                  : 'brightness(0.5)'
              } ${
                isShowBlock && isStatusBlock
                  ? 'grayscale(100%)'
                  : 'grayscale(0%)'
              }`,
              transform: `scale(${
                isSelected || isDetailsPage ? '1.0' : '0.95'
              })`,
              boxShadow: isSelected
                ? '6px 8px 16px 0px #000000'
                : isDetailsPage
                ? '0px 0px 0px 0px #000000'
                : 'none',
              border:
                isShowBlock && isStatusBlock ? '1px solid #026E18' : 'none',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: `${windowWidth - 42}px`,
                height: '210px',
              },
              marginBottom: '5px',
              objectFit: 'cover',
              objectPosition: 'center',
            }}>
            <Box
              sx={{
                alignItems: 'flex-start',
                display: 'inline-flex',
                gap: item?.isCorporate ? '0px' : '8px',
                padding: item?.isCorporate ? '22px 1vw' : '16px',
                position: 'absolute',
                bottom: '55px',
                [BreakPointTheme.breakpoints.up('sm')]: {
                  left: '132px',
                },
                [BreakPointTheme.breakpoints.down('sm')]: {
                  right: 0,
                  bottom: 0,
                  padding: item?.isCorporate ? '17px 1rem' : '16px',
                },
              }}>
              <Box
                className="spinnerCard"
                sx={{position: 'relative', display: 'inline-flex'}}>
                {item?.productTypeId?.toUpperCase() !==
                  PRODUCTS_TYPES.PREPP && (
                  <>
                    <Cicular
                      variant="determinate"
                      thickness={6}
                      value={CantidadTotalEnPorcentaje(
                        dataAccount?.DisponibleML,
                        dataAccount?.LimCreditoML,
                      )}
                    />
                    <Cicular
                      style={{
                        color: '#D0D5DD29',
                      }}
                      variant="determinate"
                      thickness={6}
                      value={100}
                    />
                  </>
                )}
              </Box>

              <div className="div">
                <Typography
                  variant="moneyCard"
                  sx={{
                    fontSize: item?.isCorporate ? '18px' : '20px',
                    color: '#FFF',
                  }}>
                  {formatoMoneda(dataAccount?.DisponibleML ?? 0)}
                </Typography>
                <Typography variant="H4" sx={{color: '#FFFFFF8A'}}>
                  Disponible
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                height: '30%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                left: '-1px',
                padding: '16px',
                position: 'absolute',
                bottom: '8px',
                width: '282px',
                flexDirection: 'row',
                [BreakPointTheme.breakpoints.down('sm')]: {
                  width: '100%',
                },
              }}>
              <Box>
                <Typography
                  variant="H5"
                  sx={{color: '#FFFFFF8A', fontSize: '12px'}}>
                  {item?.fullName}
                  <br />
                  {dataAccount?.Nombre}
                </Typography>
                <Typography
                  color="#FFF"
                  variant="textCard"
                  sx={{fontSize: '18px'}}>
                  {ocultarNumero(
                    item?.customerAccountCards[0]?.lastFourDigitCard,
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          {isShowBlock && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '8px', // Align to the bottom
                right: '10px', // Align to the right
                padding: '8px', // Optional padding for spacing
              }}
              onClick={handleToggle}>
              <button
                sx={{
                  filter: 'none', // Prevent parent filter from applying
                  backgroundColor: 'transparent', // Adjust as needed
                  cursor: 'pointer',
                }}>
                <IconBlock status={isStatusBlock} />
              </button>
            </Box>
          )}
          {visibleBlock && (
            <BlockCards
              imageCardBackground={imageCardBackground}
              mode="light"
              state="default"
              types="Drawer"
              closeDrawer={() => {
                setVisibleBlock(false);
                setClickCard(true);
              }}
              openDrawer={visibleBlock}
              status={isStatusBlock}
              lastFourDigitCard={
                item?.customerAccountCards[0]?.lastFourDigitCard
              }
            />
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

CorporateCard.propTypes = {
  click: PropTypes.any,
  isSelected: PropTypes.any,
  item: PropTypes.shape({
    customerAccountCards: PropTypes.any,
    customerProductId: PropTypes.any,
    fullName: PropTypes.any,
    isCorporate: PropTypes.any,
    productAccount: PropTypes.any,
    productTypeId: PropTypes.shape({
      toUpperCase: PropTypes.func,
    }),
  }),
  onClick: PropTypes.func,
  isShowBlock: PropTypes.bool,
  isStatusBlock: PropTypes.any,
  isDetailsPage: PropTypes.bool,
};

export default CorporateCard;

import React, { useEffect, useState } from 'react';
// @mui
import { styled } from '@material-ui/core/styles';
import { DownloadOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import axios from 'axios';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Cmf_Base } from 'src/Data/Api';
import CheckCircle from 'src/assets/images/logos/hand-money.svg';
import CreditCard from 'src/components/CreditCard/CreditCard';
import InformacionGeneral from 'src/containers/cmf_linea/inicio_cmf/InformacionGeneral';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { IDENTITY_TYPES } from 'src/utils/Constants';
import {
  TransactionTypeDecorator,
  firstName,
  getTransactionTypeDescription,
} from 'src/utils/Functions';
import formats from 'src/utils/formats';
import Movimientos from '../Movimientos/Movimientos';
import CarrouselContainer from '../inicio_cmf/carrousel';
import { ContainerData, ContainerMaterial } from './ContainerHome';
import SkeletonHome from './Skeleton';
// ----------------------------------------------------------------------
export const TypographyStyled = styled(Typography)(({theme}) => ({
  display: 'flex',
  [BreakPointTheme.breakpoints.up('xs')]: {
    padding: '8px',
  },
}));
export default function DashboardHome() {
  const {Loadingproduct, Extra} = useSelector(
    state => state.ActiveProductReducer,
  );
  const [activeTab, setActiveTab] = useState(1);

  const {userSF, tokenCMF} = useSelector(
    state => state.AuthenticationReducer?.CurrentUser,
  );
  const {CurrentUser} = useSelector(state => state.AuthenticationReducer);
  const {listCustomerProducts, systemId} = useSelector(
    state => state.ActiveProductReducer,
  );
  const rechargeMessage = useSelector(
    state => state.AuthenticationReducer.rechargeMessage,
  );
  const identification = useSelector(
    state => state.AuthenticationReducer?.CurrentUser?.customer?.IdentityNumber,
  );

  const {selectedProduct} = useSelector(state => state.ActiveProductReducer);
  const detailsById = useSelector(state => state.productReducer.productData);
  const dataCurrent = detailsById[selectedProduct?.customerProductId];
  const {movementsList} = useSelector(state => state.productReducer);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlLink = urlParams.get('success');
  const [modalShow, setModalShow] = useState(false);
  const [extraCredit, setExtracreditResponse] = useState(null);
  const [ok, setOk] = useState(false);
  const dispatch = useDispatch();

  const ExcelReportDownload = () => {
    const downloadExcel = async () => {
      const dataPrueba = movementsList ?? '';
      let option = {};
      let dataTable = [];
      if (dataPrueba) {
        for (let i in dataPrueba) {
          if (Array.isArray(dataPrueba) && dataPrueba.length > 0) {
            let obj = {
              transaccion: moment(dataPrueba[i].FechaOrigen).format(
                'MM/DD/YYYY',
              ), //transaccion
              comercio: dataPrueba[i].Descripcion, //comercio
              code: getTransactionTypeDescription(dataPrueba[i].Transaccion), //code
              monto: [
                `${
                  TransactionTypeDecorator[dataPrueba[i]?.TipoMov]
                } ${dataPrueba[i]?.MtoTra?.toFixed(2)}`,
              ],
              account: selectedProduct?.customerAccountCards[0]?.maskedCard, //Account
              nameEnte: selectedProduct?.fullName, //Name\
              name: dataCurrent?.Nombre,
            };
            dataTable.push(obj);
          }
        }
      }
      option.fileName = 'Reporte de movimientos';
      option.datas = [
        {
          sheetData: dataTable,
          sheetName: 'sheet',
          sheetFilter: [
            'transaccion',
            'comercio',
            'code',
            'monto',
            'account',
            'nameEnte',
            'name',
          ],
          sheetHeader: [
            'F. Transacción',
            'Comercio',
            'Código de transacción',
            'Monto',
            'Cuenta',
            'Nombre del ente',
            'Nombre de tarjeta',
          ],
          columnWidths: [10, 20, 10, 10, 10, 10, 20],
        },
      ];

      let toExcel = new ExportJsonExcel(option);
      await toExcel.saveExcel();
    };
    downloadExcel();
  };

  const getExtracredit = async () => {
    try {
      const payload = {
        typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
        identification: identification,
      };
      const {data} = await axios.get(
        `${Cmf_Base}api/Customers/GetCustomerDetailbyTypeIdentityandIdentification2?typeIdentityId=${payload.typeIdentityId}&identification=${payload.identification}`,
        {
          headers: {
            Authorization: `Bearer ${CurrentUser?.access_token}`,
          },
        },
      );
      if (
        data.complete &&
        data.jsonAnswer &&
        Array.isArray(data.jsonAnswer.customerFinancialProposalsDTO) &&
        data.jsonAnswer.customerFinancialProposalsDTO.length > 0 &&
        data.jsonAnswer.customerFinancialProposalsDTO.some(
          credit => credit.typeProduct === 'EXTRAFINANCIAL',
        )
      ) {
        // onClose()
        const infoMapExtra = data?.jsonAnswer?.customerFinancialProposalsDTO?.filter(
          credit => credit?.typeProduct === 'EXTRAFINANCIAL',
        );
        setExtracreditResponse(infoMapExtra);
        setOk(true);
      } else {
        setOk(false);
        setExtracreditResponse(false);
        dispatch({type: 'EXTRA', payload: true});
      }
    } catch (error) {
      NotificationManager.error(
        'Oops algo ha salido mal, por favor intente de nuevo.',
      );
    }
  };
  useEffect(() => {
    if (urlLink === 'true') {
      if (rechargeMessage) {
        NotificationManager.success(
          'Haz recargado tu Tarjeta CMF exitosamente',
          '',
          10000,
        );
      } else {
        NotificationManager.success(
          'Haz pagado tu Tarjeta CMF exitosamente',
          '',
          10000,
        );
      }
    } else if (urlLink === 'false') {
      NotificationManager.error(
        'En estos momentos la transacción no se completó correctamente.',
        '',
        10000,
      );
    }
  }, []);
  const OnCloseModal = () => {
    setOk(false);
    setExtracreditResponse(false);
    dispatch({type: 'EXTRA', payload: true});
  };
  const aceptExtra = () => {
    setOk(false);
    setExtracreditResponse(false);
    setModalShow(true);
    dispatch({type: 'EXTRA', payload: true});
  };
  useEffect(() => {
    // Establece el scroll en la parte superior al cargar el componente
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Establece el scroll en la parte superior al cargar el componente
    if (Extra !== true) {
      getExtracredit();
    }
  }, [Extra]);
  useEffect(() => {
    setActiveTab(1);
  }, [selectedProduct]);
  const renderMovimiento = () => {
    return <Movimientos path="home" />;
  };
  const onChangeTabs = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <ContainerMaterial maxWidth="xl">
      {extraCredit && extraCredit?.length > 0 && (
        <Dialog
          open={ok}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              margin: '0',
            },
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '420px',
              padding: '32px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              borderRadius: '32px',
              background: '#FFF',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '95vw',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                width: '84px',
                height: '84px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '100px',
                background: '#F5FBEE',
              }}>
              <img
                alt="Check circle"
                src={CheckCircle}
                style={{
                  width: '36.99px',
                  height: '43.26px',
                  flexShrink: 0,
                }}
              />
            </Box>

            <DialogTitle
              id="alert-dialog-title"
              sx={{
                padding: 0,
              }}>
              <p
                style={{
                  color:
                    'var(--specific-light-content-black, var(--specific-light-content-black, #101828))',
                  fontFamily: 'Red Hat Text',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '22px /* 122.222% */',
                  letterSpacing: '-0.36px',
                  textAlign: 'center',
                }}>
                Hola {firstName(userSF[0]?.FirstName ?? '')}
              </p>

              <p
                style={{
                  width: '241px',
                  color:
                    'var(--specific-light-content-black, var(--specific-light-content-black, #101828))',
                  textAlign: 'center',
                  fontFamily: 'Red Hat Text',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '18px /* 128.571% */',
                }}>
                ¡Tienes aprobado un Extra Crédito por un monto máximo de $
                {formats.formatNumberThousands(extraCredit[0]?.amount) ?? ''}!
              </p>
            </DialogTitle>
            <DialogContent
              sx={{
                textAlign: 'center',
                padding: 0,
              }}>
              <p
                style={{
                  alignSelf: 'stretch',
                  color: 'rgba(16, 24, 40, 0.64)',
                  textAlign: 'center',
                  fontFamily: 'Red Hat Text',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px /* 128.571% */',
                  marginBotton: '0 !important',
                }}>
                ¿Deseas obtenerlo?
              </p>
            </DialogContent>
            <DialogActions
              sx={{
                width: '90%',
                [BreakPointTheme.breakpoints.down('sm')]: {
                  width: '90vw',
                },
              }}>
              <LoadingButton
                sx={{
                  display: 'flex',
                  height: '36px',
                  padding: '0px 16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: '500',
                  gap: '8px',
                  borderRadius: '100px',
                  border: '1.6px solid rgba(16, 24, 40, 0.40)',
                  textTransform: 'none',
                  width: '100%',
                  fontSize: '14px',
                }}
                onClick={OnCloseModal}>
                No me interesa
              </LoadingButton>
              <LoadingButton
                variant="contained"
                sx={{
                  display: 'flex',
                  height: '36px',
                  padding: '0px 16px',
                  fontWeight: '500',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '100px',
                  textTransform: 'none',
                  width: '100%',
                }}
                onClick={aceptExtra}>
                Sí, lo quiero
              </LoadingButton>
            </DialogActions>
          </Box>
        </Dialog>
      )}
      {Loadingproduct ? (
        <SkeletonHome />
      ) : (
        <>
          <ContainerData>
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                marginBotton: '.4rem',
                alignItems: 'center',
              }}>
              <TypographyStyled variant="H2">
                Inicio{' '}
                {systemId === 'VOLCAN' && `(${listCustomerProducts?.length})`}
              </TypographyStyled>
            </div>
            <CreditCard />
          </ContainerData>
          <br />
          <br />
          <ContainerData>
            <TypographyStyled variant="H5">Acciones rápidas</TypographyStyled>
            <CarrouselContainer opens={modalShow} />
          </ContainerData>
          <br />
          <ContainerData>
            <Box sx={{width: '100%', height: '60%'}}>
              <Tabs
                value={activeTab}
                onChange={onChangeTabs}
                aria-label="document tabs"
                indicatorColor="primary"
                textColor="primary">
                <Tab
                  label="Movimientos"
                  value={1}
                  style={{
                    textTransform: 'none',
                  }}
                />
                {selectedProduct?.isCorporate && (
                  <Tab
                    label="Información General"
                    value={2}
                    style={{
                      textTransform: 'none',
                    }}
                  />
                )}
              </Tabs>
              {activeTab === 1 && (
                <Box
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                  }}>
                  {' '}
                  {selectedProduct?.isCorporate && (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'end',
                      }}>
                      <Button
                        variant="outlined"
                        disabled={movementsList?.length === 0}
                        onClick={ExcelReportDownload}
                        startIcon={<DownloadOutlined />}>
                        Descargar
                      </Button>
                    </Box>
                  )}
                  {renderMovimiento()}
                </Box>
              )}
              {activeTab === 2 && (
                <Box
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                  }}>
                  <InformacionGeneral />
                </Box>
              )}
            </Box>
          </ContainerData>
        </>
      )}
    </ContainerMaterial>
  );
}

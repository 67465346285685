import { styled } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CorporateCard from 'src/components/CreditCard/CorporateCard';
import NonCorporateCard from 'src/components/CreditCard/propertyAndPrepaid';
import BenefitsDrawer from 'src/components/Drawers/BenefitsDrawer';
import PrepaidRecharge from 'src/components/Drawers/PrepaidRecharge';
import SwipeableTemporaryDrawer from 'src/components/Drawers/SwipeableTemporaryDrawer';
import { CardIsBlockingService } from 'src/store/actions/ActiveProductAction';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { MASTERCARD_PRODUCTS, PRODUCTS_TYPES } from 'src/utils/Constants';
import Benefits from '../Benefits/Benefits';
import Estado from '../Estado/Estado';
import Credit from './../../../assets/icons/cmf_linea/credit-card.png';
import { ContainerData, ContainerMaterial } from './ContainerHome';
import './style.css';
// ----------------------------------------------------------------------
export const ContainerTable = styled(Box)(({theme}) => ({
  width: '100%',
  [theme.breakpoints.between('xxs', 'xl')]: {
    width: '90%',
  },
  [theme.breakpoints.between('xxl', 'xxxl')]: {
    width: '85%',
  },
}));

export const ContentTable = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.between('xxs', 'xl')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.between('xxl', 'xxxl')]: {
    justifyContent: 'flex-start',
  },
}));

export const BoxData = styled(Box)(({theme}) => ({
  padding: '24px',
  [theme.breakpoints.between('xxs', 'xl')]: {
    padding: '10px',
  },
  [theme.breakpoints.between('xxl', 'xxxl')]: {
    padding: '24px',
  },
}));
function CustomTabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <BoxData
          sx={
            index === 2 && {
              padding: '0px !important',
            }
          }>
          <Typography>{children}</Typography>
        </BoxData>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DetailsCardHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const {selectedProduct, statusBlockCard} = useSelector(
    state => state.ActiveProductReducer,
  );
  const statusBlockCardBool =
    statusBlockCard?.descripcionStatus === 'BLOQUEADA' ? true : false;
  React.useEffect(() => {
    dispatch(CardIsBlockingService(selectedProduct?.customerProductId));
  }, []);
  const detailsById = useSelector(state => state.productReducer.productData);
  const dataCurrent = detailsById[selectedProduct?.customerProductId];
  const [visibleBenefit, setVisibleBenefit] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleShowMore = () => {
    navigate('/home/movements');
  };
  const visiblePayOption = !selectedProduct?.isCorporate;
  const isShowBlock = MASTERCARD_PRODUCTS.includes(
    selectedProduct?.productId?.toUpperCase(),
  );

  const disabledOptionPay = statusBlockCardBool && isShowBlock;
  const renderDrawer = () => {
    const renderPrepaid =
      selectedProduct?.productTypeId?.toUpperCase() === PRODUCTS_TYPES.PREPP;
    switch (true) {
      case renderPrepaid:
        return (
          <PrepaidRecharge
            icon={
              <img
                alt="Credit"
                style={{
                  width: '22px',
                  height: '22px',
                }}
                src={Credit}
              />
            }
            mode="light"
            state="default"
            texto={
              <>
                Recargar <br />
                Tarjeta
              </>
            }
            payMin={dataCurrent?.PagoMinimoML}
            pagoContado={dataCurrent?.PagocontadoML}
            disabled={disabledOptionPay}
          />
        );
      default:
        return (
          <SwipeableTemporaryDrawer
            icon={
              <img
                alt="Credit"
                style={{
                  width: '22px',
                  height: '22px',
                }}
                src={Credit}
              />
            }
            mode="light"
            state="default"
            texto={
              <>
                Pagar <br />
                Tarjeta
              </>
            }
            payMin={dataCurrent?.PagoMinimoML}
            pagoContado={dataCurrent?.PagocontadoML}
            disabled={disabledOptionPay}
          />
        );
    }
  };
  const onShowDrawer = () => {
    setVisibleBenefit(true);
  };

  return (
    <ContainerMaterial maxWidth="xl">
      <ContainerData>
        <Typography
          variant="H5"
          sx={{
            display: 'flex',
          }}>
          Tarjeta Digital
        </Typography>
        <Box sx={{flexGrow: 1, marginTop: 3}}>
          <Grid
            container
            spacing={2}
            columns={14}
            sx={{
              marginLeft: 0,
            }}>
            <Grid
              sx={{
                [BreakPointTheme.breakpoints.down('sm')]: {
                  width: '96%',
                },
              }}>
              <Box
                sx={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  gap: '16px',
                  position: 'relative',
                }}>
                {selectedProduct?.isCorporate ? (
                  <CorporateCard
                    item={selectedProduct}
                    isSelected={false}
                    isShowBlock={isShowBlock}
                    click={true}
                    onClick={onShowDrawer}
                    isStatusBlock={statusBlockCardBool}
                    isDetailsPage={true}
                  />
                ) : (
                  <NonCorporateCard
                    item={selectedProduct}
                    isSelected={false}
                    isShowBlock={isShowBlock}
                    click={true}
                    onClick={onShowDrawer}
                    isStatusBlock={statusBlockCardBool}
                    isDetailsPage={true}
                  />
                )}
              </Box>
            </Grid>
            {visiblePayOption && (
              <Grid
                item
                xs={8}
                md={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    paddingTop: '50px',
                    paddingLeft: '0 !important',
                  },
                }}>
                {renderDrawer()}
              </Grid>
            )}
            {visibleBenefit && (
              <BenefitsDrawer
                closeDrawer={() => setVisibleBenefit(false)}
                openDrawer={visibleBenefit}
                mode="light"
                state="default"
                types="Drawer"
                item={selectedProduct}
              />
            )}
          </Grid>
        </Box>
      </ContainerData>
      <ContainerData>
        <ContentTable>
          <ContainerTable
            sx={{
              display: 'flex',
              width: '80%',
              flexDirection: 'column',
              justifyContent: 'center',
              borderRadius: '20px',
              marginTop: 3,
              background: 'var(--specific-light-background-white, #FFF)',
            }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  display: 'flex',
                  width: '100%',
                  '& .MuiTabs-flexContainer': {
                    justifyContent: 'space-between',
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#026E18', // Cambia el color del indicador
                  },
                }}>
                <Tab
                  label="Resumen del mes actual"
                  {...a11yProps(0)}
                  sx={{
                    textTransform: 'none',
                    display: 'flex',
                    padding: '18px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    flex: '1 0 0',
                    borderBottom:
                      '1.6px solid var(--specific-light-stroke-m, #EAECF0)',
                    '&.Mui-selected': {
                      color: '#026E18',
                    },
                  }}
                />
                {!selectedProduct?.isCorporate ||
                  (selectedProduct?.productTypeId?.toUpperCase() ===
                    PRODUCTS_TYPES.PREPP && (
                    <Tab
                      label="Beneficios"
                      {...a11yProps(1)}
                      sx={{
                        display: 'flex',
                        padding: '18px 8px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        flex: '1 0 0',
                        borderBottom:
                          '1.6px solid var(--specific-light-stroke-m, #EAECF0)',
                        '&.Mui-selected': {
                          color: '#026E18',
                        },
                      }}
                    />
                  ))}
                <Tab
                  label="Estado de cuenta meses anteriores"
                  {...a11yProps(2)}
                  onClick={handleShowMore}
                  sx={{
                    textTransform: 'none',
                    display: 'flex',
                    padding: '18px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    flex: '1 0 0',
                    borderBottom:
                      '1.6px solid var(--specific-light-stroke-m, #EAECF0)',
                    '&.Mui-selected': {
                      color: '#026E18',
                    },
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Estado dataCurrent={dataCurrent} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Benefits />
            </CustomTabPanel>
          </ContainerTable>
        </ContentTable>
      </ContainerData>
    </ContainerMaterial>
  );
}

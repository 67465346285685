import axios from 'axios';
import {
  Cmf_Base,
  CMF_MANAGEMENT,
  VOLCAN,
  WALLET_API,
  BasicAuth,
} from 'src/Data/Api';

const axiosInstance = axios.create({
  baseURL: Cmf_Base,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosInstanceMdl07 = axios.create({
  baseURL: CMF_MANAGEMENT,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${BasicAuth}`,
  },
});

const axiosInstanceMdl04 = axios.create({
  baseURL: WALLET_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Middleware para agregar el API_KEY a las solicitudes
const axiosMiddleware = store => next => action => {
  const apiKey = store.getState().RequestCardReducer.mdl03;
  if (apiKey) {
    axiosInstance.defaults.headers['x-ping'] = apiKey;
  }

  return next(action);
};

export {axiosInstance, axiosInstanceMdl07, axiosInstanceMdl04, axiosMiddleware};

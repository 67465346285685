import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
// pages
import { useSelector } from 'react-redux';
import Login from 'src/containers/cmf_linea/auth/login.cmf';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
}

export default function AuthGuard({children}) {
  const {Authenticated} = useSelector(state => state.AuthenticationReducer)
  const {pathname} = useLocation()
  const [requestedLocation, setRequestedLocation] = useState(null)
  if (!Authenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}

import { message } from 'antd';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { axiosInstance, axiosInstanceMdl07 } from 'src/config/axiosMiddleware';
import { LOGIN_USER_SUCCESS } from '../../constrants/reducers';
import { API_KEY, Api_Url, Cmf_Base, Cmf_Base02 } from '../../Data/Api';
import { openNotificationWithIcon } from '../../utils/Functions';
import { EMAIL_VALIDATION } from '../../utils/regex';

/**
 * meotodo para la autenticacion de usuarios
 * @param {*} credentials email y password del usuario
 * @param {*} history redireccion a la pagina de inicio
 * @returns
 */

export const LoginAuthentication = credentials => {
  return async dispatch => {
    dispatch({type: 'LOADING_SHOW'});
    dispatch({type: 'START_LOADING'});
    try {
      const response = await axios.post(
        `${Api_Url}/site/cmfenlinea/api/authentication`,
        {
          username: credentials.email,
          password: credentials.password,
        },
        {
          withCredentials: true,
        },
      );

      const {data} = response;

      if (!response.data.error && !data.shouldChangePassword) {
        const getCookie = name => {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2)
            return parts
              .pop()
              .split(';')
              .shift();
          return null;
        };
        const sessionToken = getCookie('user-info');
        const response = await fetch(
          `${Api_Url}/site/cmfenlinea/api/protected-ws`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              Cookie: `next-auth.session-token=${sessionToken}`,
            },
          },
        );
        const respo = await response.json();
        if (!response.ok) {
          throw new Error(respo.error || 'Error al obtener datos');
        }
        dispatch({
          type: 'LOGIN_AUTHENTICATION_SUCCESS',
          user: respo.data,
        });
        dispatch({
          type: 'PROCCESS_LOGIN',
          payload: true,
        });
      } else {
        // NotificationManager.error(response?.data?.message);
        if (data.access && !data.shouldChangePassword) {
          dispatch({
            type: 'PROCCESS_LOGIN',
            payload: true,
          });
        } else if (data.access && data.shouldChangePassword) {
          dispatch({
            type: 'PROCCESS_LOGIN',
            payload: 3,
          });
        }
      }
    } catch (err) {
      NotificationManager.error('Ha ocurrido un error');
    } finally {
      dispatch({type: 'STOP_LOADING'});
      dispatch({type: 'LOADING_HIDE'});
    }
  };
};
/**
 * Metodo que permite cerrar sesion a un usuario
 * @returns
 */
export const signOut = () => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const token = AuthenticationReducer?.CurrentUser?.access_token;
    try {
      const response = await axiosInstance.post(
        `api/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const {status} = response;
      if (status === 200) {
        dispatch({type: 'LOGOUT_SUCCESS'});
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Ha ocurrido un error');
    }
  };
};

export const loginFactoringAction = (payloadLogin, history) => {
  return async dispatch => {
    let payload = {
      ...payloadLogin,
      type: 'cmf_factoring',
    };
    dispatch({type: 'LOAD_FACTORING_LOGIN', payload: true});
    try {
      const response = await axios.post(`${Cmf_Base}api/auth/login`, payload, {
        headers: {
          Apikey: API_KEY,
        },
      });
      const {data} = response;
      if (data) {
        const resp = await axios.get(
          `${Cmf_Base02}api/factoring/onboarding/customerNumber/${data.id}`,
          // { headers: {Apikey: API_KEY} }
        );
        dispatch({
          type: 'LOAD_DATA_LOGIN_FACTORING',
          payload: resp.data.JsonAnswer,
        });

        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: resp.data.JsonAnswer,
        });
      }
      history.push('/Home/Factoring');
    } catch (error) {
      openNotificationWithIcon(
        'error',
        'Datos de acceso incorrectos. Por favor intentalo de nuevo.',
      );
    } finally {
      dispatch({type: 'LOAD_FACTORING_LOGIN', payload: false});
    }
  };
};
export const ResetPasswordSendEmail = (email, onSuccess) => {
  return async dispatch => {
    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      const response = await fetch(
        `${Api_Url}/site/cmfenlinea/api/email-exists`,
        {
          method: 'POST',
          credentials: 'include', // Permitir credenciales en la solicitud
          body: JSON.stringify({email}),
        },
      );
      const data = await response.json();
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});

      if (response.status === 200) {
        if (!data?.exists) {
          onSuccess(false);
          NotificationManager.error(data?.data?.message);
        } else {
          onSuccess(true);
        }
      }
    } catch (error) {
      onSuccess(false);
      if (error?.response?.status === 400) {
        NotificationManager.error(
          'Límite de intentos alcanzado. Intente más tarde.',
        );
      }
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
    }
  };
};

export const SendEmailToChangePassword = (email, onComplete) => {
  return async dispatch => {
    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      // const response = await axiosInstanceMdl07.post(
      //   `v1/api/user/forgot-password/request-otp`,
      //   {
      //     email: email,
      //   },
      // );
      const response = await fetch(
        `${Api_Url}/site/cmfenlinea/api/change-password`,
        {
          method: 'POST',
          credentials: 'include', // Permitir credenciales en la solicitud
          body: JSON.stringify({email}),
        },
      );
      const data = await response.json();
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
      if (response.status === 200 && data.success) {
        if (data?.success) {
          onComplete(true);
          NotificationManager.success(
            'Introduce el código de seguridad que se te ha enviado a tu teléfono celular.',
          );
        } else {
          NotificationManager.success(
            data.message ??
              'Algo ha salido mal al intentar enviar el código de seguridad a tu número de telefono registrado. Inténtalo nuevamente más tarde.',
          );
          onComplete(false);
        }
      }
    } catch (error) {
      onComplete(false);
      if (error?.response) {
        NotificationManager.error(
          'Algo ha salido mal al intentar enviar el código de seguridad a tu número de telefono registrado. Inténtalo nuevamente más tarde.',
        );
      }
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
    }
  };
};

export const ResetPasswordSendEmailConfig = credential => {
  return async dispatch => {
    if (EMAIL_VALIDATION.test(credential.Email)) {
      dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
      try {
        const response = await axiosInstanceMdl07.post(
          `v1/api/user/forgot-password/request-otp`,
          {
            email: credential.Email,
          },
        );
        const {data} = response;

        dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
        if (data?.logger?.status === 201) {
          if (data?.data?.message) {
            message.error(data?.data?.message);
          } else {
            message.success(
              'Ingresa el código enviado por SMS a tu número de celular',
            );
          }
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          message.error('Límite de intentos alcanzado. Intente más tarde.');
        }
        dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
      } finally {
        dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
      }
    } else {
      message.warning('Es requerido llenar el Campo de Email');
    }
  };
};

export const ResetPasswordValidateCode = (credential, onNextPassword) => {
  return async dispatch => {
    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      // const response = await axiosInstanceMdl07.post(
      //   `v1/api/user/forgot-password/confirm-otp`,
      //   {
      //     to: credential.to,
      //     code: credential.code,
      //   },
      // );

      const response = await fetch(
        `${Api_Url}/site/cmfenlinea/api/confirm-otp`,
        {
          method: 'POST',
          credentials: 'include', // Permitir credenciales en la solicitud
          body: JSON.stringify({to: credential.to, code: credential.code}),
        },
      );
      const data = await response.json();
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
      if (response.status === 200) {
        onNextPassword(true);
        NotificationManager.success('Código validado correctamente');
      }
    } catch (error) {
      onNextPassword(false);
      if (
        error?.response?.data?.data?.problemPublic ===
        'Max check attempts reached'
      ) {
        NotificationManager.error(
          'Límite de intentos alcanzado. Intente más tarde.',
        );
        return;
      }
      NotificationManager.error('El código ingresado es incorrecto');
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      onNextPassword(false);
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
    }
  };
};

export const ResetPasswordValidateCodeConfig = credential => {
  return async dispatch => {
    if (credential.code === '') {
      message.warning('Es requerido llenar el Campo de Código');
      return;
    }

    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      const response = await axiosInstanceMdl07.post(
        `v1/api/user/forgot-password/confirm-otp`,
        {
          to: credential.to,
          code: credential.code,
        },
      );
      const {data} = response;
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
      if (data?.logger?.status === 201) {
        message.success('Código validado correctamente');
      }
    } catch (error) {
      if (
        error?.response?.data?.data?.problemPublic ===
        'Max check attempts reached'
      ) {
        message.error('Límite de intentos alcanzado. Intente más tarde.');
        return;
      }
      message.error('El código ingresado es incorrecto');
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
    }
  };
};

export const ResetPasswordChangePassword = (
  credential,
  code,
  onNextPassword,
) => {
  return async dispatch => {
    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      // const response = await axiosInstanceMdl07.patch(
      //   `v1/api/user/forgot-password/change-password`,
      //   {
      //     email: credential.to,
      //     newPassword: credential.password,
      //     code: code,
      //   },
      // );
      const response = await fetch(
        `${Api_Url}/site/cmfenlinea/api/send-email-password`,
        {
          method: 'PATCH',
          credentials: 'include', // Permitir credenciales en la solicitud
          body: JSON.stringify({
            to: credential.to,
            password: credential.password,
            newPassword: credential.password,
            code: code,
          }),
        },
      );
      const data = await response.json();
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
      if (response.status === 200 && data?.success) {
        onNextPassword(true);
      } else {
        onNextPassword(false);
        NotificationManager.error(
          'Ha ocurrido un error al cambiar la contraseña. Intente nuevamente',
        );
      }
    } catch (error) {
      onNextPassword(false);
      NotificationManager.error(
        'Ha ocurrido un error al cambiar la contraseña. Intente nuevamente',
      );
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
      onNextPassword(false);
    }
  };
};

export const changePassword = (customerId, password, tokenCMF, onSuccess) => {
  return async () => {
    try {
      const response = await axiosInstanceMdl07.patch(
        `v1/api/user/changePassword`,
        {
          userId: customerId,
          newPassword: password,
        },
      );
      if (response.data.data) {
        onSuccess(true);
        NotificationManager.success(
          'La contraseña ha sido actualizada con éxito.',
        );
      }
    } catch (error) {
      NotificationManager.error(
        'Se ha producido un error al intentar cambiar la contraseña',
      );
    }
  };
};
